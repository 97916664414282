import React, { useContext } from 'react';
import { components } from 'ls-common-client';
import PropTypes from 'prop-types';
import CircularProgress from './CircularProgress';
import { AppContext } from '../../../../context';

const { Container, Text, Panel } = components;

const LargeProductContainer = ({ product, ...props }) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  const RightAligned = () => (
    <>
      <Container
        display="flex"
        flexWrap="wrap"
        height="100%"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        marginVertical={mobile ? '2px' : 0}
        marginHorizontal={mobile ? 'small' : 0}
      >
        <Text whiteSpace="nowrap">
          New <b>${Math.round(product.new).toLocaleString()}</b>
        </Text>
        <Text whiteSpace="nowrap">
          Renewal <b>${Math.round(product.renewal || 0).toLocaleString()}</b>
        </Text>
      </Container>
      {!mobile && (
        <Container
          display="flex"
          width="60%"
          borderTop="1px solid #efefef"
          height="2px"
          marginVertical="10px"
        />
      )}
      <Container
        display="flex"
        flexWrap="wrap"
        height="100%"
        alignItems={mobile ? 'flex-start' : 'center'}
        justifyContent="center"
        flexDirection="column"
        marginVertical={mobile ? '2px' : 0}
        marginHorizontal={mobile ? 'small' : 0}
      >
        <Text color="#9b9b9b" fontSize="15px">
          Total Sales
        </Text>
        <Text fontSize="30px" color="#555555">
          ${Math.round(product.total || 0).toLocaleString()}
        </Text>
      </Container>
    </>
  );

  return (
    <Panel header={product.label} {...props}>
      <Container
        display="flex"
        flexDirection={mobile ? 'row' : 'column'}
        flexWrap={mobile ? 'wrap' : 'noWrap'}
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent={mobile ? 'center' : 'space-around'}
        padding
      >
        <CircularProgress
          radius={mobile ? 50 : 70}
          stroke={mobile ? 5 : 7}
          progress={product.percentage}
          colors={product.colors}
        />
        {mobile ? (
          <Container
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <RightAligned />
          </Container>
        ) : (
          <RightAligned />
        )}
      </Container>
    </Panel>
  );
};

LargeProductContainer.propTypes = {
  product: PropTypes.shape({
    label: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    new: PropTypes.number.isRequired,
    renewal: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default LargeProductContainer;
