import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Leaderboard } from '../../common';
import { AppContext } from '../../../context';

const DigitalNewLeaderBoard = ({ data, ...props }) => {
  const { digitalNewLeaderBoard, name } = data || {};

  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  return (
    <Leaderboard
      data={digitalNewLeaderBoard}
      header="Digital New Leaderboard"
      valueType={mobile ? '%' : 'Percentage'}
      repName={name}
      renderValue={val => `${val === 1 ? 100 : (val * 100).toFixed(2)}%`}
      {...props}
    />
  );
};

DigitalNewLeaderBoard.propTypes = {
  data: PropTypes.shape({
    digitalNewLeaderBoard: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

DigitalNewLeaderBoard.defaultProps = {
  data: null,
};

export default DigitalNewLeaderBoard;
