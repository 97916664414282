import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import { AppContext } from '../../context';

const GreenText = styled(Text)`
  color: ${props => props.theme.secondary.secondary300};
  font-size: 30px;
`;

const OrangeText = styled(Text)`
  color: #ff9f40;
  font-size: 30px;
`;

const RedText = styled(Text)`
  color: #f16159;
  font-size: 30px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex: 1;
  min-width: ${props => (props.mobile ? '100px' : '120px;')};
  flex-direction: column;
  margin-bottom: ${props => (props.mobile ? '20px' : '')};
  padding: 5px;
`;

const FeatureValue = ({ value, color, label, render }) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  const colors = () => ({
    green: <GreenText>{render(value)}</GreenText>,
    orange: <OrangeText>{render(value)}</OrangeText>,
    red: <RedText>{render(value)}</RedText>,
    default: (
      <Text color="text500" fontSize="30px">
        {render(value)}
      </Text>
    ),
  });

  return typeof value === 'number' ? (
    <StyledContainer mobile={mobile}>
      {colors()[color(value) || 'default']}
      <Text color="text300" lineHeight="1.2" fontSize="16px" fontWeight="600">
        {label}
      </Text>
    </StyledContainer>
  ) : (
    <StyledContainer maxWidth="140px" mobile={mobile}>
      <Text
        fontSize="16px"
        fontWeight="600"
        lineHeight="1.2"
        display="block"
        marginBottom="5px"
      >
        No Data Available
      </Text>
      <Text color="text300" lineHeight="1.2" fontSize="16px" fontWeight="600">
        {label}
      </Text>
    </StyledContainer>
  );
};

FeatureValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.func,
  label: PropTypes.string,
  render: PropTypes.func,
};

FeatureValue.defaultProps = {
  value: null,
  color: () => null,
  label: null,
  render: () => {},
};

export default FeatureValue;
