import React from 'react';

const CircleArrowIcon = () => (
  <svg
    width="38px"
    height="46px"
    viewBox="0 0 38 46"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-797.000000, -259.000000)">
        <g transform="translate(798.000000, 260.000000)">
          <polygon
            fill="#DCDCDC"
            points="27.5 9.76996262e-15 34.9316406 12.9599609 20.3583984 15.9667969"
          />
          <path
            d="M33.1043283,28.5695654 C31.908586,34.4435008 27.9267437,38.8241894 22.4027258,40.4164883 C18.3079442,41.6064069 13.8981872,40.9725734 10.3069285,38.6743669 C7.06185418,36.6386733 4.64503414,33.5161945 3.48826557,29.8647082 C0.999520807,21.1033311 6.92193695,12.9920142 14.0400456,11.0010184 L14.0400456,10.9990772 C15.9804701,10.4526981 18.0123311,10.3010838 20.0130331,10.5518796 L16.5287904,18.8155077 L34.2979303,15.2321135 L24.4420032,0 L21.2066351,7.71510877 C18.5565201,7.24846913 15.8344306,7.3845736 13.2426518,8.11369618 C2.69087175,11.1001899 -1.78886883,22.2004893 0.649105544,30.7105031 L0.651051743,30.7105031 C1.96734885,35.0658065 4.83522899,38.7854844 8.71408703,41.1632312 C11.5586227,43.012267 14.8777123,43.9980588 18.2703692,44 C19.9366834,43.9883338 21.5932416,43.7550129 23.1972874,43.3039484 C29.8178462,41.4121056 34.6455133,36.1373604 36.0397082,29.1688751 L33.1043283,28.5695654 Z M25.041293,6.37095763 L29.421882,13.1896205 L21.4578987,14.782019 L25.041293,6.37095763 Z"
            stroke="#FFFFFF"
            strokeWidth="0.8"
            fill="#4A4A4A"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CircleArrowIcon;
