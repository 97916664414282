import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import { AppContext } from '../../context';

const Header = ({ children, subHeading, ...props }) => {
  const {
    user: { user },
    media: { mobile },
    salesReportData,
  } = useContext(AppContext.Context);

  const { displayName, account } = user;
  const { firstName } = account;

  const { loading, repName } = salesReportData;

  const usersName = repName || displayName || firstName || '';

  return (
    <Container
      display="flex"
      padding={mobile ? '40px 18px 10px 18px' : '20px 40px'}
      flexDirection={mobile ? 'column' : 'row'}
      {...props}
    >
      <Container
        flex="1"
        minWidth="245px"
        marginRight={mobile ? 0 : '20px'}
        alignItems="flex-end"
        marginBottom="10px"
      >
        <Text display="block" color="text500" fontSize="22px" fontWeight="600">
          Hi {!loading && usersName}
        </Text>
        <Text fontSize="16px" lineHeight="1.1" color="text300">
          {subHeading}
        </Text>
      </Container>
      {children}
    </Container>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  subHeading: PropTypes.string,
};

Header.defaultProps = {
  children: null,
  subHeading: null,
};

export default Header;
