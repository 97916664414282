import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import DoughnutChart from '../../common/DoughnutChart';

const LocalsearchHealth = ({ data, ...props }) => {
  const { localsearchHealth } = data || {};

  return (
    <Panel header="Localsearch Health" {...props}>
      <DoughnutChart data={localsearchHealth} />
    </Panel>
  );
};

LocalsearchHealth.propTypes = {
  data: PropTypes.shape({
    localsearchHealth: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

LocalsearchHealth.defaultProps = {
  data: null,
};

export default LocalsearchHealth;
