import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import AppMenu from 'ls-common-client/src/components/AppMenu';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import FeedbackForm from 'ls-common-client/src/components/FeedbackForm';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import NotificationSlider from 'ls-common-client/src/components/NotificationSlider';
import Animation from 'ls-common-client/src/components/Animation';
import SideBar from './SideBar';
import StaffSearch from './StaffSearch';
import Home from './pages/Home';
import Market from './pages/Market';
import StateOfPlay from './pages/StateOfPlay';
import NotFound from './pages/NotFound';
import { AppContext } from '../context';
import { auth, imageResizer } from '../services';
import budgetTrackerLogo from '../images/budgetTrackerLogo.svg';
import BudgetTrackerIcon from '../images/BudgetTrackerIcon';
import MailIcon from '../images/MailIcon';
import emailSuccess from '../animations/emailSuccess.json';

const Layout = () => {
  const {
    user: { user },
    error,
    media: { desktop, tablet, mobile },
  } = useContext(AppContext.Context);

  const { staffId, userRoles, displayName, account } = user || {};
  const { firstName } = account || {};

  const [loader, setLoader] = useState(true);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showStateOfPlaySuccess, setShowStateOfPlaySuccess] = useState();
  const [sideBarClosed, setSideBarClosed] = useState(true);
  const [stateOfPlayHover, setStateOfPlayHover] = useState();

  const isAuthorized = useMemo(() => {
    const { edges = [] } = userRoles || {};

    return edges.some(({ node }) =>
      ['admin', 'salesManager'].includes(node.roleName)
    );
  }, [userRoles]);

  const isPersoniv = useMemo(() => {
    const { userRoles: { edges = [] } = {} } = user || {};
    return !!edges.some(({ node }) => node.roleName === 'personiv');
  }, [user]);

  useEffect(() => {
    setSideBarClosed(
      mobile || tablet || localStorage.getItem('sideBarClosed') === 'true'
    );
  }, [mobile, tablet]);

  const openSideBar = () => {
    setSideBarClosed(false);
    localStorage.setItem('sideBarClosed', false);
  };

  const closeSideBar = () => {
    setSideBarClosed(true);
    localStorage.setItem('sideBarClosed', true);
  };

  useEffect(() => {
    if (staffId || error) {
      setTimeout(() => {
        setLoader(false);
      }, 600);
    }
  }, [staffId, error]);

  const paddingTop = useMemo(() => {
    if (desktop) {
      return 0;
    }

    if (isAuthorized) {
      return '123px';
    }

    return '60px';
  }, [isAuthorized, desktop]);

  return (
    <>
      {loader && (
        <Container
          backgroundColor="rgba(255,255,255,0.6)"
          position="fixed"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          transition="all ease 0.5s"
          top="0"
          left="0"
          zIndex="2"
          opacity={staffId ? 0 : 1}
        >
          <Container display="flex" flexDirection="column">
            <img alt="Budget Tracker Logo" src={budgetTrackerLogo} />
            <Loader width="200px" />
          </Container>
        </Container>
      )}
      {!!user && (
        <Container display="flex" minHeight="100vh">
          {!!staffId && (
            <SideBar
              sideBarClosed={sideBarClosed}
              openSideBar={openSideBar}
              closeSideBar={closeSideBar}
            />
          )}
          <Container flex="1" display="flex" flexDirection="column">
            <Container
              position={desktop ? 'relative' : 'fixed'}
              width="100%"
              zIndex="2"
              boxShadow={
                !desktop ? '0 2px 20px 3px rgba(0, 0, 0, 0.1)' : 'none'
              }
            >
              <Container
                display="flex"
                padding={!desktop ? '13px 12px' : '13px 40px'}
                backgroundColor={!desktop ? 'white' : 'none'}
                alignItems="center"
              >
                {desktop && isAuthorized && (
                  <StaffSearch marginRight="auto" width="300px" />
                )}
                {!desktop && (
                  <>
                    <EmptyButton
                      onClick={openSideBar}
                      display="flex"
                      alignItems="center"
                      marginRight="20px"
                    >
                      <Icon
                        iconSize="40px"
                        className="ls-icon icon-generalmenu"
                        iconColor="normal"
                      />
                    </EmptyButton>
                    {mobile && <BudgetTrackerIcon />}
                    {tablet && (
                      <img
                        alt="Budget Tracker Logo"
                        src={budgetTrackerLogo}
                        width="175"
                      />
                    )}
                  </>
                )}
                <Container
                  marginLeft="auto"
                  display="flex"
                  justifyContent="center"
                >
                  {isAuthorized && (
                    <AnchorLink
                      width="100%"
                      height="100%"
                      display="block"
                      marginRight
                      to="/state-of-play"
                      onMouseOver={() => setStateOfPlayHover(true)}
                      onMouseLeave={() => setStateOfPlayHover(false)}
                    >
                      <MailIcon
                        fill={stateOfPlayHover ? '#97AEFF' : '#2f393e'}
                      />
                    </AnchorLink>
                  )}
                  <AppMenu
                    iconFill="#2f393e"
                    isStaff={!!staffId}
                    isPersoniv={isPersoniv}
                  />
                  <UserMenu
                    user={user}
                    auth={auth}
                    imageResizer={imageResizer}
                    iconFill="#2f393e"
                    marginLeft
                  />
                </Container>
              </Container>

              {!desktop && isAuthorized && (
                <Container
                  backgroundColor="white"
                  padding="10px"
                  borderTop={theme => `1px solid ${theme.border.border300}`}
                >
                  <StaffSearch closeSideBar={closeSideBar} />
                </Container>
              )}
            </Container>

            {!!staffId && (
              <Container paddingTop={paddingTop}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/market">
                    <Market />
                  </Route>
                  <Route exact path="/state-of-play">
                    <StateOfPlay
                      setShowStateOfPlaySuccess={setShowStateOfPlaySuccess}
                    />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Container>
            )}

            <Container
              padding="20px 40px"
              marginTop="auto"
              display="flex"
              flexWrap={!desktop ? 'wrap-reverse' : 'no-wrap'}
              justifyContent={!desktop ? 'center' : 'flex-start'}
            >
              <Text
                marginRight={!desktop ? '0' : '20px'}
                fontSize="13px"
                color="text300"
                textAlign="center"
                width={!desktop ? '100%' : 'auto'}
              >
                &#169; {new Date().getFullYear()} Budget Tracker.
              </Text>
              <EmptyButton
                color="normal"
                fontSize="13px"
                width={!desktop ? '100%' : 'auto'}
                onClick={() => setShowFeedbackForm(true)}
                marginBottom={!desktop ? '15px' : 'none'}
              >
                Send Feedback
              </EmptyButton>
            </Container>
          </Container>
        </Container>
      )}
      <FeedbackForm
        onClose={() => setShowFeedbackForm(false)}
        show={showFeedbackForm}
        mode={mobile ? 'mobile' : 'desktop'}
        application="Budget Tracker"
        types={[
          {
            label: 'Incorrect Details',
            icon: (
              <Icon
                iconSize="30px"
                className="ls-icon icon-categorybusinessservices"
              />
            ),
          },
          {
            label: 'Missing Information',
            icon: (
              <Icon
                iconSize="30px"
                marginRight="7px"
                className="ls-icon icon-categoryhomeimlookingfor"
              />
            ),
          },
          {
            label: 'Technical Issue',
            icon: (
              <Icon iconSize="30px" className="ls-icon icon-generalalert" />
            ),
          },
          {
            label: 'Suggestion',
            icon: (
              <Icon
                iconSize="30px"
                marginBottom="-3px"
                className="ls-icon icon-categoryboatlighting"
              />
            ),
          },
        ]}
      />
      <NotificationSlider
        heading="Email Sent!"
        text={`State of play is zooming through cyberspace. Nice one, ${displayName ||
          firstName}!`}
        mode={desktop ? 'desktop' : 'mobile'}
        show={showStateOfPlaySuccess}
        onClose={() => setShowStateOfPlaySuccess(false)}
        duration={10000}
        icon={<Animation width="100px" data={emailSuccess} />}
      />
    </>
  );
};

export default Layout;
