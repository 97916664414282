const numFormatter = num => {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(2)}k`;
  }
  if (num > 1000000) {
    return `${(num / 1000000).toFixed(2)}m`;
  }
  if (num < -999 && num > -1000000) {
    return `${(num / 1000).toFixed(2)}k`;
  }
  if (num < -1000000) {
    return `${(num / 1000000).toFixed(2)}m`;
  }
  return Math.round(num);
};

export default numFormatter;
