import React from 'react';
import PropTypes from 'prop-types';

const Market = ({ fill, id }) => (
  <svg
    width="15px"
    height="19px"
    viewBox="0 0 15 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="-2.40355723%"
        y1="79.9707031%"
        x2="79.5857988%"
        y2="10.4638672%"
        id={`linearGradient-${id}`}
      >
        <stop stopColor={fill || '#66EADC'} offset="0%" />
        <stop stopColor={fill || '#40A499'} offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-31.000000, -212.000000)"
        fill={`url(#linearGradient-${id})`}
        fillRule="nonzero"
      >
        <path d="M38.1874931,230.687482 C38.1874931,230.687482 45.3749863,225.47655 45.3749863,219.277337 C45.3749863,215.234372 42.1406144,212 38.1874931,212 C34.2343719,212 31,215.234372 31,219.277337 C31,225.47655 38.1874931,230.687482 38.1874931,230.687482 Z M38.1874931,222.06249 C36.615229,222.06249 35.3124959,220.759757 35.3124959,219.187493 C35.3124959,217.615229 36.615229,216.312496 38.1874931,216.312496 C39.7597573,216.312496 41.0624904,217.615229 41.0624904,219.187493 C41.0624904,220.759757 39.7597573,222.06249 38.1874931,222.06249 Z" />
      </g>
    </g>
  </svg>
);

Market.propTypes = {
  fill: PropTypes.string,
  id: PropTypes.string,
};

Market.defaultProps = {
  fill: null,
  id: '',
};

export default Market;
