/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Panel from 'ls-common-client/src/components/Panel';
import Text from 'ls-common-client/src/components/Text';
import Container from 'ls-common-client/src/components/Container';
import { AppContext } from '../../context';

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  & tr:nth-child(even) {
    background: ${props => props.theme.background.background200};
  }
`;

const StyledTH = styled.th`
  font-size: 16px;
  color: ${props => props.theme.text.text400};
  padding: 15px 10px;
  border-bottom: 1px solid ${props => props.theme.border.border300};
  text-align: ${props => props.textAlign};
  font-weight: normal;
  background: #fff;
  &:first-child {
    padding-left: 15px;
    text-align: left;
    font-weight: 600;
    color: ${props => props.theme.text.text500};
    position: sticky;
    left: 0;
    z-index: 1;
  }
  ${({ boldHeaders }) =>
    boldHeaders &&
    `
    font-weight: 600;
    color: #555555;
    font-size: 14px;
    padding: 10px 10px;
  `};
  &:last-child {
    padding-right: 15px;
  }
`;

const StyledTD = styled.td`
  font-size: 16px;
  color: ${props => props.theme.text.text400};
  padding: ${props => props.cellPadding};
  font-weight: normal;
  text-align: ${props => props.textAlign};
  background: #fff;
  &:not(:first-child) {
    border-left: ${props => props.columnBorder};
  }
  &:first-child {
    padding-left: 15px;
    text-align: left;
    font-weight: 600;
    color: ${props => props.theme.text.text500};
    position: sticky;
    left: 0;
    z-index: 1;
  }
  &:last-child {
    padding-right: 15px;
  }
`;

const Table = ({
  headings,
  textAlign,
  cellPadding,
  columnBorder,
  boldHeaders,
  data,
  responsive,
  ...props
}) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  if (mobile && responsive) {
    return (
      <Panel header={headings[0]} {...props}>
        {data.map(item =>
          item.map((text, i) =>
            i === 0 ? (
              <Container
                height="60px"
                backgroundColor="background300"
                padding="0 16px"
                display="flex"
                alignItems="center"
                key={i}
              >
                {text}
              </Container>
            ) : (
              <Container
                height="55px"
                padding="0 16px"
                key={i}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="text300">{headings[i]}</Text>
                {text}
              </Container>
            )
          )
        )}
      </Panel>
    );
  }

  return (
    <Panel overflow="hidden" width="0" {...props}>
      <Container overflowX="auto" minWidth="100%" width="0">
        <StyledTable cellSpacing="0" cellPadding="0" width="100%">
          {!!headings.length && (
            <thead>
              <tr>
                {headings.map((heading, i) => (
                  <StyledTH
                    boldHeaders={boldHeaders}
                    textAlign={textAlign}
                    key={i}
                    index={i}
                  >
                    {heading}
                  </StyledTH>
                ))}
              </tr>
            </thead>
          )}
          {!!data.length && (
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {row.map((cell, i) => (
                    <StyledTD
                      columnBorder={columnBorder}
                      cellPadding={cellPadding}
                      textAlign={textAlign}
                      key={i}
                    >
                      {cell || ''}
                    </StyledTD>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </StyledTable>
      </Container>
    </Panel>
  );
};

Table.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  data: PropTypes.arrayOf(PropTypes.array),
  textAlign: PropTypes.string,
  cellPadding: PropTypes.string,
  columnBorder: PropTypes.string,
  boldHeaders: PropTypes.bool,
  responsive: PropTypes.bool,
};

Table.defaultProps = {
  headings: [],
  data: [],
  textAlign: 'center',
  cellPadding: '15px 5px',
  columnBorder: null,
  boldHeaders: false,
  responsive: true,
};

export default Table;
