import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import Text from 'ls-common-client/src/components/Text';
import NoDataIcon from '../../../../images/NoDataIcon';
import { numFormatter } from '../../../../lib';

const message = 'This is a guide for your budget gap.';
const statusMessages = {
  bad: '#f16159',
  ok: '#ff9f40',
  good: '#40a499',
  excellent: '#40a499',
};

const BudgetHealth = ({ data, ...props }) => {
  const { budgetHealth, budgetHealthPercentage } = data || {};

  const status = useMemo(() => {
    if (budgetHealthPercentage < 0.3) {
      return 'bad';
    }
    if (budgetHealthPercentage > 0.3 && budgetHealthPercentage < 0.7) {
      return 'ok';
    }
    if (budgetHealthPercentage > 0.7 && budgetHealthPercentage < 1) {
      return 'good';
    }
    return 'excellent';
  }, [budgetHealthPercentage]);

  const color = statusMessages[status] || {};

  if (budgetHealthPercentage === null || budgetHealth === null) {
    return (
      <Panel header="Budget Health" {...props}>
        <Container padding="16px">
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Container width="100px">
              <NoDataIcon width="100%" height="50px" />
            </Container>
            <Text
              color="text300"
              lineHeight="1.2"
              fontSize="14px"
              fontWeight="600"
            >
              No Data Available
            </Text>
          </Container>
        </Container>
      </Panel>
    );
  }

  return (
    <Panel header="Budget Health" {...props}>
      <Container padding="16px">
        <Container display="flex" alignItems="center" marginBottom="15px">
          {message && (
            <Container color={color} marginRight="10px">
              {message}
            </Container>
          )}
          <Container marginLeft="auto" color="text500" fontSize="30px">
            {numFormatter(budgetHealth)}
          </Container>
        </Container>
        <Container
          backgroundColor="background300"
          display="flex"
          height="10px"
          borderRadius="15px"
          overflow="hidden"
        >
          <Container
            backgroundColor={color}
            flex={`0 0 ${budgetHealthPercentage * 100}%`}
            borderRadius="15px"
          />
        </Container>
      </Container>
    </Panel>
  );
};

BudgetHealth.propTypes = {
  data: PropTypes.shape({
    budgetHealth: PropTypes.number,
    budgetHealthPercentage: PropTypes.number,
  }),
};

BudgetHealth.defaultProps = {
  data: null,
};

export default BudgetHealth;
