import React, { useMemo } from 'react';

const Avatar = () => {
  const id1 = useMemo(() => Math.random(), []);
  const id2 = useMemo(() => Math.random(), []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <defs>
        <path
          id={`5fh1wr9w7a${id1}`}
          d="M13.717 0c7.575 0 13.717 6.141 13.717 13.717 0 7.575-6.142 13.717-13.717 13.717C6.14 27.434 0 21.292 0 13.717 0 6.14 6.141 0 13.717 0zm3.972 16.696H9.744c-2.71 0-4.925 2.196-4.961 4.9 2.182 2.473 5.368 4.038 8.934 4.038 3.566 0 6.752-1.565 8.934-4.038-.036-2.704-2.25-4.9-4.962-4.9zM13.717 4.78c-2.731 0-4.966 2.234-4.966 4.965s2.235 4.966 4.966 4.966c2.73 0 4.965-2.235 4.965-4.966 0-2.73-2.234-4.965-4.965-4.965z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-1247 -282) translate(1247 282)">
            <mask id={`wodh5cyrob${id2}`} fill="#fff">
              <use xlinkHref={`#5fh1wr9w7a${id1}`} />
            </mask>
            <path
              fill="#2F393E"
              d="M0 27.434L27.434 27.434 27.434 0 0 0z"
              mask={`url(#wodh5cyrob${id2})`}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Avatar;
