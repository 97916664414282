import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { components } from 'ls-common-client';
import styled from 'styled-components';
import { AppContext } from '../../../../context';
import ProductContainer from './ProductContainer';
import LargeProductContainer from './LargeProductContainer';
import WeeklyAccount from './WeeklyAccount';
import lsIcon from '../../../../images/lsIcon.png';
import sfIcon from '../../../../images/sfIcon.svg';

const { Container, Text, Loader, Image } = components;

const productDataQuery = gql`
  query ProductDataQuery($staffId: String!, $marketName: String!) {
    productReportData(staffId: $staffId, marketName: $marketName) {
      weeklyAccounts {
        label
        total
        required
      }
      productSales {
        label
        new
        renewal
        total
      }
    }
  }
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
`;

const GridContainer = styled(Container)`
  display: grid;
  grid-template-columns: auto auto;

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const colors = {
  'Localsearch Sales': ['#98b0fd', '#2d60fb'],
  'Google My Business': ['#66eadc', '#40a499'],
  'Website Subscriptions': ['#bae3ec', '#97aeff'],
  'Google Ads (PPC)': ['#ff7f00', '#ffbe00'],
  'Website Upfront': ['#849ae8', '#ee7aeb'],
  Social: ['#ffd3bb', '#f16159'],
  SEO: ['#0071a6', '#00a699'],
};

const renewalExclusions = ['Website Upfront'];

function Products() {
  const { search } = useLocation();
  const {
    user: { user },
    media: { mobile },
  } = useContext(AppContext.Context);
  const [productSales, setProductSales] = useState(null);
  const [lsSales, setLsSales] = useState(null);
  const [weeklyAccounts, setWeeklyAccounts] = useState(null);

  const { staffId } = user || {};

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {
    data: { productReportData } = {},
    error,
    loading: dataLoading,
  } = useQuery(productDataQuery, {
    variables: {
      marketName: query
        .get('marketName')
        .split('-')
        .map(val => val.charAt(0).toUpperCase() + val.slice(1))
        .join(' '),
      staffId: query.has('staffId') ? query.get('staffId') : staffId,
    },
  });

  useEffect(() => {
    if (productReportData) {
      const getTotalSales = sales => {
        return sales.reduce((acc, current) => {
          return acc + (current && current.total ? current.total : 0);
        }, 0);
      };
      const productMap = (product, totalSales) => {
        return {
          ...product,
          renewal:
            renewalExclusions.indexOf(product.label) > -1
              ? -1
              : product.renewal,
          colors: colors[product.label],
          percentage: Math.round((product.total / totalSales) * 100),
        };
      };

      const totalSales = getTotalSales(productReportData.productSales);
      setProductSales(
        productReportData.productSales
          .filter(({ label }) => label !== 'Localsearch Sales')
          .map(product => productMap(product, totalSales))
      );
      setLsSales(
        productReportData.productSales
          .filter(({ label }) => label === 'Localsearch Sales')
          .map(product => productMap(product, totalSales))[0]
      );
      const extraProperties = [
        {
          icon: <Image width="28px" src={lsIcon} />,
          width: '110px',
          subtitle: (
            <Text color="#9b9b9b" fontSize="15px">
              Required <br /> (per week)
            </Text>
          ),
          required: val => (
            <Text fontSize="30px" color="#555555">
              ${Math.round(val || 0).toLocaleString()}
            </Text>
          ),
          flex: '2',
        },
        {
          icon: <Image width="28px" src={sfIcon} />,
          width: '110px',
          flex: '1',
        },
      ];
      setWeeklyAccounts(
        productReportData.weeklyAccounts.map((acc, i) => ({
          ...acc,
          ...extraProperties[i],
          required: extraProperties[i].required
            ? extraProperties[i].required(acc.required)
            : null,
        }))
      );
    }
    return undefined;
  }, [productReportData]);

  if (dataLoading) {
    return (
      <Container
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Loader width="200px" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="100%"
        height="50vh"
        padding={mobile ? '8px' : '0 30px'}
      >
        <Text fontWeight="bold">
          Oops! An error has occurred, please contact TDK:{' '}
        </Text>
        <Text>{error.message}</Text>
      </Container>
    );
  }

  if (!productReportData) {
    return (
      <Container
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="100%"
        height="50vh"
        padding={mobile ? '8px' : '0 30px'}
      >
        <Text fontWeight="bold">No data available for you yet :(</Text>
      </Container>
    );
  }

  return (
    <FlexContainer padding={mobile ? '8px' : '0 30px'}>
      <Container display="flex" flex="1" flexWrap="wrap">
        <Container display="flex" flex="1" flexDirection="column">
          {weeklyAccounts &&
            weeklyAccounts.map(product => (
              <WeeklyAccount
                key={product.label}
                flex={product.flex}
                product={product}
              />
            ))}
        </Container>
        {lsSales && <LargeProductContainer flex="1" product={lsSales} />}
      </Container>
      <GridContainer flex="1">
        {productSales &&
          productSales.map(product => (
            <ProductContainer key={product.label} product={product} />
          ))}
      </GridContainer>
    </FlexContainer>
  );
}

export default Products;
