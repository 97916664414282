import React from 'react';

const WeeklyAverage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#E5E5E5"
            d="M5.133 41.067c2.942-.345 4.573-1.078 4.892-2.202 3.943-13.868 5.929-24.243 13.008-24.831 7.077-.589 8.833 8.867 12.458 22.874.294 1.137 1.489 2.455 3.583 3.955l-33.94.204z"
            transform="translate(-366 -257) translate(367 258)"
          />
          <g fill="#4A4A4A">
            <path
              stroke="#4A4A4A"
              strokeWidth=".3"
              d="M30.8 25.193c.62 1.628 1.208 3.286 1.765 4.857 2.492 7.03 4.015 11.017 6.28 11.017h1.488V39.6H38.854c-1.348 0-3.32-5.566-4.906-10.04-1-2.82-2.046-5.77-3.207-8.41l-.001-.012c-.668-1.518-1.5-3.236-2.503-4.743l-.11-.165c-.075-.11-.15-.218-.227-.326-.088-.125-.178-.246-.268-.365-.046-.06-.09-.12-.137-.18-1.196-1.528-2.516-2.557-4-2.82l-.13-.023c-.21-.027-.419-.05-.632-.05-.212 0-.421.023-.628.052-.043.007-.085.013-.129.022-1.69.3-3.164 1.59-4.484 3.476l-.077.11c-.123.178-.244.36-.363.548l-.08.123c-.13.209-.261.422-.39.64-.05.088-.098.176-.15.264-.079.137-.156.275-.234.418-.052.093-.101.187-.15.28-.083.154-.164.31-.245.466-.03.062-.062.123-.093.185-1.568 3.108-2.919 6.915-4.193 10.509C9.933 34.034 7.961 39.6 6.606 39.6H5.133v1.467h1.46c2.319.022 3.81-3.964 6.31-11.017.557-1.573 1.144-3.228 1.765-4.856l-.001 17.34h-13.2V0H0v43.267c0 .405.328.733.733.733H44v-1.467H30.8v-17.34zm-1.467 17.34h-5.866v-28.5c.023.008.047.01.071.015l.022.008c1.734.515 3.483 2.662 5.306 6.51l.18.388.137.301c.051.112.1.225.15.34v20.938zM16.133 21.6c1.997-4.51 3.898-6.992 5.784-7.547.004 0 .006-.002.01-.004.025-.007.049-.01.073-.016v28.499h-5.867V21.6z"
              transform="translate(-366 -257) translate(367 258)"
            />
            <path
              d="M22 9.533L23.467 9.533 23.467 11 22 11zM24.933 6.6L26.4 6.6 26.4 8.066 24.933 8.066zM22 6.6L23.467 6.6 23.467 8.066 22 8.066zM19.067 6.6L20.533 6.6 20.533 8.066 19.067 8.066zM22 3.666L23.467 3.666 23.467 5.133 22 5.133zM24.933 3.666L26.4 3.666 26.4 5.133 24.933 5.133zM27.867 3.666L29.333 3.666 29.333 5.133 27.867 5.133zM19.067 3.666L20.533 3.666 20.533 5.133 19.067 5.133zM16.133 3.666L17.6 3.666 17.6 5.133 16.133 5.133z"
              transform="translate(-366 -257) translate(367 258)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WeeklyAverage;
