/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { components } from 'ls-common-client';
import { AppContext } from '../../../../context';
import { Table } from '../../../common';
import TableColumns from './TableColumns';

const { Container, Text, Loader } = components;

const marketDataQuery = gql`
  query MarketDataQuery($marketName: String!) {
    marketReportData(marketName: $marketName) {
      salesRep
      budgetHealth
      budget
      sgnb
      sgnbCount
      renewal
      renewalPercent
      new
      newPercent
      total
      deficitSurplus
      currentWeekly
      requiredWeekly
    }
  }
`;
function Team({ ...props }) {
  const { search } = useLocation();
  const {
    user: { user },
    media: { mobile },
    salesReportData,
  } = useContext(AppContext.Context);

  const { displayName, account } = user;
  const { firstName } = account;

  const { loading, repName } = salesReportData;

  const usersName = repName || displayName || firstName || '';

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const {
    data: { marketReportData } = {},
    error,
    loading: dataLoading,
  } = useQuery(marketDataQuery, {
    variables: {
      marketName: query
        .get('marketName')
        .split('-')
        .map(val => val.charAt(0).toUpperCase() + val.slice(1))
        .join(' '),
    },
  });
  const data = useMemo(() => {
    if (marketReportData && !loading) {
      const rows = marketReportData
        .map(item => {
          const arr = [];
          const keys = Object.keys(item).filter(key => key !== '__typename');
          for (let i = 0; i < keys.length; i += 1) {
            arr.push(item[keys[i]]);
          }
          return arr;
        })
        .sort((a, b) => b[2] - a[2]);
      const total = rows.reduce(
        (acc, currentElement) => {
          return acc.map((val, index) => {
            if (val === 'Total') return 'Total';
            return (
              acc[index] + (currentElement[index] ? currentElement[index] : 0)
            );
          });
        },
        ['Total', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      );
      if (total[6] && total[5] && total[9]) {
        total[6] = total[5] / total[9];
      }
      if (total[8] && total[7] && total[9]) {
        total[8] = total[7] / total[9];
      }
      return [total, ...rows].map(row =>
        row.map((value, i) => {
          if (TableColumns[i] && TableColumns[i].title === 'Sales Rep') {
            return TableColumns[i].cell(value, value === usersName);
          }
          if (TableColumns[i] && TableColumns[i].title === 'Current Weekly') {
            return TableColumns[i].cell(value, row[row.length - 1] > value);
          }
          return TableColumns[i] && TableColumns[i].cell && value
            ? TableColumns[i].cell(value)
            : value;
        })
      );
    }
    return undefined;
  }, [marketReportData]);
  return (
    <Container padding={mobile ? '8px' : '0 30px'}>
      {error && <Text>{error}</Text>}
      {dataLoading ||
        (loading && (
          <Container
            width="100%"
            height="60vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Loader width="200px" />
          </Container>
        ))}
      {data && !loading && (
        <Table
          boldHeaders
          textAlign="center"
          cellPadding="15px 17px"
          columnBorder="1px solid #ececec"
          width="100%"
          header={null}
          headings={TableColumns.map(column => column.title)}
          data={data}
          responsive={false}
          {...props}
        />
      )}
    </Container>
  );
}

export default Team;
