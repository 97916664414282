import React from 'react';
import PropTypes from 'prop-types';

const Overview = ({ fill }) => (
  <svg
    width="21px"
    height="17px"
    viewBox="0 0 21 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="-38.5620117%"
        y1="70.7442419%"
        x2="100%"
        y2="22.6350397%"
        id="Overview-linearGradient-1"
      >
        <stop stopColor={fill || '#66EADC'} offset="0%" />
        <stop stopColor={fill || '#40A499'} offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-51.000000, -153.000000)"
        fill="url(#Overview-linearGradient-1)"
        fillRule="nonzero"
      >
        <path d="M68.2280109,169.810531 C68.561995,169.810531 68.8217603,169.550765 68.8217603,169.216781 L68.8217603,169.216781 L68.8217603,162.314444 L70.1948059,163.68749 C70.3061339,163.798818 70.4545713,163.873037 70.6030086,163.873037 C70.751446,163.873037 70.8998834,163.798818 71.0112114,163.68749 C71.2709768,163.464834 71.2709768,163.09374 71.0112114,162.871084 L71.0112114,162.871084 L68.673323,160.496087 C68.6362136,160.496087 68.6362136,160.496087 68.6362136,160.458977 L68.6362136,160.458977 L61.8823138,153.742187 L61.140127,153 C61.0659084,153 60.9916897,153.037109 60.9545804,153.111328 L60.9545804,153.111328 L60.2866122,153.779296 L53.5698218,160.458977 C53.5698218,160.496087 53.5698218,160.496087 53.5327124,160.496087 L53.5327124,160.496087 L51.194824,162.871084 C50.9350587,163.09374 50.9350587,163.464834 51.194824,163.68749 C51.3061521,163.798818 51.4545894,163.873037 51.6030268,163.873037 C51.7514641,163.873037 51.8999015,163.798818 52.0112295,163.68749 L52.0112295,163.68749 L53.3842751,162.314444 L53.3842751,169.216781 C53.3842751,169.550765 53.6440404,169.810531 53.9780245,169.810531 L53.9780245,169.810531 L68.2280109,169.810531 Z" />
      </g>
    </g>
  </svg>
);

Overview.propTypes = {
  fill: PropTypes.string,
};

Overview.defaultProps = {
  fill: null,
};

export default Overview;
