import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';

const IconValue = ({ value, valueColor, icon, label, render, ...props }) => (
  <Container
    display="flex"
    alignItems="center"
    minWidth={icon ? '160px' : undefined}
    margin="20px"
    maxWidth={typeof value === 'number' ? 'unset' : '140px'}
    justifyContent="center"
    {...props}
  >
    {icon ? <Container marginRight="15px">{icon}</Container> : null}
    <Container display="flex" flexDirection="column">
      {typeof value === 'number' ? (
        <Text color={valueColor} fontSize="30px">
          {render(value)}
        </Text>
      ) : (
        <Text color="text500" lineHeight="1.2" fontSize="16px" fontWeight="600">
          No Data Available
        </Text>
      )}

      <Text color="text300" fontWeight="600" fontSize="16px" lineHeight="1.2">
        {label}
      </Text>
    </Container>
  </Container>
);

IconValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  label: PropTypes.string,
  render: PropTypes.func,
  valueColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

IconValue.defaultProps = {
  value: null,
  icon: null,
  label: null,
  render: val => val,
  valueColor: 'text500',
};

export default IconValue;
