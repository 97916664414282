import React, { useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import NoDataIcon from '../../images/NoDataIcon';

const StyledContainer = styled(Container)`
  grid-template-columns: auto auto;
`;

const StyledCanvas = styled.canvas`
  width: 100% !important;
  position: relative;
`;

const labelChanges = {
  LS_Online: 'LS Online',
  'Other Metropolitan area (Not on list)': 'Other Metropolitan',
};

const colors = [
  '#FECD56',
  '#2BA0EF',
  '#FF6383',
  '#4BC0C0',
  '#FF9F40',
  '#8F92FF',
  '#FBBC00',
  '#83CB79',
  '#FBB016',
  '#40A499',
  '#F16159',
  '#2A6962',
];

const hoverColors = [
  '#FECD56',
  '#2BA0EF',
  '#FF6383',
  '#4BC0C0',
  '#FF9F40',
  '#8F92FF',
  '#FBBC00',
  '#83CB79',
  '#FBB016',
  '#40A499',
  '#F16159',
  '#2A6962',
];

const DoughnutChart = ({ data, showLegend, showTooltip, width, height }) => {
  const rawData = useMemo(
    () => data.reduce((acc, { value }) => (value ? [...acc, value] : acc), []),
    [data]
  );
  const total = useMemo(() => data.reduce((acc, { value }) => acc + value, 0), [
    data,
  ]);
  const labels = useMemo(
    () =>
      data.reduce(
        (acc, { key, value }) =>
          value ? [...acc, labelChanges[key] || key] : acc,
        []
      ),
    [data]
  );
  const dataColors = useMemo(
    () =>
      data.reduce(
        (acc, { color, value }) => (color && value ? [...acc, color] : acc),
        []
      ),
    [data]
  );

  const chartRef = useRef();

  const getPercentage = i => `${((rawData[i] / total) * 100).toFixed(2)}%`;

  const titleToolTipCallback = ([{ index }]) => labels[index];

  const labelToolTipCallback = ({ index }) =>
    `$${Math.round(rawData[index]).toLocaleString()} (${getPercentage(index)})`;

  useEffect(() => {
    if (total) {
      const chart = new Chart(chartRef.current, {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              data: rawData,
              backgroundColor: dataColors.length ? dataColors : colors,
              hoverBorderColor: dataColors.length ? dataColors : hoverColors,
              hoverBackgroundColor: dataColors.length
                ? dataColors
                : hoverColors,
            },
          ],
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 50,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: showTooltip,
            callbacks: {
              label: labelToolTipCallback,
              title: titleToolTipCallback,
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
    return () => {};
  }, [data]);

  return (
    <Container
      padding="15px"
      flex="1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Container width={width} height={height}>
        {total ? (
          <StyledCanvas ref={chartRef} />
        ) : (
          <Container
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <NoDataIcon />
            <Text
              marginTop="20px"
              color="text300"
              lineHeight="1.2"
              fontSize="16px"
              fontWeight="600"
            >
              No Data Available
            </Text>
          </Container>
        )}
      </Container>
      {!!total && showLegend && (
        <StyledContainer
          display="grid"
          width="100%"
          maxWidth="250px"
          marginTop="15px"
        >
          {labels.map((label, index) => (
            <Container
              key={label}
              display="flex"
              alignItems="flex-start"
              flex="0 0 50%"
              marginBottom="5px"
            >
              <Container
                minWidth="25px"
                height="8px"
                backgroundColor={
                  dataColors.length ? dataColors[index] : colors[index]
                }
                marginRight="3px"
                marginTop="2px"
              />
              <Text
                color="text300"
                lineHeight="1.1"
                fontSize="10px"
                paddingRight="5px"
              >
                {label}
              </Text>
            </Container>
          ))}
        </StyledContainer>
      )}
    </Container>
  );
};

DoughnutChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  showLegend: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

DoughnutChart.defaultProps = {
  width: '190px',
  height: '190px',
  data: [],
  showLegend: true,
  showTooltip: true,
};

export default DoughnutChart;
