import React from 'react';

const PinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="46"
    viewBox="0 0 39 46"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DCDCDC"
        d="M20.331 44c-.898 0-1.706-.27-2.423-.809C10.543 37.76 5.38 29.902 3.405 21.013c0-.046-.045-.135-.045-.18C3.135 19.667 3 18.5 3 17.332 3.046 7.768 10.813 0 20.331 0c9.519 0 17.286 7.768 17.286 17.286 0 1.257-.135 2.515-.403 3.726 0 .046 0 .136-.046.18-1.975 8.71-7.005 16.433-14.143 21.82l-.538.404c-.046.046-.09.046-.135.09-.628.315-1.301.494-2.02.494z"
        transform="translate(-1010 -259) translate(1011 260)"
      />
      <path
        fill="#4A4A4A"
        stroke="#DCDCDC"
        strokeWidth=".5"
        d="M17.331 44c-.898 0-1.706-.27-2.423-.809C7.543 37.76 2.38 29.902.405 21.013c0-.046-.045-.135-.045-.18C.135 19.667 0 18.5 0 17.332.046 7.768 7.813 0 17.331 0c9.519 0 17.286 7.768 17.286 17.286 0 1.257-.135 2.515-.403 3.726 0 .046 0 .136-.046.18-1.975 8.71-7.005 16.433-14.143 21.82l-.538.404c-.046.046-.09.046-.135.09-.628.315-1.301.494-2.02.494h0zM2.964 20.607c1.885 8.217 6.645 15.49 13.47 20.518.448.36 1.076.403 1.57.135l.45-.314c6.645-4.984 11.314-12.122 13.155-20.115 0-.045 0-.135.046-.178.27-1.077.36-2.2.36-3.322.043-8.128-6.557-14.727-14.684-14.727-8.126 0-14.68 6.6-14.68 14.681 0 1.077.134 2.11.313 3.143v.179h0z"
        transform="translate(-1010 -259) translate(1011 260)"
      />
      <path
        fill="#4A4A4A"
        stroke="#DCDCDC"
        strokeWidth=".5"
        d="M17.331 24.694c-4.086 0-7.408-3.322-7.408-7.408 0-4.087 3.322-7.408 7.408-7.408 4.087 0 7.408 3.321 7.408 7.408 0 4.086-3.321 7.408-7.408 7.408zm0-12.212c-2.648 0-4.803 2.155-4.803 4.804 0 2.648 2.155 4.803 4.803 4.803s4.804-2.155 4.804-4.803c0-2.649-2.156-4.804-4.804-4.804z"
        transform="translate(-1010 -259) translate(1011 260)"
      />
    </g>
  </svg>
);

export default PinIcon;
