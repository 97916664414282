import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import { IconValue } from '../../common';
import { numFormatter } from '../../../lib';
import ShopIcon from '../../../images/ShopIcon';
import CalculatorIcon from '../../../images/CalculatorIcon';

const SGNB = ({ data, ...props }) => {
  const { sgnbSales, sgnbUnits } = data || {};
  return (
    <Panel header="SGNB" {...props}>
      <Container
        padding="10px"
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        <IconValue
          value={sgnbSales}
          render={val => `$${numFormatter(val)}`}
          label="Total Sales"
          icon={<ShopIcon />}
        />
        <IconValue
          value={sgnbUnits}
          render={val => val}
          label="Total Units"
          icon={<CalculatorIcon />}
        />
      </Container>
    </Panel>
  );
};

SGNB.propTypes = {
  data: PropTypes.shape({
    sgnbSales: PropTypes.number,
    sgnbUnits: PropTypes.number,
  }),
};

SGNB.defaultProps = {
  data: null,
};

export default SGNB;
