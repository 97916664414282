import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Container from 'ls-common-client/src/components/Container';
import { IconValue } from '../../../common';
import { AppContext } from '../../../../context';
import PinIcon from '../../../../images/PinIcon';

const MarketBudget = ({ data, ...props }) => {
  const { marketBudgetPercentage } = data || {};
  const {
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <Panel header="Market Budget" {...props}>
      <Container display="flex" justifyContent="space-evenly">
        <IconValue
          render={val => `${Math.round(val * 100)}%`}
          value={marketBudgetPercentage}
          label="Increase"
          icon={<PinIcon />}
          minWidth="140px"
          margin={mobile ? '20px 0' : '20px 13px'}
        />
      </Container>
    </Panel>
  );
};

MarketBudget.propTypes = {
  data: PropTypes.shape({
    marketBudgetPercentage: PropTypes.number,
  }),
};

MarketBudget.defaultProps = {
  data: null,
};

export default MarketBudget;
