import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Icon from 'ls-common-client/src/components/Icon';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Button from 'ls-common-client/src/components/Button';
import { AppContext } from '../context';
import budgetTrackerLogo from '../images/budgetTrackerLogo.svg';
import BudgetTrackerIcon from '../images/BudgetTrackerIcon';
import Cog from '../images/Cog';
import OverviewIcon from '../images/Overview';
import MarketIcon from '../images/Market';

const StyledAnchorLink = styled(AnchorLink)`
  border-left: ${props =>
    props.active
      ? `4px solid ${props.theme.secondary.secondary400}`
      : '4px solid white'};
  align-items: center;
  background-color: ${props =>
    props.active ? 'rgba(75,192,192,0.15)' : 'none'};
  &:hover {
    background-color: ${props =>
      props.active ? 'rgba(75,192,192,0.15)' : 'rgba(0, 0, 0, 0.05)'};
  }
`;

const StyledAnchor = styled(Anchor)`
  color: ${props => props.theme.secondary.secondary300};
`;

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #55d4c6 0%, #40a499 100%);
  margin-top: 30px;
`;

const toSlug = str => str.toLowerCase().replace(/ /g, '-');

const SideBar = ({ sideBarClosed, openSideBar, closeSideBar }) => {
  const { pathname, search } = useLocation();
  const {
    media: { mobile, desktop },
    salesReportData,
    allocationDialog: { setShowAllocationDialog },
  } = useContext(AppContext.Context);

  const { salesReportData: { markets = [] } = {} } = salesReportData || {};

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const marketMenuItems = useMemo(
    () =>
      markets.map(({ marketName }) => ({
        text: marketName,
        icon: fill => <MarketIcon fill={fill} id={toSlug(marketName)} />,
        to: `/market`,
        params: `?marketName=${toSlug(marketName)}${
          query.get('staffId') ? `&staffId=${query.get('staffId')}` : ''
        }`,
      })),
    [markets]
  );

  const menuItems = [
    {
      text: 'Overview',
      icon: fill => <OverviewIcon fill={fill} />,
      to: '/',
      params: query.get('staffId') ? `?staffId=${query.get('staffId')}` : '',
    },
    ...marketMenuItems,
  ];

  const isPathMatch = ({ to, params }) => {
    const pathParams = new URLSearchParams(params);

    if (
      to === '/market' &&
      pathParams.get('marketName') === query.get('marketName')
    ) {
      return true;
    }

    if (to === pathname && to !== '/market') {
      return true;
    }

    return false;
  };

  return (
    <>
      <Container
        boxShadow="0 2px 20px 3px rgba(0, 0, 0, 0.1)"
        backgroundColor="white"
        width="100%"
        transition="all 0.3s ease"
        maxWidth={sideBarClosed ? '100px' : '280px'}
        height="100vh"
        maxHeight="100%"
        position={!desktop ? 'fixed' : 'sticky'}
        top="0"
        transform={!desktop && sideBarClosed ? 'translateX(-100px)' : 'none'}
        display="flex"
        flexDirection="column"
        zIndex="5"
      >
        <Container
          padding="20px"
          display="flex"
          justifyContent={mobile ? 'flex-start' : 'center'}
          alignItems="center"
          height="77px"
        >
          {sideBarClosed ? (
            <BudgetTrackerIcon />
          ) : (
            <img
              alt="Budget Tracker Logo"
              src={budgetTrackerLogo}
              width={mobile ? '170px' : undefined}
            />
          )}
          {!sideBarClosed && (
            <EmptyButton
              marginLeft={mobile ? 'auto' : '10px'}
              onClick={closeSideBar}
            >
              <Icon
                iconSize={mobile ? '20px' : '33px'}
                className="ls-icon icon-generalcaretleftlarge"
              />
            </EmptyButton>
          )}
        </Container>

        <Container
          overflowY="auto"
          overflowX="hidden"
          flex="1"
          display="flex"
          flexDirection="column"
        >
          {!mobile && (
            <Container
              transition="all 0.3s ease"
              opacity={sideBarClosed ? 1 : 0}
              marginBottom="5px"
            >
              <EmptyButton padding="0 39px" onClick={openSideBar}>
                <Icon
                  iconSize="medium"
                  className="ls-icon icon-generalmenu"
                  iconColor="normal"
                />
              </EmptyButton>
            </Container>
          )}
          <Container>
            {menuItems.map(({ text, to, icon, params }) => (
              <StyledAnchorLink
                to={`${to}${params || ''}`}
                onClick={() => {
                  if (!desktop) {
                    closeSideBar();
                  }
                  window.scroll(0, 0);
                }}
                key={text}
                height={sideBarClosed ? '65px' : '55px'}
                transition="all 0.2s ease"
                active={isPathMatch({ to, params }) ? 1 : 0}
                display="block"
                width="100%"
                padding={sideBarClosed ? '10px 10px 0 6px' : '17px 15px 0 0'}
              >
                <Container display="flex">
                  <Container
                    marginLeft="26px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minWidth="30px"
                    marginRight="15px"
                  >
                    {icon(isPathMatch({ to, params }) ? undefined : '#9b9b9b')}
                  </Container>
                  <Text
                    color={isPathMatch({ to, params }) ? 'normal' : 'text300'}
                    fontSize="16px"
                    fontWeight="600"
                    transition="all 0.3s ease"
                    opacity={sideBarClosed ? 0 : 1}
                    whiteSpace="nowrap"
                  >
                    {text}
                  </Text>
                </Container>
                <Text
                  color={isPathMatch({ to, params }) ? 'normal' : 'text300'}
                  fontSize="12px"
                  fontWeight="600"
                  transition="all 0.3s ease"
                  opacity={sideBarClosed ? 1 : 0}
                  textAlign="center"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  width="76px"
                  marginTop="5px"
                  display={mobile ? 'none' : 'block'}
                >
                  {text}
                </Text>
              </StyledAnchorLink>
            ))}
          </Container>

          <Container
            transition="all 0.3s ease"
            opacity={sideBarClosed ? 0 : 1}
            padding="40px"
            marginTop="auto"
            flexDirection="column"
            minWidth="300px"
          >
            <Container marginBottom="5px">
              <Cog />
            </Container>
            <Text fontWeight="600" fontSize="16px" marginBottom="10px">
              Having trouble?{' '}
            </Text>
            <Text fontSize="14px" color="text300" lineHeight="1.3">
              For any technical issues, please contact{' '}
              <StyledAnchor href="mailto:products@localsearch.com.au">
                our products team
              </StyledAnchor>
              . Your PM can help you with any sales or figure-specific issues.
            </Text>
            <StyledButton
              rounded
              secondary
              minHeight="44px"
              onClick={() => setShowAllocationDialog(true)}
            >
              Request More Allocation
            </StyledButton>
          </Container>
        </Container>
      </Container>
    </>
  );
};

SideBar.propTypes = {
  sideBarClosed: PropTypes.bool,
  openSideBar: PropTypes.func,
  closeSideBar: PropTypes.func,
};

SideBar.defaultProps = {
  sideBarClosed: false,
  openSideBar: () => {},
  closeSideBar: () => {},
};

export default SideBar;
