import React from 'react';

const MoneyTree = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="300"
    height="216"
    viewBox="0 0 300 216"
  >
    <defs>
      <path id="bmkia0fita" d="M0 0L103.077 0 103.077 32.293 0 32.293z" />
      <path id="8k6544o29c" d="M0 0L149.508 0 149.508 168.69 0 168.69z" />
      <path id="ryxkhe3rxe" d="M0 13.052L0 13.052 46.285 0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#EBFFFD"
            d="M24.157 214.838S-11.14 174.448 3.6 126.974c13.229-42.61 73.041-70.186 104.823-49.083 15.375 10.209 27.797-30.824 78.824-45.845 37.734-11.108 93.532 13.804 79.36 90.093-10.775 58.002 42.67 29.083 31 93.16l-273.449-.461"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#A1DAD4"
            d="M284.233 214.67l-36.506.573c-1.31-1.11 5.895-4.568 15.271-4.374 1.01.02 2.279.135 3.733.441.337-4.465 2.258-24.538 9.503-23.733 8.146.904 0 8.841-2.302 13.54-1.706 3.48-3.506 8.472-4.327 10.827.66.144 1.346.283 2.045.413 1.08-3.312 5.096-14.45 10.205-13.8 5.998.76 3.587 5.224-.664 7.876-3.12 1.948-6.95 4.719-8.768 6.057 1.54.252 3.15.421 4.783.383 4.19-.1 6.235 1.134 7.027 1.798M21.378 213.818c-.868-1.362-3.48-4.897-6.96-4.428-3.79.509 4.187 3.005 6.63 4.438-.543.008-1.01-.036-1.352-.158-1.632-.581-4.429.534-5.323.926h15.544c-.107-.373-.387-.979-1.111-1.42-.255-.156-.768-.178-1.43-.128.924-1.088 1.566-4.564-1.008-3.67-1.88.654-1.874 2.778-1.69 4.034-.4.063-.805.126-1.206.183-.773-3.938-3.51-15.484-9.059-15.792-5.624-.312 4.77 11.974 8.23 15.904-.443.052-.87.092-1.265.111"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFB93E"
            d="M96.61 210.502v2.965c0 .237-.072.457-.196.639-.13.192-.318.343-.537.428-.053.02-.107.037-.163.049h-.001l-.046.01c-.066.012-.132.017-.201.017l-47.31-.007c-.05 0-.1-.003-.148-.01-.019-.002-.038-.005-.056-.009-.515-.092-.912-.533-.94-1.065V210.494l.001-.06c.031-.601.534-1.084 1.144-1.084l47.309.007c.03 0 .062.002.091.005l.038.003.043.005.03.006c.073.012.143.033.21.06l.029.01.029.014.057.028c.022.01.043.022.064.035l.052.034c.021.015.04.028.06.044.015.01.03.023.043.035l.003.003.03.026c.029.027.057.056.082.086l.023.026.022.028c.015.019.03.038.043.057.123.183.195.402.195.64"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFA412"
            d="M73.379 214.607l-25.346-.004-.06-.001c-.022-.002-.043-.003-.065-.008-.487-.066-.87-.52-.895-1.075l-.001-.059v-2.966l.001-.06c.028-.601.476-1.083 1.021-1.083l22.232.003.031.13c.368 1.521 1.192 3.565 3.036 5.085.015.014.03.025.046.038M90.854 214.61L90.315 214.61 90.316 209.357 90.854 209.357 90.854 214.61M88.496 214.61L89.034 214.61 89.034 209.357 88.496 209.357zM86.851 214.609L86.313 214.609 86.314 209.356 86.851 209.357 86.851 214.609M83.505 214.609L82.967 214.609 82.968 209.356 83.506 209.356 83.505 214.609M79.968 214.609L79.43 214.609 79.431 209.356 79.968 209.356 79.968 214.609M74.544 214.607L74.006 214.607 74.007 209.354 74.545 209.354 74.544 214.607"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFA412"
            d="M69.466 214.607L68.928 214.607 68.929 209.354 69.466 209.354 69.466 214.607M64.931 214.606L64.394 214.606 64.394 209.353 64.932 209.353 64.931 214.606M92.049 214.61L92.586 214.61 92.586 209.357 92.049 209.357zM93.193 214.61L93.731 214.61 93.731 209.357 93.193 209.357zM94.836 214.61L94.298 214.61 94.299 209.357 94.836 209.357 94.836 214.61M95.669 209.376l-.002 5.217c-.066.012-.132.017-.201.017h-.337v-5.253h.337c.069 0 .137.008.203.02M96.415 209.864l-.001 4.242c-.13.192-.318.343-.537.428v-5.099c.22.085.407.236.538.429"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFB93E"
            d="M99.057 205.378l-.002 2.967c0 .236-.072.455-.195.638-.131.192-.318.343-.538.427-.068.027-.137.047-.21.059-.066.012-.132.019-.201.019l-47.31-.007c-.19 0-.37-.047-.527-.13-.335-.176-.574-.512-.612-.903l-.004-.052-.001-.059v-2.967l.001-.058c.032-.602.534-1.085 1.144-1.085l47.309.008c.069 0 .136.006.202.019.072.012.143.03.209.059.22.083.407.234.538.427.123.183.197.402.197.638"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFA412"
            d="M75.824 209.485l-25.346-.004c-.17 0-.329-.047-.47-.13-.3-.176-.513-.512-.546-.903l-.004-.052-.001-.059v-2.967l.001-.058c.028-.602.477-1.085 1.021-1.085l22.233.003c.346 1.506 1.137 3.57 2.956 5.124l.156.13M92.761 209.486L93.299 209.486 93.299 204.235 92.761 204.235zM91.479 209.486L90.941 209.486 90.941 204.233 91.48 204.233 91.479 209.486M89.295 209.486L88.758 209.486 88.759 204.233 89.297 204.233 89.295 209.486M85.95 209.486L85.413 209.486 85.413 204.233 85.952 204.233 85.95 209.486M82.412 209.485L81.875 209.485 81.876 204.232 82.414 204.233 82.412 209.485M76.989 209.485L76.451 209.485 76.452 204.232 76.99 204.232 76.989 209.485"
            transform="translate(-833 -344) translate(833 344)"
          />
          <path
            fill="#FFA412"
            d="M71.911 209.483L71.373 209.483 71.374 204.23 71.912 204.232 71.911 209.483M67.377 209.483L66.839 209.483 66.84 204.23 67.377 204.23 67.377 209.483M95.031 209.488L94.493 209.488 94.495 204.235 95.033 204.235 95.031 209.488M96.176 209.488L95.638 209.488 95.639 204.235 96.177 204.235 96.176 209.488M97.28 209.488L96.743 209.488 96.744 204.235 97.282 204.235 97.28 209.488M98.113 204.254l-.001 5.215c-.065.012-.132.019-.201.019h-.337l.002-5.253h.336c.068 0 .135.006.201.019M98.86 204.74v4.243c-.131.192-.318.343-.538.427v-5.097c.22.083.407.234.538.427"
            transform="translate(-833 -344) translate(833 344)"
          />
        </g>
        <g transform="translate(-833 -344) translate(833 344) translate(71.431 182.303)">
          <mask id="6fklpshumb" fill="#fff">
            <use xlinkHref="#bmkia0fita" />
          </mask>
          <path
            fill="#82B378"
            d="M77.617 43.477l-.732.254c-3.026-6.324-7.578-10.966-11.994-14.847-4.73-4.158-14.043-9.248-25.652-8.302-4.786.39-8.323 1.373-10.046 1.938l-.376-.84c22.435-9.31 35.654.835 42.554 10.173 4.418 5.978 6.246 11.624 6.246 11.624"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#4BC0C0"
            d="M103.077 29.772l-25.46 13.704c-1.95-3.989-4.884-7.705-7.33-10.424-2.445-2.718-4.405-4.44-4.405-4.44-15.595-13.72-37.065-6.934-37.065-6.934L47.334 2.281C58.656-1.788 68.69.035 77.017 4.23c16.31 8.218 26.06 25.542 26.06 25.542"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#4BC0C0"
            d="M103.077 29.772l-25.46 13.704c-1.95-3.989-4.884-7.705-7.33-10.424-2.445-2.718-4.405-4.44-4.405-4.44C50.287 14.893 38.287 11.76 38.287 11.76l11.767.223c4.385.083 7.251-2.78 10.914-5.192.836-.55 1.678-2.75 2.41-3.273 4.201-3.004 8.388-1.933 13.64.713 16.309 8.218 26.06 25.542 26.06 25.542"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#40A499"
            d="M77.207 37.395c-1.167-2.503-3.265-6.226-6.638-9.737-3.795-3.952-8.393-6.837-13.668-8.574-5.626-1.855-12.038-2.4-19.099-1.629-.775.085-1.232-.845-.689-1.404L47.55 5.336c.765-.786 1.713-1.37 2.76-1.702 1.994-.63 5.55-1.475 10.107-1.372 4.199.095 8.417.964 12.536 2.581 5.155 2.025 10.171 5.236 14.914 9.547.022.02 2.315 2.07 4.879 4.835 3.374 3.639 5.677 6.85 6.657 9.29L78.595 40.45c-.216-.73-.482-1.113-1.388-3.056m23.386-8.336c-2.452-6.106-11.78-14.969-12.156-15.303-4.825-4.387-9.939-7.658-15.203-9.72-4.22-1.654-8.542-2.537-12.848-2.63-7.346-.155-12.03 2.023-12.228 2.117l-.069.032-15.056 15.46 1.287-.214c8.354-1.396 15.853-1.03 22.29 1.085 5.137 1.69 9.616 4.494 13.314 8.335 6.312 6.556 8.445 13.12 8.463 13.195l22.206-12.357"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#40A499"
            d="M77.23 11.433c1.73 3.86.509 7.205-3.357 8.932-3.867 1.725-8.913 1.181-10.642-2.681-1.728-3.86-.622-7.125 3.245-8.853 3.865-1.727 9.025-1.26 10.754 2.602"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#82B378"
            d="M53.497 60.029l-.815.264c-3.207-7.062-8.14-12.296-12.94-16.687-5.138-4.704-15.328-10.54-28.189-9.749-5.303.326-9.236 1.335-11.155 1.924L0 34.84c25.022-9.803 39.418 1.71 46.845 12.192 4.754 6.71 6.652 12.996 6.652 12.996"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#4BC0C0"
            d="M81.961 45.43L53.497 60.027c-2.068-4.456-5.231-8.63-7.877-11.691-2.645-3.061-4.774-5.009-4.774-5.009C23.9 27.807 0 34.84 0 34.84l20.913-21.047c12.611-4.25 23.67-2.014 32.789 2.811C71.559 26.054 81.96 45.43 81.96 45.43"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#40A499"
            d="M54.645 56.702l23.279-12.744c-1.03-2.719-3.506-6.322-7.157-10.422-2.775-3.115-5.267-5.432-5.291-5.454-5.15-4.875-10.627-8.537-16.286-10.89-4.52-1.88-9.166-2.933-13.809-3.13-5.038-.215-8.99.639-11.208 1.293-1.167.344-2.229.97-3.093 1.823L9.302 28.799c-.615.606-.13 1.645.73 1.567 7.828-.697 14.908.047 21.091 2.223 5.795 2.04 10.818 5.331 14.927 9.786 3.654 3.96 5.893 8.122 7.129 10.917.957 2.169 1.244 2.598 1.466 3.41m-.251 1.063c-.018-.082-2.232-7.39-9.068-14.78-4.005-4.331-8.899-7.531-14.545-9.514-7.072-2.482-15.359-3.052-24.63-1.693l-1.428.21 16.995-16.77.078-.034c.219-.099 5.45-2.404 13.572-2.07 4.761.196 9.522 1.27 14.152 3.19 5.777 2.398 11.363 6.128 16.603 11.088.409.379 10.53 10.386 13.105 17.194L54.394 57.765"
            mask="url(#6fklpshumb)"
          />
          <path
            fill="#82B378"
            d="M53.778 24.575c1.826 4.308.403 7.98-3.91 9.805-4.316 1.825-9.887 1.11-11.713-3.198-1.826-4.31-.53-7.897 3.784-9.721 4.314-1.824 10.011-1.194 11.839 3.114"
            mask="url(#6fklpshumb)"
          />
        </g>
      </g>
      <path
        fill="#4C85D8"
        d="M143.47 192.927s-1.421 10.038.202 16.421h-23.784s11.38-41.608 34.684-65.423c6.69-6.838 12.842-16.574 18.782-27.277 14.746-26.572 28.164-59.104 45.162-67.979 0 0-33.578 49.275-40.08 77.689-6.501 28.414-29.682 27.602-34.967 66.57"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#699FF0"
        d="M170.426 126.358c.692-3.027 1.692-6.292 2.928-9.71-5.94 10.703-12.093 20.44-18.782 27.277-3.433 3.508-6.607 7.403-9.525 11.494-.016-.143-1.054-9.187-4.74-18.374-3.663-9.133-9.938-18.404-20.42-19.211 0 0 25.372 19.415 12.5 59.224-8.372 17.203-14.602 32.29-14.602 32.29h18.298c-1.627-6.383-.202-16.42-.202-16.42 5.283-38.968 28.04-38.156 34.545-66.57"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4C85D8"
        d="M145.047 155.422s-.033.06-.095.164c-.666 1.11-4.491 7.37-4.15 3.519.377-4.213-4.37-20.094-4.37-20.094l3.874-1.966c3.716 9.26 4.741 18.377 4.741 18.377"
        transform="translate(-833 -344) translate(833 344)"
      />
      <g transform="translate(-833 -344) translate(833 344) translate(110.554 45.906)">
        <mask id="vpcn4scopd" fill="#fff">
          <use xlinkHref="#8k6544o29c" />
        </mask>
        <path
          fill="#4BC0C0"
          d="M149.504 37.261l.004.005-.703 1.07c-2.236-1.158-6.866-3.292-13.34-4.889-11.74-2.895-22.471-.882-30.234 2.261-2.623 1.061-4.909 2.254-6.78 3.428-6.917 4.342-14.191 9.693-19.746 17.693l-.948-.512L45.904 31.88s3.73-4.68 10.162-10.451C63.45 14.8 74.384 6.733 87.311 2.646 94.766.29 102.884-.743 111.361.588c5.564.875 11.285 2.768 17.077 5.973l21.07 30.702-.004-.002"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#82B378"
          d="M25.46 195.491l.732.254c3.027-6.324 7.58-10.965 11.996-14.847 4.728-4.158 14.042-9.248 25.65-8.302 4.787.39 8.323 1.374 10.046 1.939l.378-.841c-22.436-9.31-35.654.835-42.556 10.173-4.418 5.978-6.245 11.624-6.245 11.624"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#4BC0C0"
          d="M0 181.786l25.46 13.704c1.95-3.99 4.884-7.705 7.33-10.424 2.447-2.718 4.405-4.439 4.405-4.439 15.595-13.721 37.066-6.935 37.066-6.935l-18.518-19.397c-11.32-4.069-21.356-2.246-29.683 1.949C9.75 164.46 0 181.786 0 181.786"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#4BC0C0"
          d="M25.87 189.41c1.167-2.504 3.267-6.227 6.64-9.738 3.793-3.952 8.392-6.837 13.666-8.574 5.627-1.856 12.038-2.4 19.1-1.629.776.085 1.232-.845.688-1.404L55.53 157.35c-.765-.786-1.714-1.37-2.76-1.702-1.996-.632-5.551-1.475-10.107-1.372-4.2.096-8.418.964-12.536 2.581-5.157 2.024-10.173 5.236-14.914 9.547-.023.02-2.317 2.07-4.88 4.835-3.375 3.639-5.677 6.85-6.657 9.29l20.808 11.937c.214-.731.482-1.114 1.387-3.057m-23.385-8.336c2.45-6.106 11.778-14.968 12.154-15.303 4.825-4.387 9.94-7.658 15.205-9.72 4.218-1.653 8.541-2.538 12.846-2.63 7.346-.155 12.031 2.023 12.228 2.117l.069.032 15.056 15.46-1.287-.214c-8.354-1.397-15.852-1.03-22.289 1.086-5.137 1.689-9.617 4.493-13.315 8.334-6.311 6.556-8.443 13.12-8.462 13.193L2.486 181.073"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#82B378"
          d="M25.847 163.446c-1.728 3.862-.508 7.206 3.357 8.931 3.867 1.728 8.914 1.182 10.643-2.679 1.727-3.86.621-7.125-3.244-8.852-3.867-1.728-9.027-1.26-10.756 2.6"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#82B378"
          d="M84.14 190.87l-.731.253c-3.026-6.324-7.58-10.966-11.996-14.847-4.727-4.158-14.042-9.248-25.651-8.302-4.785.39-8.322 1.373-10.045 1.938l-.378-.84c22.436-9.31 35.654.834 42.555 10.173 4.418 5.978 6.247 11.624 6.247 11.624"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#4BC0C0"
          d="M109.6 177.164l-25.46 13.704c-1.95-3.99-4.884-7.705-7.329-10.424-2.446-2.718-4.405-4.44-4.405-4.44-15.595-13.72-37.065-6.934-37.065-6.934l18.517-19.397c11.32-4.069 21.356-2.246 29.683 1.949 16.308 8.218 26.059 25.542 26.059 25.542"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#40A499"
          d="M83.73 184.787c-1.166-2.503-3.265-6.226-6.638-9.737-3.795-3.952-8.392-6.837-13.667-8.574-5.628-1.856-12.038-2.4-19.1-1.629-.776.085-1.233-.845-.688-1.404l10.434-10.715c.766-.786 1.713-1.37 2.76-1.702 1.994-.632 5.55-1.475 10.107-1.372 4.2.095 8.417.964 12.537 2.581 5.155 2.025 10.172 5.236 14.913 9.547.024.02 2.317 2.07 4.88 4.835 3.375 3.639 5.678 6.85 6.656 9.29l-20.806 11.936c-.216-.73-.482-1.113-1.387-3.056m23.385-8.336c-2.453-6.106-11.779-14.969-12.155-15.303-4.825-4.388-9.94-7.658-15.204-9.72-4.22-1.654-8.542-2.537-12.848-2.63-7.346-.155-12.031 2.023-12.227 2.117l-.07.032-15.055 15.46 1.287-.214c8.353-1.396 15.853-1.03 22.29 1.085 5.137 1.69 9.616 4.493 13.314 8.335 6.312 6.556 8.443 13.12 8.463 13.193l22.205-12.355"
          mask="url(#vpcn4scopd)"
        />
        <path
          fill="#82B378"
          d="M83.753 158.825c1.729 3.86.51 7.205-3.357 8.93-3.866 1.727-8.914 1.182-10.641-2.68-1.73-3.86-.622-7.125 3.244-8.852 3.865-1.727 9.026-1.26 10.754 2.602"
          mask="url(#vpcn4scopd)"
        />
      </g>
      <path
        fill="#40A499"
        d="M215.785 81.614c-2.623 1.061-4.909 2.254-6.779 3.428-6.918 4.342-14.192 9.693-19.747 17.693l-.948-.512-26.282-20.164c1.346-5.394 2.892-10.302 4.592-14.725 7.382-6.628 18.317-14.695 31.244-18.782 7.455-2.357 15.574-3.389 24.05-2.059.286.184.437.287.437.287s-13.22 16.04-6.567 34.834"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4BC0C0"
        d="M181.766 73.825l-39.907 4.558-1.074-.048c-.236-3.621-.866-7.047-1.76-10.3-1.497-5.475-3.727-10.456-6.042-15.024-1.41-2.784-3.516-6.024-6.34-9.254-5.086-5.815-12.514-11.605-22.453-14.663-6.372-1.962-11.44-2.516-13.953-2.674l-.054-1.28h.003l33.893-15.476c6.359.222 12.031 1.447 17.097 3.418 8.242 3.205 14.865 8.375 20.175 14.383 7.753 8.772 12.698 19.327 15.776 28.152 3.589 10.281 4.64 18.208 4.64 18.208"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M177.127 55.617c-.575 7.402-4.35 13.443-12.145 15.646-8.52 2.408-17.926.497-25.957-3.228-1.497-5.475-3.727-10.456-6.042-15.024-1.41-2.784-3.516-6.024-6.34-9.254 17.643-4.12 14.533-30.675 14.533-30.675 8.242 3.205 14.865 8.375 20.175 14.383 7.753 8.772 12.698 19.327 15.776 28.152"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#A1DAD4"
        d="M130.182 51.04s-.721-7.55-14.853-4.499c0 0 5.58 6.824 14.853 4.5"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M239.189 124.028l.008.006-21.072-30.704c-14.614-8.089-28.776-7.818-41.125-3.914-24.187 7.646-41.408 29.232-41.408 29.232l31.854 24.438v.001l.946.51c5.557-7.998 12.83-13.35 19.749-17.691 7.408-4.652 21.307-9.563 37.011-5.689 6.476 1.596 11.106 3.73 13.342 4.888l.703-1.071-.008-.006"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M83.797 89.546l-.01.004 21.073-30.702c14.614-8.089 28.776-7.819 41.126-3.915 24.187 7.647 41.409 29.233 41.409 29.233l-31.856 24.438h.001l-.947.511c-5.557-7.999-12.83-13.35-19.748-17.692-7.407-4.65-21.308-9.563-37.012-5.689-6.475 1.598-11.104 3.73-13.341 4.888l-.704-1.07.009-.006"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#38988D"
        d="M152.489 73.468l-.952-.5c3.54-9.07 3.922-18.084 3.665-26.24-.274-8.736-3.958-22.996-15.946-33.843-4.942-4.472-9.3-7.116-11.511-8.323l.497-1.18c30.757 13.857 33.02 36.87 30.106 52.723-1.868 10.15-5.86 17.363-5.86 17.363"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4BC0C0"
        d="M190.577 86.211L152.49 73.467c2.195-5.763 3.177-12.262 3.611-17.32.434-5.056.32-8.673.32-8.673-.898-28.814-28.178-44.093-28.178-44.093l37.267.311c14.881 7.59 22.566 19.474 26.22 31.886 7.156 24.31-1.153 50.633-1.153 50.633"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M156.486 71.583l31.875 9.714c1.543-3.308 2.615-8.69 3.1-15.56.37-5.221.27-9.488.268-9.53-.131-8.894-1.64-17.02-4.483-24.16-2.273-5.705-5.399-10.798-9.29-15.137-4.226-4.71-8.459-7.506-10.993-8.923-1.331-.745-2.825-1.15-4.35-1.177l-20.78-.38c-1.084-.02-1.594 1.326-.771 2.031 7.49 6.412 13.028 13.417 16.492 20.873 3.247 6.988 4.689 14.381 4.286 21.974-.359 6.75-2.14 12.409-3.57 15.968-1.11 2.761-1.246 3.394-1.784 4.307zm-1.174.704c.058-.088 4.69-8.481 5.344-21.096.383-7.39-1.027-14.587-4.195-21.39-3.966-8.523-10.72-16.458-20.07-23.585l-1.441-1.098 29.984.548.1.039c.28.11 6.94 2.782 13.76 10.363 3.998 4.444 7.208 9.658 9.538 15.497 2.909 7.285 4.452 15.568 4.585 24.618.016.698-.113 18.555-3.977 26.836l-33.628-10.732z"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M177.047 29.283c-5.422-2.272-9.787-.29-12.062 5.127-2.274 5.417 1.97 11.042 7.392 13.313 5.424 2.272 9.975.33 12.25-5.088 2.274-5.417-2.156-11.081-7.58-13.352"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#82B378"
        d="M132.681 114.004l-1.024.332c-4.027-8.866-10.218-15.435-16.243-20.948-6.45-5.904-19.241-13.231-35.387-12.238-6.657.41-11.595 1.677-14.003 2.415l-.5-1.18c31.411-12.306 49.483 2.148 58.807 15.304 5.967 8.424 8.35 16.315 8.35 16.315"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4BC0C0"
        d="M168.413 95.679l-35.733 18.324c-2.596-5.595-6.567-10.835-9.888-14.678-3.32-3.843-5.992-6.286-5.992-6.286-21.274-19.483-51.276-10.655-51.276-10.655l26.253-26.422c15.83-5.335 29.715-2.528 41.16 3.53 22.418 11.861 35.476 36.187 35.476 36.187"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M134.123 109.83l29.221-16c-1.292-3.413-4.4-7.937-8.984-13.083-3.483-3.91-6.61-6.819-6.642-6.848-6.464-6.118-13.341-10.716-20.443-13.67-5.676-2.36-11.507-3.682-17.336-3.93-6.325-.27-11.285.803-14.07 1.625-1.464.432-2.796 1.216-3.882 2.287L77.2 74.8c-.771.76-.163 2.065.917 1.969 9.827-.877 18.713.058 26.476 2.79 7.275 2.56 13.58 6.692 18.74 12.283 4.584 4.97 7.396 10.196 8.946 13.706 1.204 2.722 1.563 3.26 1.843 4.282m-.316 1.332c-.024-.104-2.802-9.277-11.385-18.555-5.027-5.436-11.17-9.454-18.257-11.941-8.878-3.117-19.28-3.832-30.919-2.126l-1.792.262 21.334-21.05.098-.043c.275-.124 6.84-3.018 17.037-2.598 5.976.246 11.953 1.593 17.766 4.005 7.252 3.01 14.264 7.692 20.842 13.918.513.475 13.218 13.039 16.451 21.586l-31.175 16.542"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M133.033 69.497c-2.294-5.409-9.446-6.199-14.861-3.908-5.415 2.29-7.043 6.794-4.751 12.202 2.293 5.41 9.286 6.306 14.702 4.016s7.203-6.9 4.91-12.31M191.953 86.2l1.074.062c1.644-9.595 5.964-17.52 10.392-24.378 4.74-7.346 15.251-17.673 31.121-20.804 6.542-1.29 11.642-1.315 14.159-1.212l.183-1.267c-33.51-3.945-47.319 14.615-52.996 29.703-3.633 9.66-3.933 17.896-3.933 17.896"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4BC0C0"
        d="M152.736 77.528l39.217 8.67c1.092-6.068 3.601-12.144 5.836-16.702 2.236-4.558 4.2-7.599 4.2-7.599C217.62 37.662 248.882 38.6 248.882 38.6l-32.104-18.906c-16.67-1.15-29.386 5.083-38.918 13.842-18.672 17.154-25.124 43.992-25.124 43.992"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M190.192 77.919c.61-3.787 2-9.554 5.174-15.524 3.57-6.715 8.62-12.31 15.006-16.63 6.816-4.608 15.174-7.764 24.901-9.406 1.07-.18 1.327-1.596.388-2.137l-18.007-10.365c-1.323-.76-2.81-1.182-4.336-1.23-2.904-.088-7.975.131-14.023 1.994-5.576 1.717-10.88 4.474-15.77 8.194-6.12 4.657-11.605 10.847-16.303 18.402-.023.036-2.31 3.643-4.686 8.307-3.128 6.14-4.983 11.303-5.367 14.932l32.33 8.071c.011-1.058.221-1.67.693-4.608zm-34.408-2.285c.958-9.086 10.056-24.458 10.431-25.048 4.782-7.688 10.375-13.995 16.625-18.742 5.01-3.806 10.45-6.624 16.168-8.376 9.756-2.99 16.843-1.854 17.14-1.804l.106.018 25.983 14.956-1.801.2c-11.69 1.298-21.572 4.625-29.367 9.89-6.224 4.202-11.145 9.645-14.627 16.176-5.944 11.15-6.303 20.727-6.3 20.832l-34.358-8.102z"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M193.69 35.693c-5.82-.843-12.538 1.733-13.381 7.546-.845 5.813 2.055 9.82 7.875 10.663s12.357-1.796 13.202-7.61c.843-5.812-1.875-9.756-7.696-10.6"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#E2810E"
        d="M154.917 74.324c3.526-14.5 18.155-23.399 32.672-19.876 14.518 3.522 23.427 18.131 19.9 32.631-3.527 14.5-18.154 23.398-32.672 19.876-14.517-3.523-23.427-18.132-19.9-32.631"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#FFB93E"
        d="M152.624 73.767c3.527-14.5 18.154-23.398 32.671-19.876 14.519 3.523 23.428 18.132 19.902 32.632-3.528 14.5-18.155 23.398-32.672 19.876-14.518-3.523-23.427-18.132-19.901-32.632"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#FFA412"
        d="M178.793 57.299c-4.202 0-8.335 1.15-12.014 3.388-5.287 3.216-9.004 8.296-10.465 14.304-1.461 6.008-.493 12.225 2.727 17.505 3.22 5.28 8.307 8.993 14.323 10.452 6.016 1.46 12.24.491 17.527-2.724 5.286-3.217 9.003-8.297 10.464-14.305 3.017-12.402-4.631-24.944-17.05-27.957-1.83-.443-3.677-.663-5.512-.663zm.086 47.78c-1.95 0-3.916-.233-5.862-.704-6.398-1.552-11.807-5.5-15.23-11.115-3.425-5.615-4.455-12.226-2.902-18.616 1.554-6.389 5.506-11.791 11.129-15.212 5.622-3.42 12.241-4.448 18.64-2.897 13.204 3.204 21.339 16.541 18.13 29.73-1.554 6.39-5.506 11.792-11.129 15.212-3.912 2.38-8.308 3.603-12.776 3.603z"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#FFA412"
        d="M180.443 88.198c.188-.502.28-.969.282-1.403 0-.435-.102-.867-.307-1.296-.206-.43-.515-.87-.925-1.322-.41-.45-.934-.938-1.574-1.461-1.095-.91-2.068-1.798-2.915-2.663-.85-.866-1.522-1.75-2.019-2.656-.498-.905-.79-1.855-.88-2.853-.09-.997.084-2.08.52-3.25.378-1.013.902-1.871 1.574-2.573.671-.7 1.457-1.236 2.356-1.608.9-.372 1.888-.578 2.965-.62 1.077-.04 2.214.1 3.411.423l1.427-3.829 3.085 1.148-1.447 3.883c1.052.554 1.947 1.22 2.681 1.995.736.776 1.287 1.633 1.656 2.57.367.94.542 1.951.524 3.039-.017 1.086-.248 2.227-.692 3.419l-5.574-2.072c.534-1.432.615-2.62.243-3.567-.372-.946-1.092-1.619-2.158-2.015-.58-.215-1.107-.32-1.584-.314-.479.005-.905.098-1.28.278-.374.18-.696.437-.966.771-.272.334-.49.722-.654 1.163-.165.442-.25.865-.254 1.27-.005.406.094.82.295 1.24.201.422.513.863.933 1.325.42.463.964.98 1.628 1.553 1.074.93 2.03 1.828 2.87 2.697.84.87 1.508 1.76 2.003 2.67.495.91.787 1.865.876 2.862.09.998-.083 2.075-.513 3.232-.391 1.05-.926 1.925-1.603 2.623-.679.699-1.465 1.228-2.36 1.587-.896.36-1.884.55-2.967.568-1.083.018-2.223-.134-3.42-.457l-1.327 3.56-3.066-1.14 1.32-3.543c-1.028-.504-1.973-1.12-2.835-1.848-.864-.729-1.554-1.573-2.074-2.534-.52-.96-.822-2.04-.903-3.238-.079-1.198.147-2.512.68-3.944l5.575 2.073c-.316.847-.462 1.598-.435 2.252.026.655.176 1.227.448 1.715s.636.896 1.093 1.222c.457.326.95.586 1.476.782.604.225 1.163.345 1.677.36.514.014.977-.058 1.388-.218.412-.16.764-.399 1.058-.717.294-.318.523-.698.688-1.14"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#4BC0C0"
        d="M154.71 87.428L166.731 129.954 65.199 158.585 53.178 116.058 154.71 87.428"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M156.176 120.753c.816 2.884-.862 5.88-3.752 6.694l-77.212 21.772c-2.584.73-5.27-.772-6-3.354l-6.086-21.528c-.804-2.845.851-5.797 3.699-6.601l76.924-21.69c2.782-.786 5.678.833 6.464 3.613l5.963 21.094m-96.148-2.482l9.586 33.91 89.68-25.289-9.585-33.91-89.68 25.29"
        transform="translate(-833 -344) translate(833 344)"
      />
      <path
        fill="#40A499"
        d="M120.39 119.555c-1.647-5.83-7.716-9.222-13.553-7.576-5.837 1.647-9.233 7.707-7.585 13.538 1.648 5.83 7.716 9.22 13.553 7.576 5.838-1.646 9.234-7.707 7.585-13.538"
        transform="translate(-833 -344) translate(833 344)"
      />
      <g transform="translate(-833 -344) translate(833 344) translate(87.35 139.287)">
        <mask id="s0ftn1pz6f" fill="#fff">
          <use xlinkHref="#ryxkhe3rxe" />
        </mask>
        <path
          fill="#B6D3B9"
          d="M0 13.052L46.285 13.052 46.285 0 0 0z"
          mask="url(#s0ftn1pz6f)"
        />
      </g>
    </g>
    <path
      fill="#76A9B4"
      d="M133.635 139.287L133.635 139.287 138.705 137.858 133.635 139.287"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#B6D3B9"
      d="M140.436 137.369L140.436 137.369 154.738 133.337 140.436 137.369"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#679CA8"
      d="M138.705 137.858L138.705 137.858 140.436 137.369 138.705 137.858"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#95C28B"
      d="M133.57 93.389L133.57 93.389 152.997 87.911 133.57 93.389"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#4BC0C0"
      d="M87.35 152.339c2.219-1.39 15.365-5.972 26.954-12.76l44.99-12.687-9.585-33.91-15.015 4.235c-.592-2.096-1.178-3.376-1.124-3.828l21.14-5.961 12.02 42.526-11.992 3.383-14.302 4.032-1.731.489-5.07 1.429-46.285 13.052m31.026-15.291c5.92-3.915 10.96-8.43 13.202-13.393 5.323-11.786 4.63-20.197 3.451-25.152l8.72-2.458c.474-.134.953-.198 1.425-.198 2.286 0 4.387 1.507 5.039 3.812l5.963 21.094c.816 2.884-.862 5.88-3.752 6.694l-34.048 9.6"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#40A499"
      d="M114.304 139.579c1.386-.813 2.751-1.657 4.072-2.531l34.048-9.601c2.89-.815 4.568-3.81 3.752-6.694l-5.963-21.094c-.652-2.305-2.753-3.812-5.04-3.812-.47 0-.95.064-1.424.198l-8.72 2.458c-.109-.458-.222-.886-.335-1.286l15.015-4.234 9.585 33.909-44.99 12.687"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#4BC0C0"
      d="M272.761 99.354L272.955 143.542 167.455 144.003 167.261 99.815 272.761 99.354"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#40A499"
      d="M265.249 131.856c.013 2.998-2.407 5.435-5.408 5.448l-80.23.351c-2.686.012-4.872-2.153-4.884-4.835l-.098-22.371c-.012-2.954 2.374-5.359 5.332-5.37l79.93-.35c2.893-.013 5.249 2.32 5.26 5.21l.098 21.917m-91.979-28.08l.154 35.235 93.185-.407-.154-35.234-93.185.407"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#40A499"
      d="M220.057 110.221c-6.065.027-10.96 4.959-10.933 11.017.026 6.059 4.964 10.948 11.03 10.922 6.065-.027 10.96-4.96 10.934-11.017-.027-6.059-4.965-10.948-11.03-10.922"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#C0D9BB"
      d="M265.753 143.574L265.753 143.574 272.955 143.542 265.753 143.574"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#B6D3B9"
      d="M190.471 143.903h.002l75.28-.329-75.282.329"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#4BC0C0"
      d="M190.473 143.903c2.51-.745 16.404-1.65 29.389-5.095l46.747-.204-.154-35.234-15.601.07c-.01-2.18-.232-3.57-.059-3.99l21.966-.096.194 44.188-7.202.032-75.28.329m33.99-6.445c6.751-2.19 12.819-5.194 16.307-9.377 8.286-9.934 9.87-18.224 10.062-23.312l9.059-.04h.023c2.883 0 5.226 2.329 5.238 5.21l.097 21.917c.013 2.998-2.407 5.435-5.408 5.448l-35.378.154"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#40A499"
      d="M219.862 138.808c1.554-.412 3.094-.86 4.601-1.35l35.378-.154c3.001-.013 5.421-2.45 5.408-5.448l-.097-21.918c-.012-2.88-2.355-5.209-5.238-5.209h-.023l-9.06.04c.018-.471.024-.914.023-1.33l15.601-.069.154 35.234-46.747.204"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#E2810E"
      d="M208.545 131.338c-7.212-13.067-23.666-17.821-36.749-10.618-13.084 7.205-17.843 23.636-10.63 36.705 7.212 13.066 23.665 17.82 36.748 10.616 13.083-7.203 17.843-23.636 10.63-36.703"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M206.478 132.477c-7.212-13.069-23.666-17.821-36.749-10.618-13.083 7.203-17.843 23.636-10.63 36.703 7.212 13.067 23.665 17.82 36.748 10.618 13.084-7.204 17.843-23.636 10.63-36.703"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M182.91 122.641c-3.862 0-7.68.973-11.156 2.886-11.19 6.162-15.277 20.269-9.107 31.446 2.988 5.414 7.91 9.342 13.855 11.06 5.947 1.719 12.208 1.022 17.629-1.963 11.192-6.163 15.278-20.268 9.109-31.447-2.99-5.413-7.91-9.342-13.858-11.06-2.134-.617-4.31-.922-6.473-.922zm.068 47.784c-2.3 0-4.613-.324-6.884-.981-6.323-1.827-11.557-6.003-14.734-11.761-3.18-5.76-3.922-12.408-2.093-18.725 1.83-6.315 6.012-11.542 11.777-14.716 5.765-3.175 12.424-3.916 18.747-2.09 6.325 1.827 11.558 6.005 14.736 11.763 6.56 11.887 2.215 26.888-9.687 33.44-3.695 2.034-7.757 3.07-11.862 3.07z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M189.368 150.42c-.202-.496-.45-.904-.742-1.224-.294-.322-.661-.571-1.104-.749-.443-.177-.968-.293-1.574-.35-.609-.055-1.324-.06-2.15-.013-1.423.068-2.741.07-3.952.004-1.21-.065-2.305-.263-3.284-.594-.978-.332-1.839-.834-2.58-1.509-.74-.675-1.345-1.59-1.815-2.747-.407-1.002-.601-1.988-.58-2.958.02-.97.236-1.896.647-2.776.412-.882 1-1.7 1.766-2.458.766-.757 1.7-1.42 2.8-1.991l-1.54-3.786 3.052-1.237 1.56 3.839c1.15-.301 2.26-.414 3.327-.34 1.066.077 2.054.335 2.96.778.905.445 1.719 1.072 2.442 1.885.723.812 1.324 1.809 1.804 2.989l-5.51 2.233c-.577-1.415-1.322-2.345-2.235-2.793-.914-.446-1.9-.454-2.955-.027-.571.231-1.033.512-1.38.837-.35.328-.6.684-.755 1.069-.154.386-.217.792-.19 1.222.026.428.127.861.305 1.297.176.437.401.806.671 1.108.272.302.623.541 1.058.716.434.175.961.29 1.584.346.623.056 1.374.072 2.25.046 1.42-.041 2.735-.024 3.941.05 1.208.073 2.302.279 3.282.616.981.337 1.842.844 2.583 1.517.742.677 1.344 1.585 1.808 2.73.424 1.037.622 2.042.594 3.015-.026.973-.248 1.893-.664 2.763-.417.869-1.018 1.676-1.804 2.421-.785.744-1.73 1.401-2.83 1.97l1.431 3.52-3.03 1.23-1.424-3.502c-1.1.323-2.213.506-3.341.552-1.13.045-2.21-.11-3.244-.467-1.034-.358-1.985-.95-2.856-1.78-.869-.827-1.591-1.95-2.167-3.364l5.51-2.235c.34.838.742 1.49 1.205 1.953.463.465.96.785 1.49.962.53.177 1.074.23 1.632.162.557-.068 1.096-.207 1.618-.418.596-.244 1.09-.532 1.48-.869.389-.336.68-.701.875-1.096.195-.395.292-.81.294-1.243.001-.434-.085-.869-.263-1.304"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#E2810E"
      d="M157.015 106.513c-.967-11.17-10.817-19.44-22-18.475-11.185.967-19.466 10.805-18.499 21.975.968 11.17 10.82 19.441 22.002 18.474 11.184-.966 19.465-10.804 18.497-21.974"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M155.25 106.666c-.969-11.17-10.82-19.441-22.003-18.475-11.183.967-19.465 10.805-18.497 21.975.968 11.17 10.818 19.44 22.002 18.474 11.184-.966 19.465-10.805 18.497-21.974"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M117.67 110.154c.83 9.555 9.288 16.657 18.852 15.828 9.566-.826 16.675-9.272 15.848-18.827-.401-4.628-2.583-8.824-6.143-11.813-3.561-2.99-8.076-4.415-12.708-4.015-9.566.827-16.675 9.273-15.848 18.827zm17.337 16.998c-9.497 0-17.602-7.281-18.437-16.903-.88-10.16 6.68-19.142 16.853-20.021 4.93-.426 9.728 1.09 13.514 4.27 3.786 3.179 6.107 7.64 6.533 12.563.88 10.16-6.68 19.141-16.853 20.02-.54.047-1.078.07-1.61.07z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M138.023 113.783c.013-.401-.032-.756-.136-1.066-.103-.31-.28-.592-.53-.85-.249-.256-.574-.495-.975-.72-.4-.223-.89-.444-1.472-.663-.997-.386-1.902-.785-2.715-1.199-.812-.414-1.503-.882-2.074-1.407-.572-.525-1.008-1.133-1.31-1.821-.305-.69-.442-1.503-.41-2.44.024-.812.193-1.548.502-2.209.31-.658.74-1.23 1.293-1.709.55-.48 1.204-.863 1.962-1.15.757-.288 1.6-.46 2.53-.515l.099-3.07 2.472.079-.1 3.11c.883.145 1.68.404 2.39.781.709.376 1.307.854 1.795 1.434.486.581.855 1.26 1.103 2.038.248.778.357 1.645.326 2.6l-4.466-.14c.037-1.148-.191-2.014-.682-2.6-.492-.583-1.166-.89-2.021-.918-.463-.014-.866.038-1.204.157-.34.118-.62.286-.844.504-.224.218-.391.478-.504.78-.114.302-.175.631-.187.985-.01.353.03.675.125.965.094.29.263.56.508.813.244.251.572.492.982.72.411.228.92.467 1.532.717.987.403 1.885.816 2.692 1.232.806.419 1.495.893 2.065 1.422.572.531 1.008 1.14 1.312 1.829.303.69.44 1.498.41 2.425-.026.84-.198 1.592-.513 2.251-.314.66-.748 1.225-1.298 1.695-.553.47-1.211.842-1.978 1.115-.767.271-1.615.436-2.545.493l-.09 2.853-2.459-.078.092-2.84c-.854-.111-1.674-.325-2.463-.636-.79-.313-1.485-.749-2.086-1.308-.6-.56-1.074-1.257-1.42-2.09-.343-.834-.497-1.825-.46-2.972l4.466.142c-.022.678.056 1.247.232 1.708.175.46.419.832.73 1.114.31.283.667.486 1.071.607.403.124.815.193 1.239.206.484.014.91-.034 1.28-.147.37-.111.682-.274.937-.487.254-.211.448-.467.581-.763.133-.298.206-.622.216-.977"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#E2810E"
      d="M151.08 51.933c-3.018-5.025-9.544-6.654-14.574-3.64-5.03 3.015-6.663 9.531-3.644 14.556 3.018 5.025 9.542 6.654 14.574 3.64 5.03-3.014 6.661-9.532 3.644-14.556"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M150.285 52.409c-3.018-5.025-9.544-6.654-14.574-3.64-5.03 3.015-6.663 9.532-3.644 14.556 3.018 5.025 9.543 6.654 14.574 3.64 5.03-3.014 6.663-9.531 3.644-14.556"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M133.437 62.651c2.58 4.298 8.183 5.697 12.486 3.118 2.085-1.249 3.557-3.234 4.147-5.59.59-2.355.225-4.799-1.025-6.88-2.581-4.299-8.183-5.697-12.486-3.119-4.304 2.578-5.704 8.173-3.122 12.471zm7.82 4.988c-3.291 0-6.503-1.674-8.316-4.691-2.745-4.571-1.256-10.52 3.32-13.262 4.577-2.742 10.533-1.255 13.28 3.316 1.329 2.214 1.717 4.812 1.09 7.317-.628 2.505-2.194 4.617-4.411 5.945-1.555.931-3.27 1.375-4.963 1.375z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M143.828 59.696c-.085-.192-.188-.348-.309-.47-.119-.122-.267-.214-.443-.277-.177-.064-.384-.102-.623-.115-.24-.013-.52-.005-.843.025-.559.048-1.076.067-1.552.059-.476-.009-.908-.07-1.297-.187-.39-.116-.733-.3-1.034-.555-.3-.254-.551-.605-.753-1.052-.173-.387-.264-.771-.27-1.152-.006-.381.066-.747.214-1.1.15-.351.37-.68.659-.989.29-.308.646-.582 1.07-.821l-.659-1.464 1.18-.529.667 1.484c.448-.135.881-.195 1.301-.18.42.013.811.101 1.173.262.361.161.69.396.986.704.295.31.545.691.75 1.147l-2.13.956c-.247-.547-.552-.902-.917-1.064-.365-.162-.752-.152-1.16.031-.22.1-.398.216-.53.35-.131.133-.225.276-.28.43-.056.153-.075.314-.059.481.017.168.064.337.14.506.075.168.169.31.28.424.11.115.252.203.424.266.173.062.382.1.627.113.245.013.54.009.884-.014.556-.036 1.073-.049 1.548-.037.474.012.906.076 1.295.195.391.118.736.304 1.036.558.301.255.551.603.75 1.045.18.401.273.793.276 1.175.003.382-.07.747-.222 1.094-.15.347-.375.672-.673.976-.297.303-.658.575-1.081.814l.612 1.36-1.172.527-.61-1.354c-.426.142-.86.23-1.303.264-.442.034-.868-.011-1.28-.137-.41-.125-.792-.344-1.146-.657-.352-.312-.652-.742-.899-1.29l2.13-.955c.146.323.313.573.501.749.189.176.388.294.6.356.21.062.425.075.642.04.217-.034.427-.097.629-.187.23-.104.42-.225.568-.362.147-.138.257-.285.328-.443.07-.158.103-.322.097-.492-.005-.17-.046-.34-.122-.508"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#E2810E"
      d="M189.366 14.533c-5.678-1.47-11.475 1.938-12.946 7.61-1.47 5.672 1.94 11.461 7.62 12.93 5.679 1.47 11.475-1.937 12.947-7.61 1.47-5.672-1.94-11.461-7.62-12.93"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M188.47 14.3c-5.68-1.469-11.476 1.938-12.948 7.61-1.47 5.673 1.941 11.462 7.62 12.932 5.68 1.47 11.477-1.938 12.947-7.61 1.47-5.673-1.94-11.462-7.619-12.931"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M185.76 15.599c-1.603 0-3.184.426-4.602 1.26-2.095 1.231-3.583 3.204-4.193 5.554-.61 2.35-.267 4.797.967 6.89 1.232 2.092 3.207 3.58 5.56 4.19.763.196 1.528.29 2.28.29 4.048 0 7.752-2.72 8.813-6.811 1.259-4.852-1.67-9.822-6.528-11.079-.76-.197-1.532-.294-2.298-.294zm.013 18.761c-.801 0-1.615-.1-2.426-.31-2.502-.647-4.603-2.23-5.913-4.454-1.312-2.226-1.677-4.828-1.029-7.327.648-2.5 2.233-4.598 4.46-5.907 2.228-1.31 4.834-1.675 7.336-1.027 2.504.647 4.603 2.23 5.915 4.455 1.31 2.225 1.676 4.827 1.029 7.326-1.129 4.35-5.068 7.244-9.372 7.244z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M186.36 27.742c.077-.196.116-.378.12-.55 0-.17-.036-.34-.114-.51-.078-.17-.197-.344-.355-.524-.159-.18-.362-.374-.61-.583-.425-.364-.8-.718-1.128-1.063-.328-.345-.587-.697-.778-1.055-.189-.358-.298-.733-.327-1.125-.03-.393.044-.817.223-1.273.154-.396.366-.73.633-1.001.268-.271.58-.477.935-.618.355-.14.745-.216 1.168-.225.423-.01.868.052 1.337.186l.583-1.495 1.205.469-.592 1.516c.41.224.756.49 1.041.8.284.308.495.648.635 1.019.138.37.201.769.188 1.195-.013.427-.112.873-.292 1.339l-2.176-.847c.217-.559.257-1.025.116-1.399-.141-.374-.419-.642-.836-.804-.225-.088-.432-.132-.62-.133-.188 0-.356.034-.504.102-.148.068-.276.167-.385.297-.107.13-.195.28-.263.453-.068.172-.103.338-.107.497-.005.16.032.322.109.489.076.166.195.342.358.525.163.184.372.39.63.62.416.371.786.73 1.111 1.076.325.346.582.7.77 1.06.19.36.298.737.327 1.13.03.391-.044.813-.22 1.265-.16.41-.376.75-.645 1.02-.27.27-.583.474-.936.61-.354.136-.743.204-1.17.205-.424 0-.87-.066-1.34-.2l-.541 1.39-1.198-.466.54-1.383c-.4-.204-.767-.451-1.102-.743-.335-.29-.601-.626-.8-1.007-.198-.38-.31-.806-.334-1.277-.025-.47.072-.985.29-1.544l2.176.847c-.129.33-.19.624-.183.882.005.257.061.482.166.676.102.193.244.355.421.486.177.13.369.236.575.316.235.092.455.142.656.15.202.01.384-.016.547-.076.162-.06.301-.152.419-.275.117-.124.21-.271.277-.444"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#E2810E"
      d="M210.07 103.92c-2.377-3.957-1.092-9.09 2.87-11.463 3.961-2.374 9.1-1.091 11.478 2.866 2.377 3.956 1.091 9.09-2.871 11.463-3.961 2.374-9.1 1.09-11.477-2.867"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M209.444 104.295c-2.377-3.958-1.092-9.09 2.87-11.463 3.962-2.374 9.1-1.09 11.478 2.866 2.377 3.957 1.091 9.088-2.871 11.462-3.96 2.375-9.1 1.092-11.477-2.865"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M210.522 103.764c2.034 3.385 6.446 4.487 9.833 2.456 3.39-2.03 4.493-6.437 2.46-9.822-2.034-3.385-6.445-4.486-9.833-2.455-3.39 2.03-4.493 6.435-2.46 9.82zm6.159 3.928c-2.592 0-5.121-1.318-6.548-3.695-2.163-3.6-.989-8.285 2.615-10.444 3.604-2.159 8.295-.988 10.456 2.612 2.163 3.599.989 8.284-2.614 10.444-1.225.733-2.575 1.083-3.909 1.083z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M218.707 101.436c-.069-.15-.15-.274-.244-.37-.094-.095-.21-.168-.35-.218-.138-.05-.302-.08-.49-.091-.188-.01-.41-.003-.664.02-.44.037-.847.053-1.221.046-.375-.006-.716-.054-1.023-.147-.305-.09-.577-.236-.814-.436-.236-.2-.433-.476-.592-.829-.136-.305-.208-.607-.213-.906-.004-.301.052-.589.17-.866.117-.278.289-.537.518-.78.228-.243.508-.458.842-.647l-.519-1.153.929-.416.526 1.168c.352-.106.693-.153 1.025-.142.33.011.638.08.923.207.285.127.543.312.777.555.232.243.429.544.59.903l-1.677.752c-.194-.43-.435-.71-.723-.837-.288-.128-.592-.12-.913.024-.174.079-.314.17-.417.275-.104.105-.178.218-.222.339-.042.12-.057.248-.045.38.013.132.05.264.11.397.059.133.132.244.22.335.087.09.199.16.334.208.137.05.301.08.495.09.192.01.425.007.695-.012.438-.028.845-.038 1.22-.028.372.01.713.06 1.02.153.307.094.58.24.816.44.236.201.433.474.589.823.142.316.214.624.217.925.003.3-.054.588-.173.861-.119.274-.295.53-.53.769-.235.24-.519.452-.852.641l.481 1.073-.922.414-.48-1.067c-.336.111-.678.182-1.026.208-.348.027-.685-.009-1.008-.108-.323-.099-.624-.27-.902-.517-.28-.246-.514-.585-.708-1.015l1.678-.753c.114.254.245.45.393.59.149.138.306.232.472.28.166.049.335.059.507.032.17-.028.335-.077.495-.148.18-.082.33-.177.447-.284.116-.11.202-.226.258-.35.056-.124.08-.254.077-.387-.005-.134-.037-.267-.096-.4M114.581 95.807s-5.832-4.103-10.203-3.3c-4.37.805-9.102 6.423-9.102 6.423s8.727.964 11.962-.26c3.235-1.223 7.343-2.863 7.343-2.863"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#A1DAD4"
      d="M210.735 87.348s9.95-5.402 13.746-3.612c3.795 1.79 5.807 6.04 5.807 6.04s-4.601.46-8.165.288c-3.566-.172-5.981-3.363-11.388-2.716M226.608 45.906s6.648-3.287 9.632-2.52c2.982.765 5.997 2.52 5.997 2.52s-3.705 2.228-7.309 2.074c-3.604-.153-8.32-2.074-8.32-2.074M183.246 12.062s-3.432-4.25-2.233-7.352C182.21 1.608 183.246 0 183.246 0s3.142 3.561 3.009 6.031c-.134 2.47-3.01 6.031-3.01 6.031M197.33 19.557s-.804-5.35 1.68-6.882c2.486-1.532 6.473-1.532 6.473-1.532s-.614 4.519-2.3 5.514c-1.687.996-5.113 1.541-5.854 2.9M159.028 162.092s3.361 4.749 3.514 6.433c.153 1.685-4.742 5.974-4.742 5.974s-1.584-4.135-.894-6.204c.691-2.068 2.122-6.203 2.122-6.203M152.78 134.967s-4.996 1.684-5.686 4.442c-.69 2.757-.46 4.73-.46 4.73s7.231-3.505 6.146-9.172M154.302 134.967s1.542 1.762 1.576 2.987c.034 1.226-.787 2.292-.787 2.292s-1.82-1.461-.79-5.28"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M217.555 210.493v2.966c0 .236-.072.456-.197.638-.13.192-.317.343-.536.428-.068.027-.138.046-.21.059-.066.012-.132.018-.201.018l-47.31-.006c-.19 0-.37-.047-.528-.13-.335-.175-.574-.511-.611-.905-.003-.016-.003-.033-.004-.051l-.002-.059.002-2.965v-.059c.032-.602.534-1.084 1.144-1.084l47.309.007c.069 0 .136.006.202.018.072.013.141.032.21.058.22.085.406.236.537.429.123.182.195.402.195.638"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M194.323 214.599l-25.344-.003c-.17 0-.33-.047-.472-.13-.298-.175-.513-.511-.545-.905-.003-.016-.003-.033-.004-.051l-.002-.059.002-2.965v-.059c.027-.602.477-1.084 1.02-1.084l22.234.003c.345 1.504 1.137 3.568 2.956 5.124.05.044.102.086.155.129M211.798 214.602L211.26 214.602 211.261 209.348 211.799 209.348 211.798 214.602M209.441 214.602L209.979 214.602 209.979 209.348 209.441 209.348zM207.796 214.602L207.258 214.602 207.258 209.348 207.797 209.348 207.796 214.602M203.912 214.6L204.45 214.6 204.45 209.347 203.912 209.347zM200.375 214.6L200.912 214.6 200.912 209.347 200.375 209.347zM195.488 214.599L194.951 214.599 194.952 209.346 195.49 209.347 195.488 214.599"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M189.873 214.599L190.411 214.599 190.411 209.346 189.873 209.346zM185.876 214.597L185.339 214.597 185.339 209.346 185.877 209.346 185.876 214.597M212.993 214.602L213.531 214.602 213.531 209.348 212.993 209.348zM214.676 214.602L214.138 214.602 214.138 209.348 214.676 209.35 214.676 214.602M215.78 214.603L215.243 214.602 215.244 209.35 215.782 209.35 215.78 214.603M216.613 209.368l-.001 5.216c-.066.012-.132.018-.201.018h-.337l.002-5.254h.335c.069.002.136.008.202.02M217.36 209.855l-.002 4.242c-.13.192-.317.343-.536.428v-5.099c.22.085.407.236.538.429"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M212.38 205.24v2.967c-.002.236-.074.455-.198.638-.13.192-.317.342-.537.427-.068.027-.137.047-.209.059-.066.012-.134.019-.203.019l-47.308-.007c-.61 0-1.112-.483-1.143-1.085-.002-.02-.002-.04-.002-.058v-2.967l.002-.06c.028-.555.461-1.01 1.008-1.075.044-.006.09-.009.135-.009l47.31.008c.069 0 .135.006.201.017.072.014.143.033.21.06.22.084.407.235.536.428.125.183.197.402.197.638"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M189.147 209.346l-25.346-.003c-.543 0-.993-.483-1.02-1.085v-3.025-.06c.026-.555.412-1.01.901-1.075.038-.006.08-.009.12-.009l22.234.003.001.01c.356 1.544 1.183 3.678 3.11 5.244M206.622 209.348L206.084 209.348 206.085 204.095 206.622 204.095 206.622 209.348M204.801 209.348L204.264 209.348 204.265 204.095 204.803 204.095 204.801 209.348M202.082 209.348L202.62 209.348 202.62 204.095 202.082 204.095zM199.273 209.347L198.735 209.347 198.737 204.094 199.274 204.095 199.273 209.347M195.735 209.347L195.197 209.347 195.199 204.094 195.737 204.094 195.735 209.347M190.312 209.347L189.775 209.346 189.775 204.094 190.312 204.094 190.312 209.347"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M185.234 209.346L184.696 209.346 184.697 204.092 185.235 204.092 185.234 209.346M180.7 209.346L180.162 209.346 180.163 204.092 180.701 204.092 180.7 209.346M208.354 209.348L207.816 209.348 207.818 204.095 208.355 204.097 208.354 209.348M209.498 209.35L208.961 209.348 208.962 204.095 209.5 204.097 209.498 209.35M210.603 209.35L210.067 209.348 210.067 204.097 210.605 204.097 210.603 209.35M211.436 204.114v5.217c-.066.012-.134.019-.203.019l-.336-.002.001-5.251h.337c.069 0 .135.006.201.017M212.182 204.602v4.242c-.13.192-.317.343-.537.428l.001-5.099c.22.085.407.236.536.429"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M217.316 199.996v2.965c0 .236-.072.456-.197.638-.13.193-.317.344-.538.429-.066.026-.137.045-.208.059-.023.002-.045.007-.067.008l-.038.005-.037.003-.061.001-47.309-.007c-.61 0-1.112-.483-1.143-1.085l-.001-.058v-2.967l.001-.059.003-.047c.046-.484.398-.883.858-1l.035-.01c.08-.017.163-.027.247-.027l47.31.007c.034 0 .07.003.103.004.015.003.03.003.046.006l.053.01c.071.01.142.031.21.058.219.085.407.236.537.427.124.183.196.402.196.64"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M194.084 204.101l-25.346-.004c-.544 0-.992-.483-1.02-1.085l-.001-.058v-2.967l.001-.059.003-.047c.041-.494.369-.9.793-1.008l.044-.01c.06-.012.12-.02.181-.02l22.233.005.009.037c.358 1.54 1.186 3.653 3.092 5.207l.01.01M211.558 204.104L211.02 204.104 211.022 198.851 211.56 198.851 211.558 204.104M209.738 204.103L209.2 204.103 209.202 198.85 209.739 198.851 209.738 204.103M207.018 204.103L207.556 204.103 207.556 198.85 207.018 198.85zM204.209 204.103L203.673 204.103 203.673 198.85 204.211 198.85 204.209 204.103M200.672 204.103L200.135 204.101 200.135 198.848 200.673 198.85 200.672 204.103M195.249 204.101L194.711 204.101 194.713 198.848 195.25 198.848 195.249 204.101"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M190.17 204.1L189.634 204.1 189.634 198.848 190.171 198.848 190.17 204.1M185.636 204.1L185.098 204.1 185.1 198.847 185.638 198.847 185.636 204.1M213.292 204.104L212.752 204.104 212.754 198.851 213.292 198.851 213.292 204.104M214.436 204.104L213.897 204.104 213.898 198.851 214.436 198.851 214.436 204.104M215.003 204.104L215.541 204.104 215.541 198.851 215.003 198.851zM216.373 198.87v5.215c-.067.013-.134.02-.203.02h-.335v-5.254h.336c.07 0 .137.006.202.02M217.119 199.356v4.243c-.13.193-.317.344-.538.429l.002-5.1c.219.084.407.236.536.428M50.037 210.803L70.301 210.803 70.301 209.087 50.037 209.087z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M168.745 211.695L186.03 211.695 186.03 203.957 168.745 203.957z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M188.648 208.905L189.781 209.944 189.623 211.45 182.997 211.258 182.366 208.456 188.648 208.905"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#2C3A64"
      d="M79.279 73.642c-.013-.345-.28-4.066-5.954-4.26 0 0 2.09.311 3.1 1.431 0 0-1.728-1.387-3.275-.9-1.547.486-2.456 3.194-2.465 4.084-.01.89.85 3.76 1.832 4.005.372.092.8.068 1.19.001-.262.408-.518.857-.763 1.359-2.264 4.642.071 9.178 1.988 8.677 1.23-.321 1.577 1.773 1.577 1.773l11.943-9.656s-4.387-9.065-9.173-6.514"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#CDE0FB"
      d="M178.367 214.643l-37.17-.047h27.782l9.377.001c.005.016.008.03.01.046m-15.508-17.437c-3.934-3.918-8.781-7.837-14.41-10.673l-.029-.015c-.827-.415-1.626-.792-2.403-1.12-.365-.157-.732-.308-1.102-.455 2.384-10.328 6.23-17.358 10.522-23.109.836 1.057 1.68 2.175 2.525 3.356-.377 1.1-.777 2.271-1.055 3.105-.69 2.069.894 6.204.894 6.204s2.37-2.076 3.76-3.906c3.838 6.196 7.611 13.774 10.908 23.008-2.603.342-5.292.984-8.056 1.978l-1.554 1.627m-17.81-41.787l-.018-.15c-.065-.524-.31-2.36-.835-4.958.89.72 1.834 1.526 2.823 2.428-.67.884-1.326 1.774-1.97 2.68"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#ACCFCD"
      d="M162.858 197.206c-3.934-3.918-8.781-7.837-14.41-10.673l-.029-.015.03.015c5.628 2.836 10.475 6.755 14.41 10.673m-16.843-11.808c-.374-.157-.742-.303-1.107-.437l.005-.018c.37.147.737.298 1.102.455"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#99C0BA"
      d="M161.364 198.772c-2.658-.586-5.238-.853-7.73-.853-1.47 0-2.911.094-4.32.269.273-1.343.063-2.854-.652-4.452-.897-2.003-2.717-3.092-4.799-3.437.177-1.075.366-2.12.57-3.133 5.138 2.025 10.138 5.23 14.864 9.527.013.013.875.783 2.13 2.013l-.063.066m.611-.641c-1.178-1.202-2-1.978-2.107-2.072-4.824-4.388-9.939-7.658-15.203-9.72l-.055-.022c.097-.458.196-.91.3-1.356.364.134.732.28 1.106.437.777.328 1.576.705 2.403 1.12l.03.015c5.628 2.836 10.475 6.755 14.41 10.673l-.884.925"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#99C0BA"
      d="M161.427 198.706c-1.255-1.23-2.117-2-2.13-2.013-4.726-4.297-9.726-7.502-14.864-9.527.059-.287.116-.567.177-.85l.055.023c5.264 2.062 10.379 5.332 15.203 9.72.108.094.929.87 2.107 2.072l-.548.575M142.929 199.579c.125-3.711.54-6.652.54-6.652.122-.896.253-1.772.394-2.628 2.082.345 3.902 1.434 4.799 3.437.715 1.598.925 3.11.652 4.452-2.207.274-4.336.751-6.385 1.39"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#7EA9EA"
      d="M140.991 200.24c.56-7.718.962-17.668.383-27.612 2.876-6.407 6.418-11.183 10.066-15.482 1.298 1.41 2.637 2.968 3.996 4.688-4.292 5.75-8.138 12.78-10.522 23.11l-.005.017c-.103.446-.202.898-.3 1.356-.06.282-.117.562-.176.85-.204 1.012-.393 2.057-.57 3.132-.14.856-.272 1.732-.394 2.628 0 0-.415 2.94-.54 6.652-.655.204-1.3.424-1.938.66m4.056-44.82c.645-.906 1.3-1.796 1.97-2.68-.668.882-1.325 1.776-1.97 2.68"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#8CB6F6"
      d="M141.374 172.628c-.533-9.171-1.902-18.338-4.754-25.732l-.276-1.75s.767.333 2.104 1.122c1.241 4.77 2.56 10.539 2.355 12.837-.078.888.065 1.238.338 1.238.915 0 3.298-3.903 3.81-4.757.063-.104.096-.164.096-.164v-.003c.645-.904 1.302-1.798 1.97-2.68 1.398 1.274 2.883 2.737 4.423 4.407-3.648 4.3-7.19 9.075-10.066 15.482m3.656-17.359c-.066-.525-.31-2.36-.835-4.958.525 2.599.77 4.434.835 4.958"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#7EA9EA"
      d="M141.14 160.343c-.272 0-.415-.35-.337-1.238.206-2.298-1.114-8.066-2.355-12.837 1.38.816 3.367 2.118 5.747 4.043.525 2.597.769 4.433.835 4.958l.017.15v.003s-.033.06-.095.164c-.513.854-2.896 4.757-3.811 4.757"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#ACCFCD"
      d="M166.674 214.596h-15.607c1.51-.294 3.273-.555 5.249-.716.956-.078 1.895-.116 2.82-.116 2.661 0 5.185.308 7.538.832m-15.976-4.652c-.613-1.32-1.797-2.414-3.54-3.193-1.388-.619-2.94-.956-4.44-.956-.759 0-1.504.086-2.209.265.097-1.04.196-2.158.291-3.344 4.081-1.588 8.258-2.44 12.417-2.534.258-.006.512-.009.763-.009 2.118 0 4.003.201 5.592.477l-8.874 9.294m-9.823-8.171c.04-.5.078-1.013.116-1.534.638-.236 1.283-.456 1.938-.66 2.05-.64 4.178-1.117 6.385-1.391 1.409-.175 2.85-.269 4.32-.269 2.492 0 5.072.267 7.73.853l-1.078 1.13c-1.734-.33-3.853-.583-6.288-.583-.247 0-.499.003-.753.007-4.143.088-8.302.912-12.37 2.447"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#99C0BA"
      d="M140.8 202.716l.075-.943c4.068-1.535 8.227-2.359 12.37-2.447.254-.004.506-.007.753-.007 2.435 0 4.554.252 6.288.583l-.714.748c-1.589-.276-3.474-.477-5.592-.477-.251 0-.505.003-.763.009-4.159.094-8.336.946-12.417 2.534M146.258 214.596h-5.061l-1.531-.003c-.056-.184.372-3.424.843-8.533.705-.179 1.45-.265 2.208-.265 1.502 0 3.053.337 4.44.956 1.744.78 2.928 1.873 3.54 3.193l-4.439 4.652M168.965 214.596h-2.291c-2.353-.524-4.877-.832-7.539-.832-.924 0-1.863.038-2.82.116-1.975.161-3.738.422-5.248.716h-3.756c2.045-.496 6.131-1.299 11.15-1.299 3.082 0 6.516.304 10.043 1.167l.003.001c.138.08.294.128.458.13"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#ACCFCD"
      d="M147.311 214.596h-1.053l13.314-13.946 1.792-1.878.063-.066.548-.575 2.437-2.552c2.764-.994 5.453-1.636 8.056-1.978.154.428.308.867.458 1.303-4.69.52-7.538 1.844-7.69 1.916l-.07.032-15.054 15.46 1.287-.214c3.313-.554 6.492-.83 9.523-.83 2.443 0 4.79.179 7.034.538v1.645l.002.059c.001.018.001.035.004.051.032.392.244.727.542.903-3.527-.863-6.96-1.167-10.043-1.167-5.019 0-9.105.803-11.15 1.299m20.647-3.655c-2.25-.351-4.597-.527-7.036-.527-1.957 0-3.971.113-6.043.339-.032.004-.066.006-.098.006-.714 0-1.111-.876-.59-1.41l10.434-10.715c.766-.787 1.714-1.37 2.761-1.702 1.316-.417 3.312-.926 5.827-1.195.345 1.017.684 2.053 1.018 3.108l-5.492-.001c-.062 0-.122.007-.18.019l-.045.01c-.424.107-.752.514-.793 1.008l-.003.047-.001.059v2.967l.001.058c.025.56.416 1.017.907 1.079l-4.7-.002h-.127c-.04 0-.079.003-.116.01-.489.064-.875.519-.9 1.075l-.002.059v2.967c0 .019 0 .038.002.058.026.602.476 1.085 1.02 1.085h4.943v.029c-.433.116-.762.541-.787 1.055v.514"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#99C0BA"
      d="M150.112 212.313l15.054-15.46.07-.033c.152-.072 3-1.397 7.69-1.916.097.277.191.555.287.833-2.515.269-4.51.778-5.827 1.195-1.047.331-1.995.915-2.76 1.702l-10.435 10.714c-.521.535-.124 1.41.59 1.41.032 0 .066 0 .098-.005 2.072-.226 4.086-.34 6.043-.34 2.439 0 4.786.177 7.036.528l-.002.865c-2.243-.36-4.591-.538-7.034-.538-3.031 0-6.21.276-9.523.83l-1.287.215M157.8 174.499s-1.584-4.135-.894-6.204c.278-.834.678-2.005 1.055-3.105 1.196 1.67 2.402 3.47 3.598 5.403-1.39 1.83-3.76 3.906-3.76 3.906"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#D7B987"
      d="M178.356 214.597l-9.377-.001h-.014c-.164-.003-.32-.05-.458-.13-.001 0-.001-.002-.003-.002-.298-.176-.51-.511-.542-.903-.003-.016-.003-.033-.004-.051l-.002-.059.002-2.965v-.059c.025-.514.354-.94.787-1.055v2.323h8.97c.218.952.434 1.923.641 2.902"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#D7C39D"
      d="M168.626 204.091l-4.706-.002h.005l4.7.002h.001"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#D7B987"
      d="M168.745 209.343h-4.944c-.543 0-.993-.483-1.02-1.085v-3.025-.06c.026-.555.412-1.01.901-1.075.037-.006.077-.009.116-.009H163.92l4.706.002c.037.004.073.006.112.006h.007v5.246"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#D7B987"
      d="M168.745 204.097h-.007c-.039 0-.075-.002-.112-.006h-.001c-.491-.062-.882-.52-.907-1.079l-.001-.058v-2.967l.001-.059.003-.047c.041-.494.369-.9.793-1.008l.044-.01c.06-.012.12-.02.181-.02l5.492.002c.523 1.658 1.028 3.356 1.519 5.112h-7.005v.14"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#D7B987"
      d="M177.714 211.695h-8.969v-7.738h7.005c.692 2.479 1.349 5.06 1.964 7.738"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#001351"
      d="M83.437 210.39l-2.537.944S69.882 196.07 68.525 182.64c-.242-2.396-.161-4.719.363-6.864l-.296-1.7-.317-1.817c-.358-2.05-.851-4.854-1.415-8.049-1.296-7.348-2.976-16.744-4.28-23.65l.683-.22 7.396-2.382 1.528-4.848 5.507-1.449s1.23 2.404 2.33 13.757c.203 1.49.387 3.133.526 4.912.429 5.437.46 12.181-.524 19.889-.253 1.974-.572 4.012-.967 6.104-.29 1.535-.334 3.626-.212 6.028.556 10.977 4.59 28.038 4.59 28.038"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#233862"
      d="M80.55 150.331l-11.958 23.745-.317-1.816c-.358-2.05-.851-4.854-1.415-8.049-1.296-7.348-2.976-16.744-4.28-23.65l.683-.22 7.396-2.382 1.528-4.848 5.507-1.449s1.23 2.404 2.33 13.757c.203 1.49.387 3.133.526 4.912"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#001351"
      d="M75.29 134.709l-.278-2.37c-4.638 1.23-17.03-3.438-17.03-3.438s-4.492 3.944-3.902 13.4c.288 4.614.814 24.224 1.363 30.028.198 2.104.372 3.6.444 4.198v.006c.022.182-.041.355-.134.513-.809 1.37-1.478 2.798-2.027 4.258-5.392 14.351.83 32.022.83 32.022l1.614-.65s3.541-19.667 6.418-28.355c.488-1.473.957-2.63 1.386-3.323 1.25-2.016 2.411-5.31 3.465-9.228 1.991-7.41 3.594-17.047 4.661-24.486.147.007 3.19-12.575 3.19-12.575"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB27D"
      d="M81.649 105.375c.326-1.189.864-2.13 1.42-2.838.077-.116.154-.23.27-.326.92-1.093 1.823-1.554 1.823-1.554.46-.192.96.04 1.479.594v.02c.421.441.883 1.112 1.323 1.955.02.02.039.039.039.058.096.173.192.365.308.575.038.04.057.097.076.135.212.44.422.9.634 1.38 2.36 5.14 4.876 12.924 6.968 15.244.902.996 5.03 2.952 8.312 4.409 2.361 1.036 4.282 1.802 4.282 1.802s2.366-.46 2.907 0c.54.462 3.107 3.065 2.558 3.92-.55.855-3.184-.707-3.732-.855-.548-.147-2.079-.745-2.079-.745s-.154 0-.423-.02c-.845-.057-2.898-.171-5.221-.42-3.494-.345-7.64-.96-9.291-1.976-2.515-1.533-6.642-6.231-9.33-10.928-.077-.115-.135-.23-.192-.345-1.402-2.493-2.38-4.967-2.457-6.94-.058-1.21.076-2.244.326-3.145"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB27D"
      d="M84.15 105.951c-1.457.674-3.215 1.133-5.342 1.25-7.974.436-9.784-6.545-10.113-11.179 1.15-.416 2.156-.376 2.736-.345.565.03 1.156.089 1.642.149.976-.51 2.728-2.98 3.74-7.116l.344.16 5.754 2.7s-.611 1.87-1.063 3.66c-.352 1.4-.605 2.754-.393 3.132.453.184.725.3.725.3l.002.005c.058.147 1.309 3.331 1.967 7.284"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#ED985F"
      d="M82.911 91.572s-.612 1.868-1.063 3.66c-3.362-.938-4.384-4.469-4.69-6.362l5.753 2.702"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB27D"
      d="M83.619 93.55s-8.046-1.86-6.809-6.846c1.237-4.987 1.031-8.585 6.22-7.608 5.19.976 5.712 3.125 5.704 4.89-.007 1.764-2.99 9.92-5.115 9.564"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#2C3A64"
      d="M89.187 81.155c-.864-1.808-1.882-1.712-2.52-1.391-3.78-3.493-10.116-2.618-10.116-2.618-1.427 3.444-1.893 6.83-1.893 6.83s1.236 1.263 4.819 1.236c1.094-.009 2.194-.51 3.195-1.202-1.93 1.942-4.195 3.125-4.195 3.125 4.485-.857 7.492-6.043 7.8-6.593.38.56 1.107 1.753 1.167 2.76.084 1.369-.388 4.027.397 4.127 0 0 2.643-3.564 1.346-6.274"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#97AEFF"
      d="M89.04 105.366c-.222-.668-1.42-2.819-1.962-3.776-.196-.346-.467-.642-.794-.868-.647-.443-1.93-1.208-4.104-2.06l.002.005c.054.2 1.505 5.452.975 6.573-.538 1.138-10.73-2.54-11.287-3.381-.445-.674-.458-4.238-.445-5.626.002-.345.006-.556.006-.556s-3.805-.515-5.944.35c0 0 .845 1.832 1.156 3.852.029.193.054.385.072.579.042.406.054.814.031 1.212-.03.525-.302.99-.735 1.445-.393.418-.928.829-1.532 1.27-.396.285-.82.586-1.26.91-.037 2.38.13 6.292-.31 10.469-.112 1.059-.464 2.073-1.02 2.97-.219.358-.471.698-.753 1.013-2.247 2.518-6.232 7.535-5.356 10.66.065 2.944 24.94 6.815 24.407 3.58 0 0 3.738-17.993 5.757-22.338.074-.158.14-.314.2-.474.646-1.714.538-3.476.319-5.055 1.14-.808 2.577-.754 2.577-.754"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#6C7FF9"
      d="M73.49 127.941c-4.907-.051-9.061-5.14-11.6-9.208.555-.896.907-1.91 1.019-2.97.44-4.176.273-8.088.31-10.468.44-.324.864-.625 1.26-.91.604-.441 1.139-.852 1.532-1.27 4.018 6.782 14.13 24.897 7.479 24.826"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#233862"
      d="M63.943 214.643H51.166s-.306-1.945.362-3.42c.639-1.415 1.64-2.06 1.4-3.772.813.835 2.136 1.77 3.983 1.522.226.365 1.46 2.327 2.217 2.87.835.599 4.558.384 4.815 2.8M91.376 214.643H78.599s-.478-2.036.361-3.42c.743-1.226.458-1.923-.092-2.937.579.236 1.328.452 2.152.405.637-.037 1.31-.326 1.9-.668.55.626 2.905 3.292 3.64 3.82.836.599 4.559.384 4.816 2.8"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#C94C43"
      d="M109.233 135.98l3.453 1.417c.939.383 1.432 1.403 1.192 2.36l.37.15c1.404-4.873-.31-9.788-4.165-11.367-.541-.222-1.098-.367-1.66-.437-1.775-.235-3.625.243-5.288 1.28-1.58.98-2.988 2.469-4.004 4.337l.509.208c.5-.85 1.567-1.231 2.505-.848l3.455 1.416c.27-.659.913-1.058 1.585-1.058.212 0 .43.04.64.126l.477.197c.868.355 1.287 1.354.931 2.22zm-16.438 5.996l3.035-7.397c.397-.964 1.458-1.465 2.44-1.194 1.007-1.888 2.392-3.423 3.961-4.5 1.656-1.139 3.513-1.766 5.347-1.758.968.004 1.933.188 2.853.565 4.318 1.766 6.253 7.215 4.667 12.563l.237.097s.737-.051 1.976-.051c3.91 0 12.798.52 19.034 4.845 0 0 .391.417.407 1.052.008.211-.027.447-.132.698 0 0-10.01-6.474-19.268.18-.568.406-1.12.783-1.657 1.134l-.83 2.023c-1.377 3.357-5.252 4.976-8.612 3.601l-8.48-3.47-1.766-.724c-2.995-1.225-4.44-4.673-3.212-7.664z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#B23B38"
      d="M115.695 148.21c.538-.351 1.089-.728 1.657-1.135 9.259-6.653 19.268-.179 19.268-.179.105-.25.14-.487.132-.698-1.401-.922-6.653-3.987-13.16-2.94-7.625 1.229-9.005 2.288-7.897 4.952M96.007 149.64l1.765.724c-2.648-1.446.632-7.548 2.551-11.576 1.919-4.03 4.822-2.188 4.822-2.188.664-1.35 2.089-1.277 2.089-1.277.075-1.301 1.068-1.562 1.068-1.562l-.477-.197c-.21-.085-.428-.126-.64-.126-.672 0-1.314.399-1.585 1.058l-3.455-1.416c-.938-.383-2.004-.002-2.505.848l-.509-.208c1.016-1.868 2.425-3.356 4.004-4.338 1.663-1.036 3.513-1.514 5.287-1.28-.277-.224-.575-.537-.844-.975-1.834-.008-3.69.619-5.347 1.758-1.569 1.077-2.954 2.612-3.96 4.5-.983-.271-2.044.23-2.44 1.194l-3.036 7.397c-1.228 2.99.217 6.44 3.212 7.664"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB27D"
      d="M62.682 102.345c.039-5.56 2.803-6.327 2.803-6.327.057-.02.115-.04.192-.058.48-.076.979.25 1.497.882.653.787 1.344 2.07 2.054 3.623 1.094 2.474 2.246 5.676 3.321 8.86.596 1.744 1.171 3.49 1.747 5.099.327.979.653 1.899.96 2.742.595 1.61 1.133 2.915 1.613 3.74.307.536 1.19 1.283 2.361 2.127 2.035 1.476 5.01 3.26 7.602 4.716 1.075.615 2.093 1.17 2.938 1.63.71.154 4.32.92 6.007.537 1.901-.44 5.088 1.842 5.088 1.842s.345.728-.883 1.226c-1.228.518-2.956 2.32-5.72 1.227-1.978-.767-4.07-1.303-5.089-1.552-.402-.078-.633-.135-.633-.135-1.074-.21-2.36-.498-3.762-.806h-.02c-2.399-.575-5.086-1.265-7.352-2.032-1.593-.556-2.975-1.15-3.916-1.745-.096-.057-.173-.114-.269-.192-2.246-1.629-4.914-6.423-6.987-11.561-.289-.71-.557-1.42-.826-2.109-.96-2.57-1.728-5.1-2.188-7.285-.289-1.305-.48-2.493-.519-3.47-.019-.347-.038-.673-.019-.98"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#699DEE"
      d="M69.848 100.733c-3.696-1.016-7.173 1.606-7.173 1.606.046-5.552 2.813-6.312 2.813-6.312 1.385-.796 3.03 1.725 4.36 4.706"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M152.77 210.486v2.965c0 .238-.071.457-.195.639-.13.192-.318.343-.537.428-.068.026-.138.045-.21.059-.067.011-.133.017-.202.017l-47.31-.006c-.61 0-1.112-.484-1.143-1.084l-.001-.06.001-2.966v-.058c.03-.556.461-1.011 1.01-1.077.044-.005.09-.008.135-.008l47.308.008c.07-.002.137.005.203.017.072.013.143.033.209.059.22.085.407.236.537.428.124.182.196.402.196.639"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M129.539 214.591l-25.345-.003c-.545 0-.993-.484-1.02-1.084l-.002-.06.001-2.966v-.058c.027-.556.413-1.011.9-1.077.04-.005.08-.008.121-.008l22.233.003.003.009c.356 1.545 1.183 3.679 3.109 5.244M146.477 214.594L147.015 214.594 147.015 209.341 146.477 209.341zM144.657 214.594L145.194 214.594 145.194 209.341 144.657 209.341zM143.011 214.594L142.473 214.594 142.475 209.341 143.013 209.341 143.011 214.594M139.666 214.593L139.128 214.593 139.13 209.34 139.667 209.34 139.666 214.593M136.128 214.593L135.59 214.593 135.592 209.34 136.13 209.34 136.128 214.593M130.704 214.591L130.168 214.591 130.168 209.338 130.705 209.34 130.704 214.591"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M125.626 214.591L125.089 214.591 125.09 209.338 125.628 209.338 125.626 214.591M121.093 214.591L120.555 214.59 120.555 209.337 121.093 209.338 121.093 214.591M148.747 214.594L148.209 214.594 148.21 209.341 148.748 209.341 148.747 214.594M149.891 214.594L149.353 214.594 149.355 209.341 149.893 209.341 149.891 214.594M150.996 214.594L150.458 214.596 150.46 209.341 150.997 209.343 150.996 214.594M151.829 209.36l-.001 5.217c-.065.011-.133.017-.202.017h-.336l.001-5.253.337.002c.067-.002.135.005.201.017M152.575 209.847v4.243c-.13.192-.318.343-.537.428v-5.1c.22.086.407.237.537.43"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M144.457 205.24v2.967c0 .236-.072.455-.196.637-.13.192-.318.343-.537.428-.068.027-.138.046-.21.059l-.067.01c-.017.003-.035.005-.052.005l-.027.002-.056.002-47.31-.007c-.61 0-1.112-.483-1.143-1.085l-.001-.058.001-2.967c0-.02 0-.04.002-.06.03-.6.533-1.084 1.143-1.084l47.31.008.06.001c.048.003.094.01.141.016.072.014.143.033.209.06.035.012.067.027.1.043.02.01.04.02.058.032l.006.005c.02.01.04.023.059.035l.009.006.051.038c.041.03.078.063.113.098l.033.034.006.007.02.021.003.004c.012.014.024.028.034.043.016.019.03.04.045.062.124.182.197.402.196.638"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M121.225 209.346l-25.345-.003c-.543 0-.993-.483-1.02-1.085 0-.02 0-.04-.002-.058l.001-2.967c0-.02 0-.04.002-.06.028-.6.476-1.084 1.02-1.084l22.232.003.03.122c.368 1.532 1.2 3.597 3.073 5.124l.009.008M138.163 209.348L138.701 209.348 138.701 204.095 138.163 204.095zM136.88 209.348L136.343 209.348 136.344 204.095 136.88 204.095 136.88 209.348M134.697 209.348L134.159 209.348 134.161 204.095 134.699 204.095 134.697 209.348M131.352 209.347L130.814 209.347 130.816 204.095 131.353 204.094 131.352 209.347M127.814 209.347L127.276 209.347 127.278 204.094 127.816 204.094 127.814 209.347M122.391 209.347L121.854 209.346 121.854 204.094 122.391 204.094 122.391 209.347"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M117.312 209.346L116.775 209.346 116.776 204.092 117.314 204.092 117.312 209.346M112.779 209.346L112.241 209.344 112.241 204.092 112.779 204.092 112.779 209.346M140.433 209.348L139.895 209.348 139.896 204.097 140.434 204.095 140.433 209.348M141.577 209.348L141.039 209.348 141.041 204.097 141.579 204.097 141.577 209.348M142.682 209.35L142.144 209.35 142.146 204.097 142.683 204.097 142.682 209.35M143.515 204.114l-.001 5.217c-.065.012-.133.019-.202.019h-.336l.001-5.253h.337c.067 0 .135.006.201.017M144.261 204.602v4.242c-.13.192-.318.343-.537.428l.001-5.099c.219.085.406.236.536.429"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M150.876 200.109l-.002 2.967c0 .236-.072.454-.195.638-.131.192-.32.343-.538.427-.068.026-.137.047-.21.059-.065.011-.132.019-.201.019l-47.31-.008c-.183 0-.358-.044-.512-.122-.344-.171-.59-.515-.627-.915l-.005-.047V200.1c0-.019 0-.04.002-.058.028-.557.46-1.011 1.008-1.076.044-.006.09-.009.135-.009l47.31.007c.068 0 .135.006.201.018.072.013.143.032.209.06.22.084.407.235.538.427.123.184.197.402.197.639"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M127.644 204.216l-25.346-.005c-.165 0-.32-.044-.457-.122-.307-.171-.528-.515-.56-.915l-.005-.047V200.1c0-.019 0-.04.002-.058.025-.557.411-1.011.9-1.076.039-.006.08-.009.121-.009l22.231.003.003.009c.347 1.505 1.142 3.571 2.964 5.124l.147.122M144.58 204.217L145.118 204.217 145.118 198.964 144.58 198.964zM142.76 204.217L143.298 204.217 143.298 198.964 142.76 198.964zM141.114 204.217L140.577 204.217 140.578 198.964 141.116 198.964 141.114 204.217M137.769 204.216L137.231 204.217 137.233 198.964 137.771 198.964 137.769 204.216M134.231 204.216L133.694 204.216 133.695 198.963 134.233 198.963 134.231 204.216M128.807 204.216L128.271 204.216 128.271 198.963 128.809 198.963 128.807 204.216"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M123.73 204.214L123.192 204.214 123.194 198.961 123.731 198.961 123.73 204.214M118.658 204.214L119.196 204.214 119.196 198.961 118.658 198.961zM146.85 204.217L146.312 204.217 146.314 198.965 146.852 198.965 146.85 204.217M147.995 204.217L147.457 204.219 147.458 198.965 147.996 198.965 147.995 204.217M149.099 204.219L148.562 204.219 148.563 198.965 149.101 198.965 149.099 204.219M149.932 198.983l-.001 5.217c-.065.011-.132.019-.201.019h-.337l.002-5.254h.336c.068 0 .135.006.201.018M150.679 199.47v4.244c-.131.192-.32.342-.538.427v-5.1c.22.086.407.237.538.43"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFB93E"
      d="M147.398 194.863l-.001 2.967c0 .236-.072.456-.196.638-.13.192-.319.343-.538.429-.067.026-.136.045-.21.058-.022.003-.044.008-.066.009l-.036.004-.039.003c-.019.002-.04.002-.06.002l-47.309-.008c-.61 0-1.113-.482-1.144-1.084V194.855l.001-.058c.031-.602.534-1.085 1.143-1.085l47.31.008c.07 0 .136.006.202.019.072.012.142.032.21.058.219.086.405.237.536.427.125.184.197.403.197.639"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M124.166 198.97l-25.346-.005c-.544 0-.993-.482-1.021-1.084V194.855l.001-.058c.028-.602.476-1.085 1.021-1.085l22.232.005c.354 1.543 1.18 3.677 3.103 5.244l.01.009M141.103 198.973L141.64 198.973 141.64 193.72 141.103 193.72zM139.82 198.971L139.282 198.971 139.284 193.72 139.82 193.72 139.82 198.971M137.637 198.971L137.099 198.971 137.101 193.718 137.638 193.718 137.637 198.971M134.292 198.971L133.754 198.971 133.755 193.718 134.293 193.718 134.292 198.971M130.754 198.971L130.216 198.971 130.218 193.718 130.755 193.718 130.754 198.971M124.793 198.97L125.331 198.97 125.331 193.717 124.793 193.717z"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M120.252 198.97L119.715 198.968 119.716 193.717 120.254 193.717 120.252 198.97M115.181 198.968L115.718 198.968 115.718 193.715 115.181 193.715zM143.373 198.973L142.835 198.973 142.836 193.72 143.374 193.72 143.373 198.973M144.517 198.973L143.979 198.973 143.981 193.72 144.518 193.72 144.517 198.973M145.622 198.973L145.086 198.973 145.086 193.72 145.623 193.72 145.622 198.973M146.455 193.739l-.002 5.216c-.064.012-.132.018-.2.018h-.337l.001-5.253h.337c.069 0 .135.006.2.019M147.201 194.224v4.244c-.13.192-.317.343-.538.429l.002-5.1c.219.086.405.235.536.427"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFA412"
      d="M118.28 203.616l3.759-1.13-.63-3.513s-2.826-2.288-3.294-5.256l-18.663 4.647v10.635h4.733v4.228h17.937v-3.877s-2.554-.508-3.843-5.734"
      transform="translate(-833 -344) translate(833 344)"
    />
    <path
      fill="#FFF"
      d="M142.415 150.364s18.19 14.549 24.582 35.678c0 0-4.356-16.186-24.582-35.678M149.622 167.26s9.068 24.065 9.255 41.196c0 0 2.61-11.702-9.255-41.196M143.637 165.915s4.309 17.12 4.653 23.87c0 0 .244-9.197-4.653-23.87M82.708 90.25s1.764.099 2.66.64c0 0-1.307.99-2.66-.64"
      transform="translate(-833 -344) translate(833 344)"
    />
  </svg>
);

export default MoneyTree;
