import React from 'react';

const EyeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="44"
    viewBox="0 0 47 44"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-370 -464) translate(338 377) translate(32 87)">
            <circle cx="24.6" cy="29.4" r="13.8" fill="#DCDCDC" />
            <g fill="#4A4A4A">
              <path
                d="M22.938 0C6.569 0 .513 11.691.257 12.22L0 12.696l.204.475C.461 13.7 6.517 25.92 22.886 25.92c16.368 0 22.68-12.22 22.937-12.75l.257-.474-.257-.476C45.62 11.691 39.31 0 22.937 0zM2.36 12.695c1.026-1.64 4.875-7.195 12.881-9.521-2.722 2.326-4.466 5.872-4.466 9.786 0 3.913 1.744 7.405 4.414 9.732C7.184 20.26 3.335 14.44 2.361 12.695zm20.73 10.844c-5.644 0-10.262-4.76-10.262-10.58 0-5.818 4.618-10.579 10.263-10.579 5.644 0 10.263 4.761 10.263 10.58 0 5.818-4.619 10.58-10.263 10.58zm8.057-.952c2.618-2.327 4.26-5.765 4.26-9.627 0-3.862-1.694-7.352-4.362-9.68 7.851 2.38 11.75 7.775 12.776 9.467-1.026 1.694-4.875 7.3-12.674 9.84z"
                transform="translate(0 15.6)"
              />
              <path
                d="M23.04 5.76c-3.721 0-6.72 3.213-6.72 7.2s2.999 7.2 6.72 7.2c3.721 0 6.72-3.213 6.72-7.2s-2.999-7.2-6.72-7.2zm0 12.185c-2.585 0-4.652-2.216-4.652-4.985 0-2.77 2.067-4.985 4.652-4.985s4.652 2.216 4.652 4.985c0 2.77-2.067 4.985-4.652 4.985z"
                transform="translate(0 15.6)"
              />
            </g>
            <path
              fill="#4A4A4A"
              d="M6.56 6.69c-.364.364-.364.954 0 1.318l4.552 4.555c.182.182.42.272.66.272.237 0 .478-.09.66-.272.363-.364.363-.954 0-1.318L7.878 6.69c-.366-.364-.955-.364-1.32 0zM22.33.96v6.637c0 .53.48.96 1.07.96.59 0 1.07-.43 1.07-.96V.96c0-.53-.48-.96-1.07-.96-.59 0-1.07.43-1.07.96zM40.24 6.69c-.364-.364-.954-.364-1.318 0l-4.553 4.555c-.364.364-.364.954 0 1.318.182.182.42.272.66.272.237 0 .477-.09.66-.272l4.553-4.555c.362-.364.362-.954-.002-1.318z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EyeIcon;
