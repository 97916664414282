import React from 'react';

const ChevronIcon = () => (
  <svg
    width="10px"
    height="6px"
    viewBox="0 0 10 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1594.000000, -380.000000)"
        fill="#285AFF"
        fillRule="nonzero"
      >
        <g transform="translate(1200.000000, 357.000000)">
          <g transform="translate(197.000000, 10.000000)">
            <path d="M202,19 C202.113475,19 202.22695,18.9375813 202.312057,18.8439532 L202.312057,18.8439532 L206.851064,13.8504551 C207.049645,13.663199 207.049645,13.3511053 206.851064,13.1638492 C206.680851,12.9453836 206.397163,12.9453836 206.22695,13.1638492 L206.22695,13.1638492 L202,17.7828349 L197.77305,13.1638492 C197.602837,12.9453836 197.319149,12.9453836 197.148936,13.1638492 C196.950355,13.3511053 196.950355,13.663199 197.148936,13.8504551 L197.148936,13.8504551 L201.687943,18.8439532 C201.77305,18.9375813 201.886525,19 202,19 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChevronIcon;
