import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 100%;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 15px;
  font-family: 'Circular';
  font-size: 16px;
  font-weight: normal;
  color: #232323;
  ::placeholder {
    color: rgb(187, 187, 187);
  }
`;

export const StyledLabel = styled.h3`
  color: '#555555';
  margin-bottom: 10px;
  font-weight: 600;
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 200px;
  border-radius: 15px;
  border: 1px solid #ededed;
  padding: 16px;
  font-family: 'Circular';
  font-size: 16px;
  font-weight: normal;
  color: #232323;
  ::placeholder {
    color: rgb(187, 187, 187);
  }
`;

export const StyledFormContainer = styled.div`
  margin-bottom: 10px;
`;

export const StyledError = styled.p`
  color: #ef5151;
  margin-top: 10px;
`;
