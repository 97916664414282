import React from 'react';
import PropTypes from 'prop-types';

const MailIcon = ({ fill }) => (
  <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 36a18 18 0 1 0 0-36 18 18 0 0 0 0 36zM9.238 12.932a2.728 2.728 0 0 1 2.624-1.682h12.24a2.767 2.767 0 0 1 2.632 1.613c.093.13.117.295.065.444a.482.482 0 0 1-.325.311l-8.114 4.238a.677.677 0 0 1-.674 0l-8.222-4.273a.43.43 0 0 1-.226-.651zm.535 2.704c2.557 1.336 5.11 2.668 7.662 3.995.35.207.784.207 1.133 0 2.54-1.331 5.082-2.652 7.629-3.96a.854.854 0 0 1 .237-.09.43.43 0 0 1 .566.446v5.087c.02.44.005.88-.047 1.317a2.745 2.745 0 0 1-2.75 2.32H11.855a2.779 2.779 0 0 1-2.751-2.07A2.9 2.9 0 0 1 9 21.96v-5.879c0-.493.316-.68.773-.447v.001z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

MailIcon.propTypes = {
  fill: PropTypes.string,
};

MailIcon.defaultProps = {
  fill: '#2F393E',
};

export default MailIcon;
