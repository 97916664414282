import React from 'react';
import styled from 'styled-components';
import Switch from 'ls-common-client/src/components/Switch';
import PropTypes from 'prop-types';

const StyledInfoContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eaf8f7;
  color: #40a499;
  font-weight: 600;
  border: 2px dashed #40a499;
  border-radius: 5px;
  margin-top: 10px;
  p {
    margin-right: 10px;
  }
`;

const ToggleSection = ({ title, checked, onChange }) => {
  return (
    <StyledInfoContainer>
      <p>{title}</p>
      <Switch
        backgroundColor="#40a499"
        {...{
          checked,
          onChange,
        }}
      />
    </StyledInfoContainer>
  );
};

ToggleSection.propTypes = {
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSection;
