import { ApolloClient, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';

const create = ({ api, auth, logger }) => {
  const batchLink = new BatchHttpLink({
    uri: `${api}/graphql`,
    batchMax: 50,
  });

  const authentication = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `bearer ${auth.token()}`,
      },
    });
    return forward(operation);
  });

  const authError = onError(err => {
    const { graphQLErrors } = err;

    if (graphQLErrors) {
      for (let index = 0; index < graphQLErrors.length; index += 1) {
        const {
          extensions: { exception },
        } = graphQLErrors[index];
        const error = exception || {};
        const { status } = error;
        if ([401, 403].includes(status)) {
          auth.login({
            redirectUrl: window.location.pathname,
          });
          break;
        }
        if (status === 400) {
          logger.warn(error);
        } else {
          logger.error(error);
        }
      }
    }
  });

  const client = new ApolloClient({
    link: from([authError, authentication, batchLink]),
    cache: new InMemoryCache(),
  });
  return client;
};

export { create };
