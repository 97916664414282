import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import DoughnutChart from '../../common/DoughnutChart';

const ProductFamily = ({ data, ...props }) => {
  const { products } = data || {};

  return (
    <Panel header="Product Family" {...props}>
      <DoughnutChart data={products} />
    </Panel>
  );
};

ProductFamily.propTypes = {
  data: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ProductFamily.defaultProps = {
  data: null,
};

export default ProductFamily;
