import React, { useContext, useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { components } from 'ls-common-client';
import { AllocationDialog, Header } from '../../common';
import { AppContext } from '../../../context';
import Overview from './Overview';
import Team from './Team';
import Products from './Products';

const { Container, Text, Tabs, MenuItem } = components;

const StyledTabs = styled(Tabs)`
  text-align: left;
`;

const StyledMenuItem = styled(MenuItem)`
  border-bottom: 2px solid;
  border-bottom-color: ${props => (props.selected ? '#47B0A5' : 'transparent')};
  color: ${props =>
    props.selected ? '#4a4a4a !important' : props.theme.text.text300};
  &:hover {
    color: ${props =>
      !props.selected ? '#47B0A5 !important' : props.theme.text.text300};
  }
  margin: 0;
  height: 38px;
`;

const TabItem = properties => {
  const { text, ...props } = properties;
  return (
    <StyledMenuItem {...props}>
      <Container display="flex" alignItems="center" paddingHorizontal>
        <Text fontWeight="600" whiteSpace="nowrap">
          {text}
        </Text>
      </Container>
    </StyledMenuItem>
  );
};

const render = data => <TabItem {...data} />;

const MarketOverview = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const {
    media: { desktop, mobile },
    salesReportData: { salesReportData: { markets = [] } = {} },
  } = useContext(AppContext.Context);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const data = useMemo(
    () =>
      markets.reduce(
        (acc, market) =>
          query.get('marketName') ===
          market.marketName.toLowerCase().replace(/ /g, '-')
            ? market
            : acc,
        {}
      ),
    [markets, query]
  );

  const tabData = useMemo(() => {
    const tabs = ['Overview', 'Team', 'Products'];

    return tabs.map((text, index) => ({
      text,
      onClick: () => setSelectedTabIndex(index),
      selected: index === selectedTabIndex,
    }));
  }, [selectedTabIndex]);

  const { canvasStartDate, canvasEndDate, marketName, totalSNGB } = data;

  return (
    <>
      <Header
        subHeading={`Here is your report for ${marketName || ''}`}
        flexWrap="wrap"
      >
        <Container
          display="flex"
          flexWrap="nowrap"
          flex="1"
          justifyContent="flex-end"
          maxWidth={desktop ? '370px' : 'unset'}
          marginTop="8px"
        >
          <Container
            display="flex"
            alignItems="center"
            padding="0 20px"
            borderRadius="30px"
            backgroundColor="white"
            height="40px"
            border="1px solid #dce5ff"
            fontSize="16px"
            whiteSpace="nowrap"
            width="100%"
            flex="1"
            justifyContent="center"
          >
            {moment(canvasStartDate).format('DD MMM YYYY')} -{' '}
            {moment(canvasEndDate).format('DD MMM YYYY')}
            {!mobile && (
              <>
                <Container
                  height="20px"
                  width="1px"
                  backgroundColor="#dce5ff"
                  margin="0 15px"
                />
                Season Period
              </>
            )}
          </Container>
        </Container>
      </Header>
      <Container
        padding={mobile ? '8px' : '0 40px'}
        marginBottom="20px"
        alignSelf="flex-start"
      >
        <StyledTabs data={tabData} render={render} padding="0px" />
      </Container>
      {selectedTabIndex === 0 && <Overview data={data} />}
      {selectedTabIndex === 1 && <Team />}
      {selectedTabIndex === 2 && <Products />}
      <AllocationDialog SGNB={totalSNGB} />
    </>
  );
};

export default MarketOverview;
