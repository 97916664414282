import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import { IconValue } from '../../../common';
import { AppContext } from '../../../../context';

const CanvasProjection = ({ data, loading, ...props }) => {
  const {
    media: { mobile, desktop },
  } = useContext(AppContext.Context);

  const { canvasLength, activeWeeks, activeWeeksRemaining } = data;

  return (
    <Panel header="Canvas Projection" loading={loading} {...props}>
      <Container
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        height={desktop ? '100%' : 'auto'}
        flexWrap={mobile ? 'wrap' : 'nowrap'}
      >
        <IconValue
          alignItems="flex-start"
          value={canvasLength}
          label="Canvas Length"
          maxWidth="100px"
          minWidth="85px"
          margin={mobile ? '20px 5px' : '20px'}
          textAlign={mobile ? 'center' : 'left'}
        />
        <IconValue
          alignItems="flex-start"
          value={activeWeeks}
          label="Active Weeks"
          maxWidth="100px"
          minWidth="85px"
          margin={mobile ? '20px 5px' : '20px'}
          textAlign={mobile ? 'center' : 'left'}
        />
        <IconValue
          alignItems="flex-start"
          value={activeWeeksRemaining}
          valueColor={activeWeeksRemaining < 11 ? '#f16159' : undefined}
          label="Active Weeks Left"
          maxWidth="100px"
          minWidth="85px"
          margin={mobile ? '20px 5px' : '20px'}
          textAlign={mobile ? 'center' : 'left'}
        />
      </Container>
    </Panel>
  );
};

CanvasProjection.propTypes = {
  data: PropTypes.shape({
    canvasLength: PropTypes.number,
    activeWeeks: PropTypes.number,
    activeWeeksRemaining: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

CanvasProjection.defaultProps = {
  data: null,
  loading: false,
};

export default CanvasProjection;
