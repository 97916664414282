import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

const userQuery = gql`
  {
    currentUser {
      id
      accountId
      displayName
      avatar
      suburbId
      userRoles {
        edges {
          node {
            roleName
          }
        }
      }
      account {
        firstName
        lastName
        phoneNumber
        email
        isEmailUpdatable
        isPasswordUpdatable
        identities {
          id
          connection
        }
      }
      suburb {
        name
        postcode
        state
      }
    }
  }
`;

const useUser = handleError => {
  const { data: { currentUser } = {}, loading, refetch } = useQuery(userQuery);

  const staffId = useMemo(() => {
    if (currentUser) {
      const {
        account: { identities = [] },
      } = currentUser;

      const lsAccount = identities.find(
        ({ connection }) => connection === 'localsearch'
      );

      if (!lsAccount) {
        handleError(
          {
            message:
              'You need to be a Localsearch staff member to access this application.',
          },
          false
        );
      }

      return lsAccount && lsAccount.id;
    }

    return null;
  }, [currentUser]);

  return {
    user: currentUser && { ...currentUser, staffId },
    loading,
    refetch,
  };
};

export default useUser;
