import React from 'react';
import { components } from 'ls-common-client';
import PropTypes from 'prop-types';

const { Panel, Container, Text } = components;

const WeeklyAccount = ({ product, ...props }) => (
  <Panel
    header={
      <Container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingHorizontal
        fontWeight="600"
        minHeight="55px"
        borderBottom={theme => `1px solid ${theme.border.border300}`}
      >
        {product.icon}
        <Text
          flex="1 0 auto"
          marginLeft="9px"
          width="120px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontWeight="bold"
        >
          {product.label}
        </Text>
      </Container>
    }
    {...props}
  >
    <Container
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Container
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginVertical="10px"
        width={product.width}
      >
        <Text fontSize="30px" color="#555555">
          ${Math.round(product.total || 0).toLocaleString()}
        </Text>
        <Text color="#9b9b9b" fontSize="15px">
          Total Accounts
        </Text>
      </Container>
      {product.required && product.subtitle && (
        <Container
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width={product.width}
          marginVertical="10px"
          paddingBottom="10px"
        >
          {product.required}
          {product.subtitle}
        </Container>
      )}
    </Container>
  </Panel>
);

WeeklyAccount.propTypes = {
  product: PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    subtitle: PropTypes.element,
    width: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    required: PropTypes.number,
  }),
};

WeeklyAccount.defaultProps = {
  product: {
    subtitle: null,
    required: null,
  },
};

export default WeeklyAccount;
