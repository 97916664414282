import React, { useContext, useState, useEffect, useCallback } from 'react';
import Container from 'ls-common-client/src/components/Container';
import DropDown from 'ls-common-client/src/components/DropDown';
import PerformanceOverview from './PerformanceOverview';
import WeeklyAverage from './WeeklyAverage';
import SGNB from './SGNB';
import LocalsearchHealth from './LocalsearchHealth';
import MarketsSold from './MarketsSold';
import ProductFamily from './ProductFamily';
import DigitalNewLeaderboard from './DigitalNewLeaderboard';
import { AppContext } from '../../../context';
import { Header, AllocationDialog } from '../../common';
import LocalsearchNewLeaderboard from './LocalsearchNewLeaderboard';

const Home = () => {
  const {
    media: { mobile, desktop },
    salesReportData,
  } = useContext(AppContext.Context);

  const { salesReportData: { overview = {} } = {}, loading } =
    salesReportData || {};

  const [periods, setPeriods] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (overview.length) {
      const period = Number(localStorage.getItem('period')) || 30;
      setPeriods(
        overview
          .map(item => ({
            ...item,
            text: `Last ${item.period} days`,
            selected: item.period === period,
          }))
          .reverse()
      );

      setData(overview.find(item => item.period === period));
    }
  }, [overview]);

  const onSelected = useCallback(
    selected => {
      localStorage.setItem('period', selected.period);

      setPeriods(
        periods.map(item => ({
          ...item,
          selected: selected.period === item.period,
        }))
      );

      setData(selected);
    },
    [periods]
  );

  const { sgnbUnits } = data || {};

  return (
    <>
      <Header
        subHeading={`Here is your ${localStorage.getItem('period') ||
          30}-day report`}
      >
        <Container width={mobile ? '100%' : '165px'} marginTop="7px">
          {!!periods.length && (
            <DropDown width="100%" data={periods} onSelected={onSelected} />
          )}
        </Container>
      </Header>
      <Container
        display="flex"
        flexWrap="wrap"
        padding={mobile ? '8px' : '0 30px'}
      >
        <Container
          flex={desktop ? 2 : '0 0 100%'}
          flexDirection="column"
          display="flex"
        >
          <PerformanceOverview data={data} loading={loading} />

          <Container display="flex" flexWrap="wrap">
            <WeeklyAverage
              data={data}
              loading={loading}
              flex="1"
              minWidth={mobile ? 'unset' : '300px'}
            />
            <SGNB
              data={data}
              loading={loading}
              flex="2"
              minWidth={mobile ? 'unset' : '300px'}
            />
          </Container>

          <Container display="flex" flexWrap="wrap" flex="1">
            <LocalsearchHealth
              data={data}
              loading={loading}
              flex="1"
              minWidth="200px"
            />
            <MarketsSold
              data={data}
              loading={loading}
              flex="1"
              minWidth="200px"
            />
            <ProductFamily
              data={data}
              loading={loading}
              flex="1"
              minWidth="200px"
            />
          </Container>
        </Container>
        <Container
          flex={desktop ? 1 : '0 0 100%'}
          flexDirection="column"
          display="flex"
        >
          <DigitalNewLeaderboard data={data} loading={loading} />
          <LocalsearchNewLeaderboard data={data} loading={loading} />
        </Container>
      </Container>
      <AllocationDialog SGNB={sgnbUnits} />
    </>
  );
};

export default Home;
