import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Leaderboard as LeaderboardComponent } from '../../../common';

const Leaderboard = ({ data, ...props }) => {
  const {
    localsearchNewLeaderBoard = [],
    digitalNewLeaderBoard = [],
    sgnbLeaderBoard = [],
    name,
  } = data || {};

  const leaderboardData = useMemo(
    () => [
      {
        name: 'SGNB Units',
        data: sgnbLeaderBoard,
      },
      {
        name: 'Localsearch New %',
        renderValue: val => `${val === 1 ? 100 : (val * 100).toFixed(2)}%`,
        data: localsearchNewLeaderBoard,
      },
      {
        name: 'Subscription Product New %',
        renderValue: val => `${val === 1 ? 100 : (val * 100).toFixed(2)}%`,
        data: digitalNewLeaderBoard,
      },
    ],
    [data]
  );

  return (
    <LeaderboardComponent
      header="Leaderboard"
      multiple
      data={leaderboardData}
      repName={name}
      {...props}
    />
  );
};

Leaderboard.propTypes = {
  data: PropTypes.shape({
    sgnbLeaderBoard: PropTypes.arrayOf(PropTypes.shape({})),
    localsearchNewLeaderBoard: PropTypes.arrayOf(PropTypes.shape({})),
    digitalNewLeaderBoard: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Leaderboard.defaultProps = {
  data: null,
};

export default Leaderboard;
