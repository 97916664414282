import Container from 'ls-common-client/src/components/Container';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import Button from 'ls-common-client/src/components/Button';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import SelfDevelopmentForm from './SelfDevelopmentForm';
import {
  StyledFormContainer,
  StyledLabel,
  StyledTextArea,
  StyledError,
} from './CommonStyles';

const StyledLayoutContainer = styled(Container)`
  display: flex;
  padding: 0 50px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const GradientButton = styled(Button)`
  background: linear-gradient(90deg, #55d4c6 0%, #40a499 100%);
  margin: 30px 0;
`;

const StateOfPlayForm = ({ onSubmit }) => {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState();

  const submitted = async payload => {
    setIsLoading(true);
    try {
      setSubmitError(null);
      await onSubmit(payload);
    } catch (e) {
      setSubmitError(
        `An unexpected submission error has occurred, please contact the development team. Error: ${e.graphQLErrors[0].message}`
      );
      setIsLoading(false);
    }
  };

  return (
    <StyledLayoutContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitted)}>
          <StyledFormContainer>
            <StyledLabel>Introduction</StyledLabel>
            <StyledTextArea
              {...register('comment', { required: 'This field is required' })}
              placeholder="Write a small introduction..."
            />
            <ErrorMessage
              errors={errors}
              name="comment"
              render={({ message }) => <StyledError>{message}</StyledError>}
            />
          </StyledFormContainer>

          <StyledFormContainer>
            <StyledLabel>Last Week&apos;s Highlights</StyledLabel>
            <StyledTextArea
              {...register('highlights', {
                required: 'This field is required',
              })}
              placeholder="Write a summary of last week's highlights..."
            />
            <ErrorMessage
              errors={errors}
              name="highlights"
              render={({ message }) => <StyledError>{message}</StyledError>}
            />
          </StyledFormContainer>
          <SelfDevelopmentForm />
          {submitError && <StyledError>{submitError}</StyledError>}
          <Container display="flex" justifyContent="center">
            <GradientButton
              rounded
              secondary
              minHeight="44px"
              width="200px"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? 'Sending...' : 'Send Email'}
            </GradientButton>
          </Container>
        </form>
      </FormProvider>
    </StyledLayoutContainer>
  );
};

StateOfPlayForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StateOfPlayForm;
