import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import MediaForm from './MediaForm';
import ToggleSection from '../ToggleSection';

const StyledHeader = styled.h2`
  text-align: center;
  margin: 30px 0 0 0;
  color: #555555;
`;

const StyledPhrase = styled.p`
  text-align: center;
  margin: 5px 0 40px 0;
  color: #757575;
  a {
    text-decoration: none;
  }
`;

const PHRASE_LINK =
  'https://nextbigideaclub.com/magazine/get-1-better-every-day/19161/#:~:text=Here%27s%20how%20the%20math%20works,decline%20nearly%20down%20to%20zero.';
const SelfDevelopmentForm = () => {
  const { unregister } = useFormContext();
  const [sections, setSections] = useState({ video: false, podcast: false });

  const onToggle = id => {
    if (sections[id]) {
      unregister(`selfDevelopment.${id}`);
    }
    setSections(prev => ({
      ...prev,
      ...{ [id]: !prev[id] },
    }));
  };

  return (
    <>
      <StyledHeader>Self Development</StyledHeader>
      <StyledPhrase>
        Are you getting <a href={PHRASE_LINK}>1%</a> better each day?
      </StyledPhrase>
      <ToggleSection
        title="Display Video"
        checked={sections.video}
        onChange={() => onToggle('video')}
      />
      {sections.video && <MediaForm type="Video" id="video" showThumbnail />}
      <ToggleSection
        title="Display Podcast"
        checked={sections.podcast}
        onChange={() => onToggle('podcast')}
      />
      {sections.podcast && (
        <MediaForm type="Podcast" id="podcast" showThumbnail={false} />
      )}
    </>
  );
};

export default SelfDevelopmentForm;
