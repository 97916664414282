import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import {
  StyledFormContainer,
  StyledLabel,
  StyledInput,
  StyledError,
  StyledTextArea,
} from '../CommonStyles';
import ThumbnailFormElement from './ThumbnailFormElement';

const MediaForm = ({ type, id, showThumbnail }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <StyledFormContainer>
        <StyledLabel>{type} Link</StyledLabel>
        <StyledInput
          {...register(`selfDevelopment.${id}.link`, {
            required: 'This field is required',
          })}
          placeholder="Paste link here..."
        />
        <ErrorMessage
          errors={errors}
          name={`selfDevelopment.${id}.link`}
          render={({ message }) => <StyledError>{message}</StyledError>}
        />
      </StyledFormContainer>

      {showThumbnail && (
        <ThumbnailFormElement
          id={`selfDevelopment.${id}.thumbnail`}
          type={type}
        />
      )}

      <StyledFormContainer>
        <StyledLabel>{type} Title</StyledLabel>
        <StyledInput
          {...register(`selfDevelopment.${id}.title`, {
            required: 'This field is required',
          })}
          placeholder="Title..."
        />
        <ErrorMessage
          errors={errors}
          name={`selfDevelopment.${id}.title`}
          render={({ message }) => <StyledError>{message}</StyledError>}
        />
      </StyledFormContainer>

      <StyledFormContainer>
        <StyledLabel>{type} Description</StyledLabel>
        <StyledTextArea
          {...register(`selfDevelopment.${id}.description`, {
            required: 'This field is required',
          })}
          placeholder="A short description..."
        />
        <ErrorMessage
          errors={errors}
          name={`selfDevelopment.${id}.description`}
          render={({ message }) => <StyledError>{message}</StyledError>}
        />
      </StyledFormContainer>
    </>
  );
};

MediaForm.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  showThumbnail: PropTypes.bool.isRequired,
};

export default MediaForm;
