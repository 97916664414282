import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import SearchInput from 'ls-common-client/src/components/SearchInput';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { Context } from '../context/AppContext';

let timeout;

const StyledButton = styled(EmptyButton)`
  transition: all ease 0.3s;
  &:hover,
  &:focus {
    background: ${props => props.theme.background.background300};
  }
`;

const query = gql`
  query salesReportStaff($staffName: String!) {
    salesReportStaff(staffName: $staffName, first: 10) {
      edges {
        node {
          name
          id
          active
        }
      }
    }
  }
`;

const StyledSearchInput = styled(SearchInput)`
  background: ${props => props.theme.background.background200};
  &:placeholder-shown {
    border-color: ${props => props.theme.border.border300};
  }
`;

const StaffSearch = ({ closeSideBar, ...props }) => {
  const {
    salesReportData: { repName },
  } = useContext(Context);

  const [value, setValue] = useState('');
  const [results, setResults] = useState();
  const [loading, setLoading] = useState();
  const inputRef = useRef();

  const client = useApolloClient();
  const history = useHistory();

  useEffect(() => {
    setValue(repName || '');
  }, [repName]);

  const fetchStaff = async staffName => {
    const {
      data: { salesReportStaff },
    } = await client.query({
      query,
      variables: { staffName },
    });
    return salesReportStaff.edges;
  };

  const onChange = ({ target }) => {
    setLoading(true);
    setValue(target.value);
    clearTimeout(timeout);

    if (!target.value) {
      setResults(null);
      setLoading(false);
      return;
    }

    timeout = setTimeout(async () => {
      const staff = await fetchStaff(target.value);
      setResults(staff.map(({ node }) => node));
      setLoading(false);
    }, 800);
  };

  const onClick = () => {
    setValue('');
    inputRef.current.focus();
  };

  const onSelect = ({ id, name }) => {
    setValue(name);
    setResults(null);
    history.push(`/?staffId=${id}`);
  };

  const onKeyDown = e => {
    const elm = document.querySelector('.lsCommonClientAutoSuggest');
    if (e.keyCode === 40 && elm) {
      e.preventDefault();
      elm.querySelector('button:first-child').focus();
    }
  };

  const onAutoSuggestKeyDown = (e, staff) => {
    e.preventDefault();

    const elm = e.currentTarget;
    const nextElm = elm.nextElementSibling;
    const prevElm = elm.previousElementSibling;

    if (e.keyCode === 27) {
      setResults([]);
    }
    if (e.keyCode === 40 && nextElm) {
      nextElm.focus();
    }
    if (e.keyCode === 38 && prevElm) {
      prevElm.focus();
    }
    if (e.keyCode === 13) {
      onSelect(staff);
    }
  };

  return (
    <Container {...props} position="relative">
      <StyledSearchInput
        name="search"
        placeholder="Search Rep Name"
        value={value}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onFocus={closeSideBar}
        backgroundColor="white"
        ref={inputRef}
        autoComplete="off"
      />
      <AutoSuggest
        data={results}
        itemDisplayText="name"
        onSelect={onSelect}
        loading={loading}
        marginTop="10px"
        render={staff => (
          <StyledButton
            key={staff.id}
            textAlign="left"
            fontSize="16px"
            color={staff.active ? 'normal' : 'text200'}
            padding="13px 10px"
            width="100%"
            onClick={() => onSelect(staff)}
            onKeyDown={e => onAutoSuggestKeyDown(e, staff)}
          >
            {staff.name}
          </StyledButton>
        )}
      />
    </Container>
  );
};

StaffSearch.propTypes = {
  closeSideBar: PropTypes.func,
};

StaffSearch.defaultProps = {
  closeSideBar: () => {},
};

export default StaffSearch;
