import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import { StyledFormContainer, StyledLabel, StyledError } from '../CommonStyles';
import { ImageCropper } from '../../../common';
import {
  encodeFileAsURL,
  addPlayButtonToImage,
} from '../../../../lib/imageHelpers';

const StyledUploadButton = styled.div`
  width: 100%;
  label {
    display: block;
    text-align: center;
    color: white;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, #55d4c6 0%, #40a499 100%);
    color: #eaefff;
    font-weight: 600;
    border: 2px dashed #00b29d;
    border-radius: 5px;
    padding: 15px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const StyledThumbnailTitle = styled.h4`
  color: '#555555';
  margin-bottom: 10px;
  font-weight: 400;
`;

const ThumbnailFormElement = ({ type, id }) => {
  const {
    register,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  const [uploadedImage, setUploadedImaged] = useState();
  const [croppedImage, setCroppedImage] = useState();
  register(id, { required: 'This field is required' });

  const onFileChange = async event => {
    try {
      const result = await encodeFileAsURL(event);
      setUploadedImaged(result);
      clearErrors(id);
    } catch (e) {
      setError(id, {
        type: 'manual',
        message: e.message,
      });
    }
  };

  const onCropped = async img => {
    if (img) {
      // 16 / 9 downscaled ratio
      const withPlayButton = await addPlayButtonToImage(
        img,
        480,
        270,
        'image/jpeg'
      );
      setValue(id, withPlayButton);
      setCroppedImage(withPlayButton);
    }
    setUploadedImaged(null);
  };

  return (
    <StyledFormContainer>
      <StyledLabel>
        {type} Thumbnail{' '}
        <span style={{ fontWeight: '400', fontSize: '12px' }}>
          (Play button will be added automatically)
        </span>
      </StyledLabel>
      <StyledUploadButton>
        <input
          type="file"
          id="upload"
          accept="image/*"
          hidden
          onClick={e => {
            e.target.value = null;
          }}
          onChange={onFileChange}
        />
        <label htmlFor="upload">
          {croppedImage ? 'Change Image' : 'Choose file'}
        </label>
      </StyledUploadButton>
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ message }) => <StyledError>{message}</StyledError>}
      />
      <ImageCropper
        show={!!uploadedImage}
        image={uploadedImage}
        onSubmit={onCropped}
      />
      {croppedImage && (
        <>
          <StyledThumbnailTitle>Thumbnail Preview</StyledThumbnailTitle>
          <StyledImage src={croppedImage} alt="Thumbnail" />
        </>
      )}
    </StyledFormContainer>
  );
};

ThumbnailFormElement.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ThumbnailFormElement;
