import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Avatar from 'ls-common-client/src/components/Avatar';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Image from 'ls-common-client/src/components/Image';
import DropDown from 'ls-common-client/src/components/DropDown';
import imageResizer from '../../services/imageResizer';
import AvatarImage from '../../images/Avatar';
import { AppContext } from '../../context';
import ChevronIcon from '../../images/ChevronIcon';
import NoDataIcon from '../../images/NoDataIcon';

const Leaderboard = ({
  data,
  loading,
  header,
  valueType,
  repName,
  renderValue,
  multiple,
  ...props
}) => {
  const {
    media: { mobile, desktop },
  } = useContext(AppContext.Context);

  const [showAll, setShowAll] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState({});
  const [dropDownData, setDropDownData] = useState([]);

  const containerRef = useRef();

  useEffect(() => {
    setSelectedBoard(
      multiple ? { renderValue, ...data[0] } : { data, renderValue }
    );
    setDropDownData(
      multiple
        ? data.reduce((acc, { name }, i) => {
            return [
              ...acc,
              { text: name, selected: i === 0 },
              ...(i + 1 < data.length ? [{ splitter: true }] : []),
            ];
          }, [])
        : []
    );
    if (containerRef.current) {
      containerRef.current.scroll(0, 0);
    }
  }, [data]);

  const onDropDownSelected = ({ text }) => {
    setSelectedBoard(
      data.reduce(
        (acc, item) => (item.name === text ? { renderValue, ...item } : acc),
        []
      )
    );
    setDropDownData(
      dropDownData.map(item => ({ ...item, selected: item.text === text }))
    );
    setShowAll(false);
    containerRef.current.scroll(0, 0);
  };

  return (
    <Panel
      loading={loading}
      flex={desktop ? '1' : 'unset'}
      minWidth={mobile ? 'unset' : '200px'}
      render={({ children, header: head }) => (
        <>
          {head}
          <Container display="flex" height="100%">
            {children}
          </Container>
        </>
      )}
      header={
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingHorizontal
          fontWeight="600"
          padding={multiple ? '11px 16px' : '16px'}
          borderBottom={theme => `1px solid ${theme.border.border300}`}
          flexWrap="wrap"
        >
          {multiple ? (
            <>
              <Text
                flex="1"
                whiteSpace="nowrap"
                fontWeight="bold"
                marginRight="10px"
                padding="5px 0"
              >
                {header}
              </Text>
              <DropDown
                flex={mobile ? '0 0 100%' : '1'}
                fontWeight="500"
                buttonRender={({ selectedText, setShow, show }) => (
                  <EmptyButton
                    onClick={() => setShow(!show)}
                    fontSize="14px"
                    color="text700"
                    border="1px solid"
                    backgroundColor={theme => theme.primary.primary100}
                    borderRadius="21px"
                    padding="0 15px"
                    height="32px"
                    width="100%"
                    whiteSpace="nowrap"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    minWidth="220px"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    borderColor={theme => theme.primary.primary200}
                  >
                    {selectedText}
                    <Container marginLeft="10px">
                      <ChevronIcon />
                    </Container>
                  </EmptyButton>
                )}
                data={dropDownData}
                onSelected={onDropDownSelected}
              />
            </>
          ) : (
            <>
              <Text
                flex="1"
                whiteSpace="nowrap"
                fontWeight="bold"
                marginRight="10px"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {header}
              </Text>
              <Text color="text300">{valueType}</Text>
            </>
          )}
        </Container>
      }
      {...props}
    >
      <Container height="100%" position="relative" flex="1">
        <Container
          padding="10px 0"
          overflow={desktop ? 'auto' : 'hidden'}
          position={desktop ? 'absolute' : 'relative'}
          height={
            desktop ||
            showAll ||
            (selectedBoard.data && selectedBoard.data.length < 6)
              ? 'auto'
              : '235px'
          }
          left="0"
          bottom="0"
          top="0"
          right="0"
          ref={containerRef}
        >
          {selectedBoard.data && selectedBoard.data.length ? (
            selectedBoard.data.map(({ name, value, id, user }, i) => {
              const { avatar } = user || {};
              return (
                <Container
                  key={id}
                  padding="8px 15px"
                  display="flex"
                  alignItems="center"
                >
                  <Text
                    display="block"
                    width="25px"
                    color="text300"
                    fontWeight="600"
                    fontSize="16px"
                    flex="0 0 23px"
                  >
                    {i + 1}
                  </Text>
                  <Avatar marginRight="8px" width="28px" flex="0 0 28px">
                    {avatar ? (
                      <Image
                        width="100%"
                        height="100%"
                        src={imageResizer.resize(avatar, {
                          width: 60,
                          height: 60,
                          format: 'jpeg',
                        })}
                      />
                    ) : (
                      <AvatarImage />
                    )}
                  </Avatar>
                  <Text
                    fontSize="16px"
                    color={name === repName ? '#00a699' : 'text500'}
                    lineHeight="1.2"
                    paddingRight="10px"
                  >
                    {name}
                  </Text>
                  <Text
                    marginLeft="auto"
                    color={name === repName ? '#00a699' : 'text500'}
                    fontSize="16px"
                    fontWeight="600"
                  >
                    {selectedBoard.renderValue(value)}
                  </Text>
                </Container>
              );
            })
          ) : (
            <Container
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Container
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap={desktop ? 'nowrap' : 'wrap'}
                maxWidth="270px"
              >
                <Container>
                  <NoDataIcon />
                </Container>
                <Text
                  fontSize="16px"
                  color="text300"
                  fontWeight="600"
                  lineHeight="1.2"
                  marginTop={desktop ? 0 : '20px'}
                  marginLeft={desktop ? '25px' : 0}
                >
                  No Data Available
                </Text>
              </Container>
            </Container>
          )}
        </Container>
        {!desktop && selectedBoard.data && selectedBoard.data.length > 5 ? (
          <Container borderTop={theme => `1px solid ${theme.border.border300}`}>
            <EmptyButton
              onClick={() => setShowAll(!showAll)}
              width="100%"
              display="flex"
              alignItems="center"
              padding="5px 5px 5px 15px"
            >
              <Text color="text700" fontSize="16px">
                Show {showAll ? 'Less' : 'More'}
              </Text>
              <Icon
                iconColor="primary"
                fontSize="42px"
                marginLeft="auto"
                className={`ls-icon ${
                  showAll
                    ? 'icon-generalarrownlarge'
                    : 'icon-generalarrowslarge'
                }`}
              />
            </EmptyButton>
          </Container>
        ) : null}
      </Container>
    </Panel>
  );
};

Leaderboard.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      })
    ),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  valueType: PropTypes.string,
  repName: PropTypes.string,
  renderValue: PropTypes.func,
  multiple: PropTypes.bool,
};

Leaderboard.defaultProps = {
  loading: false,
  header: null,
  data: [],
  valueType: null,
  repName: '',
  renderValue: val => val,
  multiple: false,
};

export default Leaderboard;
