import React from 'react';
import { components } from 'ls-common-client';
import styled from 'styled-components';

const { Text } = components;
const StyledPill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  padding: 5px 10px;
  background: ${props => (props.negative ? '#F6D9D8' : '#D9ECEB')};
  color: ${props => (props.negative ? '#f16159' : '#40a499')};
  border-radius: 19px;
`;

const Pill = (val, isNegative) => (
  <StyledPill negative={isNegative !== undefined ? isNegative : val < 0}>
    <Text>
      $
      {val < 0
        ? Math.round(val * -1).toLocaleString()
        : Math.round(val).toLocaleString()}
    </Text>
  </StyledPill>
);

const TableRows = [
  {
    title: 'Sales Rep',
    cell: (val, highlight) => (
      <Text
        color={highlight ? '#40a499' : 'normal'}
        fontWeight="500"
        whiteSpace="nowrap"
      >
        {val}
      </Text>
    ),
  },
  {
    title: 'Budget Health',
    cell: Pill,
  },
  {
    title: 'Budget',
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
  {
    title: 'SGNB',
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
  {
    title: 'SGNB Count',
    cell: val => <Text>{val}</Text>,
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Renewal ($)</span>,
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>Renewal (%)</span>,
    cell: val => <Text>{Math.round(val * 100)}%</Text>,
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>New ($)</span>,
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
  {
    title: <span style={{ whiteSpace: 'nowrap' }}>New (%)</span>,
    cell: val => <Text>{Math.round(val * 100)}%</Text>,
  },
  {
    title: 'Total',
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
  {
    title: 'Deficit/Surplus',
    cell: Pill,
  },
  {
    title: 'Current Weekly',
    cell: (val, isNegative) => Pill(val, isNegative),
  },
  {
    title: 'Required Weekly',
    cell: val => <Text>${Math.round(val).toLocaleString()}</Text>,
  },
];

export default TableRows;
