import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

const dataQuery = gql`
  query salesReportData($staffId: String!) {
    salesReportData(staffId: $staffId) {
      id
      markets {
        id
        name
        marketName
        canvasStartDate
        canvasEndDate
        canvasLength
        activeWeeks
        activeWeeks
        activeWeeksRemaining
        weeklyAverage
        weeklyRequired
        totalSNGB
        totalBudget
        totalBudgetSold
        totalBudgetRemaining
        lsOnlineActualSales
        lsOnlineExpectedSales
        lsOnlineProjectedSales
        subscriptionProductsActualSales
        subscriptionProductsExpectedSales
        subscriptionProductsProjectedSales
        budgetHealth
        budgetHealthPercentage
        budgetHealthRemainingPercentage
        newBusinessPercentage
        totalRenewal
        marketBudgetPercentage
        renewalPercentage
        localsearchNewLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
        digitalNewLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
        localsearchNewLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
        sgnbLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
      }
      overview {
        period
        id
        name
        totalSales
        totalUnits
        newSalesPercentage
        lsOnlineHealth
        weeklyAverageSales
        weeklyAverageUnits
        sgnbSales
        sgnbUnits
        localsearchHealth {
          key
          value
        }
        products {
          key
          value
        }
        marketsSold {
          key
          value
        }
        digitalNewLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
        localsearchNewLeaderBoard {
          id
          name
          value
          user {
            avatar
          }
        }
      }
    }
  }
`;

const useSalesReportData = ({ staffId } = {}) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const staffIdFromQuery = query.get('staffId');

  const [fetchData, { data = {}, loading }] = useLazyQuery(dataQuery, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (staffIdFromQuery) {
      fetchData({
        variables: {
          staffId: staffIdFromQuery,
        },
      });
    }
  }, [staffIdFromQuery]);

  useEffect(() => {
    if (!staffIdFromQuery && staffId) {
      fetchData({
        variables: {
          staffId,
        },
      });
    }
  }, [staffId, staffIdFromQuery]);

  const repName = useMemo(() => {
    const { salesReportData = {} } = data || {};
    const { overview = [] } = salesReportData;

    return overview[0] && overview[0].name;
  });

  return {
    ...data,
    repName,
    loading,
    fetchData,
  };
};

export default useSalesReportData;
