import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'ls-common-client';

const { Container, Text } = components;

function CircularProgress({ radius, stroke, progress, colors, ...props }) {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset =
    circumference - ((progress || 0) / 100) * circumference;
  return (
    <Container
      position="relative"
      height={`${radius * 2}px`}
      width={`${radius * 2}px`}
      {...props}
    >
      <Container
        position="absolute"
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="20px" paddingTop="3px">
          {progress || 0}%
        </Text>
      </Container>
      <Container transform="rotate(-135deg)">
        <svg height={radius * 2} width={radius * 2}>
          <defs>
            <linearGradient
              id={colors.map(str => str.substring(1)).join('-')}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor={colors[0]} />
              <stop offset="100%" stopColor={colors[1]} />
            </linearGradient>
          </defs>
          <circle
            stroke={`url(#${colors.map(str => str.substring(1)).join('-')})`}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="gray"
            opacity="0.1"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      </Container>
    </Container>
  );
}

CircularProgress.propTypes = {
  radius: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
};

CircularProgress.defaultProps = {
  colors: ['#2ae076', '#1fef76'],
};

export default CircularProgress;
