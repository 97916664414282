import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import { IconValue } from '../../common';
import { numFormatter } from '../../../lib';
import EyeIcon from '../../../images/EyeIcon';
// import ArrowIcon from '../../../images/ArrowIcon';

const WeeklyAverage = ({ data, ...props }) => {
  const { weeklyAverageSales } = data || {};
  return (
    <Panel header="Weekly Average" {...props}>
      <Container
        padding="10px"
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        <IconValue
          value={weeklyAverageSales}
          render={val => `$${numFormatter(val)}`}
          label="Total Sales"
          icon={<EyeIcon />}
        />
        {/* <IconValue
        value={weeklyAverageUnits}
        render={val => val}
        label="Total Units"
        icon={<ArrowIcon />}
      /> */}
      </Container>
    </Panel>
  );
};

WeeklyAverage.propTypes = {
  data: PropTypes.shape({
    // weeklyAverageUnits: PropTypes.number,
    weeklyAverageSales: PropTypes.number,
  }),
};

WeeklyAverage.defaultProps = {
  data: null,
};

export default WeeklyAverage;
