import React, { useRef } from 'react';
import Dialog from 'ls-common-client/src/components/Dialog';
import Container from 'ls-common-client/src/components/Container';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import styled from 'styled-components';
import Button from 'ls-common-client/src/components/Button';

const StyledButton = styled(Button)`
  margin: 10px 10px 10px auto;
  width: 100px;
  background: linear-gradient(90deg, #55d4c6 0%, #40a499 100%);
`;

const ImageCropper = ({ show, image, onSubmit }) => {
  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef.current;
    const { cropper } = imageElement;
    onSubmit(
      cropper
        .getCroppedCanvas({ imageSmoothingQuality: 'medium' })
        .toDataURL('image/jpeg')
    );
  };

  return (
    <Dialog
      show={show}
      header="Crop your image"
      onClose={() => onSubmit(false)}
    >
      <Container display="flex" flexDirection="column">
        <Cropper
          src={image}
          style={{ width: '100%' }}
          aspectRatio={16 / 9}
          guides={false}
          background={false}
          zoomable={false}
          movable={false}
          ref={cropperRef}
        />
        <StyledButton rounded secondary onClick={() => onCrop(true)}>
          Finish
        </StyledButton>
      </Container>
    </Dialog>
  );
};

ImageCropper.propTypes = {
  show: PropTypes.bool.isRequired,
  image: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

ImageCropper.defaultProps = {
  image: null,
};

export default ImageCropper;
