import React from 'react';

const SalesKeeperLogo = () => (
  <svg
    width="35px"
    height="27px"
    viewBox="0 0 35 27"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="31.5688776%"
        y1="63.1696429%"
        x2="68.4311224%"
        y2="32.5265067%"
        id="SalesKeeperLogo-linearGradient-1"
      >
        <stop stopColor="#FF7F00" offset="0%" />
        <stop stopColor="#FFBE00" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="112.877312%"
        y1="0%"
        x2="0%"
        y2="100%"
        id="SalesKeeperLogo-linearGradient-2"
      >
        <stop stopColor="#FFA471" offset="0%" />
        <stop stopColor="#F16159" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="34.0034866%"
        y1="79.9707031%"
        x2="59.03125%"
        y2="10.4638672%"
        id="SalesKeeperLogo-linearGradient-3"
      >
        <stop stopColor="#66EADC" offset="0%" />
        <stop stopColor="#40A499" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-37.000000, -24.000000)">
        <g transform="translate(37.000000, 24.000000)">
          <g transform="translate(0.000000, 1.000000)">
            <rect
              fill="url(#SalesKeeperLogo-linearGradient-1)"
              x="10.957518"
              y="6.115824"
              width="8.664084"
              height="14.270256"
              rx="1.528956"
            />
            <circle
              fill="url(#SalesKeeperLogo-linearGradient-2)"
              cx="4.332042"
              cy="16.56369"
              r="4.332042"
            />
            <rect
              fill="url(#SalesKeeperLogo-linearGradient-3)"
              x="21.915036"
              y="-1.02877706e-13"
              width="8.664084"
              height="20.38608"
              rx="1.528956"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SalesKeeperLogo;
