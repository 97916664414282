import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Container from 'ls-common-client/src/components/Container';
import WeeklyAverageIcon from '../../../../images/WeeklyAverage';
import WeeklyRequiredIcon from '../../../../images/WeeklyRequired';
import { AppContext } from '../../../../context';
import { IconValue } from '../../../common';
import { numFormatter } from '../../../../lib';

const WeeklyPerformance = ({ data, loading, ...props }) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  const { weeklyAverage, weeklyRequired } = data;

  return (
    <Panel header="Weekly Performance" {...props}>
      <Container
        display="flex"
        flexWrap={!mobile ? 'nowrap' : 'wrap'}
        justifyContent="space-around"
      >
        <IconValue
          render={val => `$${numFormatter(val)}`}
          value={weeklyAverage}
          label="Average"
          icon={<WeeklyAverageIcon />}
          minWidth="120px"
          margin="20px 13px"
        />
        <IconValue
          render={val => `$${numFormatter(val)}`}
          value={weeklyRequired}
          label="Required"
          icon={<WeeklyRequiredIcon />}
          minWidth="120px"
          margin="20px 13px"
        />
      </Container>
    </Panel>
  );
};

WeeklyPerformance.propTypes = {
  data: PropTypes.shape({
    weeklyAverage: PropTypes.number,
    weeklyRequired: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

WeeklyPerformance.defaultProps = {
  data: null,
  loading: false,
};

export default WeeklyPerformance;
