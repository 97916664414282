import React from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import { numFormatter } from '../../../lib';
import { FeatureValue } from '../../common';

const PerformanceOverview = ({ data, ...props }) => {
  const {
    totalSales,
    // totalUnits,
    newSalesPercentage,
    // lsOnlineHealth,
  } = data || {};

  return (
    <Panel header="Performance Overview" {...props}>
      <Container
        padding="20px"
        display="flex"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <Container display="flex">
          <FeatureValue
            label="Total Sales"
            render={val => `$${numFormatter(val)}`}
            value={totalSales}
          />
          {/* <FeatureValue
          label="Total Units"
          render={val => val.toLocaleString()}
          value={totalUnits}
        /> */}
        </Container>
        <Container display="flex">
          <FeatureValue
            label="New Sales"
            render={val => `${(val * 100).toFixed(2)}%`}
            value={newSalesPercentage}
          />
          {/* <FeatureValue
          label="Localsearch Health"
          render={val => numFormatter(val)}
          value={lsOnlineHealth}
        /> */}
        </Container>
      </Container>
    </Panel>
  );
};

PerformanceOverview.propTypes = {
  data: PropTypes.shape({
    totalSales: PropTypes.number,
    // totalUnits: PropTypes.number,
    newSalesPercentage: PropTypes.number,
    // lsOnlineHealth: PropTypes.number,
  }),
};

PerformanceOverview.defaultProps = {
  data: null,
};

export default PerformanceOverview;
