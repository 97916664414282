import React, { useContext } from 'react';
import { components } from 'ls-common-client';
import PropTypes from 'prop-types';
import WeeklyPerformance from './WeeklyPerformance';
import PerformanceOverview from './PerformanceOverview';
import CanvasProjection from './CanvasProjection';
import BudgetBreakdown from './BudgetBreakdown';
import SGNB from './SGNB';
import BudgetHealth from './BudgetHealth';
import MarketBudget from './MarketBudget';
import ProductBudgetBreakdown from './ProductBudgetBreakdown';
import Leaderboard from './Leaderboard';
import { AppContext } from '../../../../context';

const { Container } = components;

function Overview({ data }) {
  const {
    media: { desktop, mobile, device },
    salesReportData: { loading },
  } = useContext(AppContext.Context);
  return (
    <>
      <Container
        display="flex"
        flexWrap="wrap"
        flexDirection={!desktop ? 'column' : 'row'}
        padding={mobile ? '8px' : '0 30px'}
      >
        <Container
          display="flex"
          flexDirection={mobile ? 'column' : 'row'}
          flexWrap="wrap"
          flex="1"
        >
          <Container
            display="flex"
            flex={
              { mobile: '1', tablet: '1 0 100%', desktop: '1 0 34%' }[device]
            }
            flexWrap={!desktop ? 'wrap' : 'nowrap'}
          >
            <WeeklyPerformance
              loading={loading}
              data={data}
              flex="1"
              minWidth={mobile ? '220px' : 'unset'}
            />
            <PerformanceOverview
              loading={loading}
              data={data}
              flex="1"
              minWidth={mobile ? '220px' : 'unset'}
            />
          </Container>
          <CanvasProjection data={data} loading={loading} flex="1 0 5%" />
        </Container>
        <Container
          width="100%"
          display="flex"
          flexWrap="wrap"
          flexDirection={desktop ? 'row' : 'column'}
        >
          <Container
            display="flex"
            flexWrap="wrap-reverse"
            flex={desktop ? '1 0 34%' : '1'}
          >
            <Container flex={desktop ? '1 0 50%' : '1 0 100%'} display="flex">
              <BudgetBreakdown data={data} flex="1" loading={loading} />
            </Container>
            <Container display="flex" flexWrap="wrap" flex="1" minWidth="233px">
              <SGNB flex="1" loading={loading} data={data} />
              <MarketBudget flex="1" loading={loading} data={data} />
            </Container>
          </Container>
          <Leaderboard
            data={data}
            loading={loading}
            flex={desktop ? '1 0 5%' : '1'}
            minWidth={desktop ? '376px' : 'unset'}
            minHeight={desktop ? '300px' : undefined}
          />
        </Container>
        <Container
          display="flex"
          flex="1"
          flexWrap="wrap"
          flexDirection={mobile ? 'column' : 'row'}
        >
          <Container flex={mobile ? '1' : '1 0 34%'} display="flex">
            <ProductBudgetBreakdown
              data={data}
              loading={loading}
              flex="1"
              minWidth={mobile ? 'unset' : '440px'}
            />
          </Container>

          <BudgetHealth
            data={data}
            loading={loading}
            minWidth={mobile ? 'unset' : '376px'}
            flex={mobile ? '1' : '1 0 5%'}
          />
        </Container>
      </Container>
    </>
  );
}

Overview.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Overview;
