import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Container from 'ls-common-client/src/components/Container';
import CircleArrowIcon from '../../../../images/CircleArrowIcon';
import BriefcaseIcon from '../../../../images/BriefcaseIcon';
import { AppContext } from '../../../../context';
import { IconValue } from '../../../common';

const PerformanceOverview = ({ data, loading, ...props }) => {
  const {
    media: { mobile },
  } = useContext(AppContext.Context);

  const { renewalPercentage, newBusinessPercentage } = data;

  return (
    <Panel header="Performance Overview" {...props}>
      <Container
        display="flex"
        flexWrap={!mobile ? 'nowrap' : 'wrap'}
        justifyContent="space-around"
        alignItems="center"
      >
        <IconValue
          render={val => `${Math.round(val * 100)}%`}
          value={renewalPercentage}
          label="Renewal"
          icon={<CircleArrowIcon />}
          minWidth="120px"
          margin="20px 13px"
        />
        <IconValue
          render={val => `${Math.round(val * 100)}%`}
          value={newBusinessPercentage}
          label="New"
          icon={<BriefcaseIcon />}
          minWidth="120px"
          margin="20px 13px"
        />
      </Container>
    </Panel>
  );
};

PerformanceOverview.propTypes = {
  data: PropTypes.shape({
    renewalPercentage: PropTypes.number,
    newBusinessPercentage: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

PerformanceOverview.defaultProps = {
  data: null,
  loading: false,
};

export default PerformanceOverview;
