import React from 'react';
import { components } from 'ls-common-client';
import PropTypes from 'prop-types';
import CircularProgress from './CircularProgress';

const { Container, Text, Panel } = components;

const ProductContainer = ({ product, ...props }) => (
  <Panel header={product.label} {...props}>
    <Container
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="5px"
      height="100%"
    >
      <CircularProgress
        radius={50}
        stroke={5}
        progress={product.percentage}
        colors={product.colors}
      />
      <Container
        margin="small"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Text whiteSpace="nowrap">
          New <b>${Math.round(product.new || 0).toLocaleString()}</b>
        </Text>
        {product.renewal !== -1 && (
          <Text whiteSpace="nowrap">
            Renewal <b>${Math.round(product.renewal || 0).toLocaleString()}</b>
          </Text>
        )}
        <Text whiteSpace="nowrap">
          Total <b>${Math.round(product.total || 0).toLocaleString()}</b>
        </Text>
      </Container>
    </Container>
  </Panel>
);

ProductContainer.propTypes = {
  product: PropTypes.shape({
    label: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    new: PropTypes.number.isRequired,
    renewal: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ProductContainer;
