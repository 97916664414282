import React from 'react';

const ShopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="45"
    viewBox="0 0 46 45"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#DCDCDC"
              d="M7.837 0H44.837V44H7.837z"
              transform="translate(-796 -463) translate(338 377) translate(459 87)"
            />
            <g fill="#4A4A4A" stroke="#4A4A4A" strokeWidth=".5">
              <path
                d="M42.013 0H.54C.215 0 0 .215 0 .54v10.583c0 .325.215.54.54.54h1.405v28.945c0 .325.215.54.54.54H40.07c.325 0 .54-.215.54-.54l-.002-28.943h1.405c.325 0 .54-.215.54-.54V.542c0-.272-.27-.542-.54-.542h0zM1.08 10.585V4.538h3.835v6.047H1.08zM17.225 40.07h-10.8V16.85h10.8v23.22zm22.247 0H18.305V16.31c0-.325-.215-.54-.54-.54H5.885c-.325 0-.54.215-.54.54v23.76H3.022V11.665h36.506V40.07h-.056zM5.992 10.585V4.538H9.61v6.047H5.992zm4.7 0V4.538h3.455v6.047h-3.455zm4.535 0V4.538h3.618v6.047h-3.618zm4.645 0V4.538h3.455v6.047h-3.455zm4.535 0V4.538h3.348v6.047h-3.348zm4.375 0V4.538H32.4v6.047h-3.618zm4.698 0V4.538h3.455v6.047H33.48zm7.992 0h-3.455l-.002-6.047h3.455l.002 6.047zm0-7.127H1.08V1.082h40.392v2.376z"
                transform="translate(-796 -463) translate(338 377) translate(459 87) translate(0 .485)"
              />
              <path
                d="M21.493 31.97H36.45c.325 0 .54-.215.54-.54V16.31c0-.325-.215-.54-.54-.54H21.493c-.325 0-.54.215-.54.54v15.175c0 .268.27.485.54.485h0zm.54-15.12H35.91v11.233l-13.877-.002V16.85zm0 12.31H35.91v1.783H22.033v-1.782zM8.533 30.565c.324 0 .54-.215.54-.54v-3.672c0-.325-.216-.54-.54-.54-.325 0-.54.215-.54.54v3.672c0 .27.215.54.54.54z"
                transform="translate(-796 -463) translate(338 377) translate(459 87) translate(0 .485)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ShopIcon;
