import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Panel from 'ls-common-client/src/components/Panel';
import { DoughnutChart } from '../../../common';
import { numFormatter } from '../../../../lib';
import { AppContext } from '../../../../context';

const BudgetBreakdown = ({ data, ...props }) => {
  const { totalBudget, totalBudgetSold, totalBudgetRemaining } = data;

  const chartData = useMemo(() => {
    const arr = [
      {
        value: totalBudgetSold,
        key: 'Total Sold',
        color: '#ff9f40',
      },
      {
        value: totalBudgetRemaining,
        key: 'Total Remaining',
        color: '#4bc0c0',
      },
    ];

    return arr.filter(({ value }) => value > -1);
  }, [totalBudgetSold, totalBudgetRemaining]);

  const {
    media: { mobile, desktop },
  } = useContext(AppContext.Context);

  const required =
    totalBudgetRemaining > 0
      ? Math.round((totalBudgetRemaining / totalBudget) * 100)
      : 0;

  return (
    <Panel header="Budget Breakdown for Season" {...props}>
      <Container
        display="flex"
        justifyContent="space-around"
        flexWrap={mobile ? 'wrap' : 'nowrap'}
      >
        <Container alignItems="center" justifyContent="center" display="flex">
          <DoughnutChart
            width={!desktop ? '200px' : '265px'}
            height={!desktop ? '200px' : '265px'}
            data={chartData}
            showKey={false}
            showLegend={false}
            showTooltip={false}
          />
          {!!required && (
            <Container position="absolute" textAlign="center" lineHeight="1.2">
              <Text
                display="block"
                color="text500"
                fontSize="30px"
                lineHeight="1"
              >
                {required}%
              </Text>
              <Text color="text300" fontSize="16px" fontWeight="600">
                Required
              </Text>
            </Container>
          )}
        </Container>
        <Container
          justifyContent="center"
          display="flex"
          flexDirection="column"
          padding="25px"
        >
          <Container marginBottom="15px">
            <Text
              fontSize="16px"
              color="text500"
              fontWeight="600"
              display="block"
              marginBottom="5px"
            >
              Individual Budget
            </Text>
            <Text fontSize="30px" color="text500">
              {`$${numFormatter(totalBudget)}`}
            </Text>
          </Container>
          {chartData.map(({ key, value, color }) => (
            <Container marginBottom="15px" key={key}>
              <Container display="flex">
                <Container
                  width="14px"
                  height="14px"
                  borderRadius="30px"
                  marginRight="15px"
                  backgroundColor={color}
                  marginTop="5px"
                />
                <Text
                  fontSize="16px"
                  color="text500"
                  fontWeight="600"
                  display="block"
                  marginBottom="5px"
                  whiteSpace="nowrap"
                >
                  {key}
                </Text>
              </Container>
              <Text fontSize="30px" color="text500">
                {`$${numFormatter(value)}`}
              </Text>
            </Container>
          ))}
        </Container>
      </Container>
    </Panel>
  );
};

BudgetBreakdown.propTypes = {
  data: PropTypes.shape({
    totalBudget: PropTypes.number,
    totalBudgetSold: PropTypes.number,
    totalBudgetRemaining: PropTypes.number,
  }),
};

BudgetBreakdown.defaultProps = {
  data: null,
};

export default BudgetBreakdown;
