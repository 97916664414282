/**
 * Function to add overlay and play button to image using canvas
 * @param {string} src - Image source
 * @param {string} outputFormat - The output format of the image
 * @returns {Promise<String>} - Image result
 */
export const addPlayButtonToImage = (src, w, h, outputFormat) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const shouldDownscale = img.width > w;
      const WIDTH = shouldDownscale ? w : img.width;
      const HEIGHT = shouldDownscale ? h : img.height;
      const CENTER_X = WIDTH / 2;
      const CENTER_Y = HEIGHT / 2;
      const RADIUS = 30;
      const HALF_RADIUS = RADIUS / 2;

      const canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.width = WIDTH;
      canvas.height = HEIGHT;
      ctx.drawImage(img, 0, 0, WIDTH, HEIGHT);

      // Add black overlay
      ctx.beginPath();
      ctx.globalAlpha = 0.15;
      ctx.rect(0, 0, WIDTH, HEIGHT);
      ctx.fillStyle = 'black';
      ctx.fill();
      ctx.closePath();

      // Add center arc
      ctx.globalAlpha = 0.65;
      ctx.beginPath();
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 4;
      ctx.arc(CENTER_X, CENTER_Y, RADIUS, 0, Math.PI * 2, true); // Outer circle
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.closePath();

      // Creates an Isosceles triangle at center with respect to size of arc
      ctx.beginPath();
      ctx.fillStyle = 'white';
      ctx.moveTo(CENTER_X + HALF_RADIUS, CENTER_Y);
      ctx.lineTo(CENTER_X - HALF_RADIUS / 2, CENTER_Y - HALF_RADIUS);
      ctx.lineTo(CENTER_X - HALF_RADIUS / 2, CENTER_Y + HALF_RADIUS);
      ctx.fill();
      resolve(canvas.toDataURL(outputFormat, 0.75)); // 0.75 Quality
    };
    img.onerror = e => {
      reject(e);
    };
    img.src = src;
  });
};

/**
 * Function to encode file into base64
 * @param {HTMLInputElement} e - Event fired from input
 * @param {Number} maxSize - Max file size, default is 2.5MB
 * @returns {Promise<String>} - Base64 encoded image
 */
export const encodeFileAsURL = (e, maxSize = 2621440) => {
  return new Promise((resolve, reject) => {
    const { size } = e.target.files[0];
    if (size > maxSize) {
      const mb = Math.floor((maxSize / 1024000) * 10) / 10;
      reject(new Error(`File must be less then ${mb} MB`));
    }

    const reader = new FileReader();
    reader.onload = event => {
      resolve(event.target.result);
    };
    reader.onerror = () => {
      reject(new Error(reader.error.message));
    };
    reader.readAsDataURL(e.target.files[0]);
  });
};
