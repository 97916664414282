import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dialog from 'ls-common-client/src/components/Dialog';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';
import Button from 'ls-common-client/src/components/Button';
import Text from 'ls-common-client/src/components/Text';
import Image from 'ls-common-client/src/components/Image';
import Container from 'ls-common-client/src/components/Container';
import MoneyTree from '../../images/MoneyTree';
import chrisRound from '../../images/chrisRound.png';
import { AppContext } from '../../context';

const StyledButton = styled(Button)`
  background: linear-gradient(90deg, #55d4c6 0%, #40a499 100%);
`;

const AllocationDialog = ({ SGNB }) => {
  const {
    allocationDialog: { setShowAllocationDialog, showAllocationDialog },
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <Dialog
      onClose={() => setShowAllocationDialog(false)}
      clickOutside
      show={showAllocationDialog}
      maxWidth="495px"
      mode={mobile ? 'mobile' : 'desktop'}
      render={({ children }) => (
        <Container display="flex" flexDirection="column" flex="1">
          {children}
        </Container>
      )}
    >
      <Container display="flex" justifyContent="flex-end" padding="10px">
        <EmptyButton onClick={() => setShowAllocationDialog(false)}>
          <Icon iconSize="55px" className="ls-icon icon-generalxlarge" />
        </EmptyButton>
      </Container>
      <Container
        padding="20px"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        flex="1"
      >
        {SGNB < 10 ? (
          <>
            <MoneyTree />
            <Text
              display="block"
              fontWeight="600"
              fontSize="25px"
              textAlign="center"
              marginTop="20px"
              maxWidth="330px"
              lineHeight="1.3"
              marginBottom="40px"
            >
              You haven&apos;t sold enough SGNBs yet!
            </Text>
          </>
        ) : (
          <>
            <Container width="185px">
              <Image width="100%" src={chrisRound} />
            </Container>
            <Text
              display="block"
              fontWeight="600"
              fontSize="25px"
              textAlign="center"
              marginTop="35px"
              marginBottom="40px"
              maxWidth="330px"
              lineHeight="1.3"
            >
              Please Call Chris Curran
            </Text>
            <StyledButton
              rounded
              secondary
              maxWidth="185px"
              marginBottom="40px"
              onClick={() => {
                window.location = 'tel:0412288372';
              }}
            >
              Call Now
            </StyledButton>
          </>
        )}
      </Container>
    </Dialog>
  );
};

AllocationDialog.propTypes = {
  SGNB: PropTypes.number,
};

AllocationDialog.defaultProps = {
  SGNB: 0,
};

export default AllocationDialog;
