import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import Container from 'ls-common-client/src/components/Container';
import Panel from 'ls-common-client/src/components/Panel';
import Text from 'ls-common-client/src/components/Text';
import Logo from 'ls-common-client/src/components/Logo';
import Image from 'ls-common-client/src/components/Image';
import Header from '../../common/Header';
import { Context } from '../../../context/AppContext';
import budgetTrackerLogo from '../../../images/budgetTrackerLogo.svg';
import stateOfPlaySvg from '../../../images/stateOfPlay.svg';
import StateOfPlayForm from './StateOfPlayForm';

const mutation = gql`
  mutation createStateOfPlayReport($input: CreateStateOfPlayReportInput!) {
    createStateOfPlayReport(input: $input) {
      success
    }
  }
`;

const StateOfPlay = ({ setShowStateOfPlaySuccess }) => {
  const [createStateOfPlayReport] = useMutation(mutation);

  const {
    media: { mobile },
  } = useContext(Context);

  const history = useHistory();
  const { search } = useLocation();

  const onSubmit = async payload => {
    const {
      data: {
        createStateOfPlayReport: { success },
      },
    } = await createStateOfPlayReport({
      variables: {
        input: payload,
      },
    });

    if (success) {
      history.push(`/${search}`);
      setShowStateOfPlaySuccess(true);
    }
  };

  return (
    <Container>
      <Header subHeading="Update your weekly State of Play email" />
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={mobile ? '8px' : '0 30px'}
      >
        <Panel flex="1" maxWidth="825px" width="100%" header="Email Preview">
          <Container
            backgroundColor="#f8f8f8"
            display="flex"
            justifyContent="center"
          >
            <Container flex="1" maxWidth="670px" padding="20px">
              <Container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={mobile ? 'column' : 'row'}
                marginBottom="20px"
              >
                <Container marginBottom={mobile ? '10px' : '0'}>
                  <Logo width="113px" />
                </Container>
                <Image width="165px" src={budgetTrackerLogo} />
              </Container>

              <Container padding="50px 20px" textAlign="center">
                <Image width="100%" maxWidth="343px" src={stateOfPlaySvg} />
              </Container>

              <Container
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Container
                  backgroundColor="white"
                  borderRadius="30px"
                  padding="5px 20px"
                  fontSize="14px"
                  color="text400"
                  marginBottom="12px"
                >
                  {moment().format('dddd DD MMM YYYY')}
                </Container>
                <Text fontSize="24px" fontWeight="600" marginBottom="20px">
                  Hi Everyone,
                </Text>
              </Container>
              <StateOfPlayForm onSubmit={onSubmit} />
            </Container>
          </Container>
        </Panel>
      </Container>
    </Container>
  );
};

StateOfPlay.propTypes = {
  setShowStateOfPlaySuccess: PropTypes.func.isRequired,
};

export default StateOfPlay;
