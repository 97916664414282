import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import Container from 'ls-common-client/src/components/Container';
import { IconValue } from '../../../common';
import { AppContext } from '../../../../context';
import ShopIcon from '../../../../images/ShopIcon';

const SGNB = ({ data, ...props }) => {
  const { totalSNGB } = data || {};
  const {
    media: { mobile },
  } = useContext(AppContext.Context);
  return (
    <Panel header="SGNB" {...props}>
      <Container display="flex" justifyContent="space-evenly">
        <IconValue
          value={totalSNGB}
          label="Total Units"
          icon={<ShopIcon />}
          minWidth="140px"
          margin={mobile ? '20px 0' : '20px 13px'}
        />
      </Container>
    </Panel>
  );
};

SGNB.propTypes = {
  data: PropTypes.shape({
    totalSNGB: PropTypes.number,
  }),
};

SGNB.defaultProps = {
  data: null,
};

export default SGNB;
