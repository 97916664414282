import React from 'react';
import PropTypes from 'prop-types';
import Panel from 'ls-common-client/src/components/Panel';
import DoughnutChart from '../../common/DoughnutChart';

const MarketsSold = ({ data, ...props }) => {
  const { marketsSold } = data || {};

  return (
    <Panel header="Markets Sold" {...props}>
      <DoughnutChart data={marketsSold} />
    </Panel>
  );
};

MarketsSold.propTypes = {
  data: PropTypes.shape({
    marketsSold: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

MarketsSold.defaultProps = {
  data: null,
};

export default MarketsSold;
