import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Image from 'ls-common-client/src/components/Image';
import Container from 'ls-common-client/src/components/Container';
import { numFormatter } from '../../../../lib';
import lsIcon from '../../../../images/lsIcon.png';
import sfIcon from '../../../../images/sfIcon.png';
import { Table } from '../../../common';

const ProductBudgetBreakdown = ({ data, ...props }) => {
  const {
    lsOnlineActualSales,
    lsOnlineExpectedSales,
    lsOnlineProjectedSales,
    subscriptionProductsActualSales,
    subscriptionProductsExpectedSales,
    subscriptionProductsProjectedSales,
  } = data || {};

  const productBudgetData = useMemo(() => {
    const getBudgetFigure = val =>
      val === null ? 'N/A' : `$${numFormatter(val)}`;

    return [
      [
        <Container
          textAlign="left"
          display="flex"
          fontWeight="600"
          color="text500"
          alignItems="center"
        >
          <Container width="26px" marginRight="20px">
            <Image width="100%" src={lsIcon} alt="Localsearch Icon" />
          </Container>
          Localsearch.com.au
        </Container>,
        getBudgetFigure(lsOnlineActualSales),
        getBudgetFigure(lsOnlineExpectedSales),
        getBudgetFigure(lsOnlineProjectedSales),
      ],
      [
        <Container
          textAlign="left"
          display="flex"
          fontWeight="600"
          color="text500"
          alignItems="center"
        >
          <Container width="26px" marginRight="20px">
            <Image width="100%" src={sfIcon} alt="Salesforce Icon" />
          </Container>
          Subscription Products
        </Container>,
        getBudgetFigure(subscriptionProductsActualSales),
        getBudgetFigure(subscriptionProductsExpectedSales),
        getBudgetFigure(subscriptionProductsProjectedSales),
      ],
    ];
  }, [data]);

  return (
    <Table
      headings={[
        'Product Budget Breakdown',
        'Actual Sales',
        'Expected Sales',
        'Projected Sales',
      ]}
      data={productBudgetData}
      {...props}
    />
  );
};

ProductBudgetBreakdown.propTypes = {
  data: PropTypes.shape({
    lsOnlineActualSales: PropTypes.number,
    lsOnlineExpectedSales: PropTypes.number,
    lsOnlineProjectedSales: PropTypes.number,
    subscriptionProductsActualSales: PropTypes.number,
    subscriptionProductsExpectedSales: PropTypes.number,
    subscriptionProductsProjectedSales: PropTypes.number,
  }),
};

ProductBudgetBreakdown.defaultProps = {
  data: null,
};

export default ProductBudgetBreakdown;
